import { BREAKPOINTS } from '@/styles/breakpoints';
import { get2FAParams } from '../process/orderCreation2FA/orderCreation2FA';
import { filtersSearchTrie } from './index';
const device = window.innerWidth < BREAKPOINTS.lg ? 'mobile' : 'desktop';
export const sendSearchActivity = async (queries) => {
    if (!queries.length) {
        return;
    }
    const requests = queries.map((query) => sendQuery(query));
    try {
        await Promise.all(requests);
    }
    catch (error) {
        console.error(error);
    }
};
const sendQuery = async (query) => {
    if (!query) {
        return;
    }
    try {
        await fetch(`${__VZR_API_URL__}filtration`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...(await get2FAParams()),
            },
            body: JSON.stringify({
                query,
                results: Array.from(filtersSearchTrie.search(query)),
                device,
            }),
        });
    }
    catch (error) {
        console.error(error);
    }
};
