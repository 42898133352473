import { CONTACTS_COMPONENTS_NAMES } from '@che-ins-ui/contacts';
import { darken } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, } from './consts';
export const getContactsTheme = (theme) => ({
    base: {
        [CONTACTS_COMPONENTS_NAMES.callUsButton]: {
            fontSize: MOBILE_FONT_SIZES.main,
            borderColor: theme.colors.brand,
            color: theme.colors.brand,
            background: theme.colors.brandTertiary,
            height: '35px',
            '& svg > path': {
                fill: theme.colors.brand,
            },
            '&:hover': {
                background: darken(theme.colors.brandTertiary, 0.1),
            },
            '&.isFooter': {
                height: '37px',
                lineHeight: '21px',
                fontSize: MOBILE_FONT_SIZES.main,
                [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                    height: '34px',
                    fontSize: DEKSTOP_FONT_SIZES.main,
                    lineHeight: '18px',
                },
            },
        },
        [CONTACTS_COMPONENTS_NAMES.contactsItem]: {
            '& .lightGrey': {
                color: theme.colors.textSecondary,
                '& svg > path': {
                    fill: theme.colors.brand,
                },
            },
        },
        [CONTACTS_COMPONENTS_NAMES.contactsSocialItem]: {
            background: theme.colors.brandTertiary,
            '& svg > path': {
                fill: theme.colors.brand,
            },
            '&:hover': {
                background: darken(theme.colors.brandTertiary, 0.1),
            },
        },
        [CONTACTS_COMPONENTS_NAMES.contactsSeparator]: {
            background: theme.colors.textTertiary,
        },
        [CONTACTS_COMPONENTS_NAMES.contactsTitleWrapper]: {
            color: theme.colors.sky,
        },
        [CONTACTS_COMPONENTS_NAMES.contactsLink]: {
            color: theme.colors.brand,
            '&:-webkit-any-link, &:visited': {
                color: theme.colors.brand,
            },
        },
    },
});
