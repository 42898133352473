import { createSlice } from '@reduxjs/toolkit';
import { getRecomendServices, getMainServices, getActiveRestServices, getPopularServices, getMandatoryServices, getAdditionalServices, transformServicesArrayToDictionary, getWorkAndEducationServices, buildDirection, } from '@/libs/helpers/services';
import { getRecomendSports, getCompetitionSports, getCommonSports, } from '@/libs/helpers/sports';
import { SERVICES_SUB_DESCRIPTIONS } from './subDescriptions';
import { fetchApiData } from './fetchApiData';
const initialState = {
    isApiDataLoading: true,
    isServiceStatusLoading: true,
    servicesDictionary: {},
    directionsDictionary: {},
    assistances: [],
    companies: [],
    countries: [],
    directions: [],
    countryGroups: [],
    exchangeTodayRates: [],
    exchangeTomorrowRates: [],
    services: [],
    sports: [],
    recomendSports: [],
    commonSports: [],
    competitionSports: [],
    recomendServices: [],
    popularServices: [],
    mandatoryServices: [],
    mainServices: [],
    activeRestServices: [],
    additionalServices: [],
    workAndEducationServices: [],
    serviceStatus: {
        name: '',
        description: '',
        serviceStatus: {
            isSalesWorking: true,
            isSalesRussiaWorking: false,
            isAntiDosWorking: false,
        },
    },
};
const companiesSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        setServiceStatus: (state, action) => {
            state.serviceStatus = action.payload;
            state.isServiceStatusLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchApiData.fulfilled, (state, action) => {
            const { services, sports, countryGroups, countries } = action.payload;
            const mappedServices = services.map((service) => {
                return {
                    ...service,
                    subDescription: SERVICES_SUB_DESCRIPTIONS[service.code],
                };
            });
            const recomendServices = getRecomendServices(mappedServices);
            const popularServices = getPopularServices(mappedServices);
            const mandatoryServices = getMandatoryServices(mappedServices);
            const mainServices = getMainServices(mappedServices);
            const activeRestServices = getActiveRestServices(mappedServices);
            const additionalServices = getAdditionalServices(mappedServices);
            const workAndEducationServices = getWorkAndEducationServices(mappedServices);
            const recomendSports = getRecomendSports(sports);
            const commonSports = getCommonSports(sports);
            const competitionSports = getCompetitionSports(sports);
            const servicesDictionary = transformServicesArrayToDictionary(mappedServices);
            const { directions, dictionary: directionsDictionary } = buildDirection(countryGroups, countries);
            return {
                ...state,
                ...action.payload,
                recomendServices,
                popularServices,
                mandatoryServices,
                mainServices,
                activeRestServices,
                additionalServices,
                workAndEducationServices,
                servicesDictionary,
                directionsDictionary,
                directions,
                recomendSports,
                commonSports,
                competitionSports,
                isApiDataLoading: false,
            };
        });
    },
});
export const { reducer: apiReducer, actions: apiActions } = companiesSlice;
export { API_STATE_NAMESPACE } from './consts';
