import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { Button } from '@che-ins-ui/button';
import { Checkbox } from '@che-ins-ui/checkbox';
import { Container } from '@che-ins-ui/container';
import { fetchCalculation } from '@/models/calculation';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { ROUTES } from '@/components/routes';
import { apiIsApiDataLoadingSelector } from '@/models/api/selectors';
import { requestActions } from '@/models/request';
import { requestServiceSelector } from '@/models/request/selectors';
import { ABROAD_CODE, FOREIGN_CODE } from '@/libs/static';
import { useStyles } from '../styles';
import { TouristsPicker } from './TouristsPicker/TouristsPicker';
import { Datepicker } from './Datepicker/Datepicker';
import { Countries } from './Countries/Countries';
import { scrollToShowcase } from './ShowcaseInfo/ShowcaseInfo';
const checkboxBaseAppearance = {
    baseAppearance: 'whitelabel-search-calculation',
};
export const Search = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(apiIsApiDataLoadingSelector);
    const queryString = useCalculationQueryString();
    const handleSearch = () => {
        dispatch(fetchCalculation());
        scrollToShowcase();
    };
    const requestService = useSelector(requestServiceSelector);
    const isAbroad = Boolean(requestService[ABROAD_CODE]);
    const isForeign = Boolean(requestService[FOREIGN_CODE]);
    const toggleAbroad = useCallback(() => {
        dispatch(requestActions.setServices({ [ABROAD_CODE]: isAbroad ? 0 : 1 }));
    }, [isAbroad]);
    const toggleForeign = useCallback(() => {
        dispatch(requestActions.setServices({ [FOREIGN_CODE]: isForeign ? 0 : 1 }));
    }, [isForeign]);
    return (_jsx(Container, { children: _jsxs("div", { className: classes.fields, children: [_jsx(Countries, {}), _jsx(Datepicker, {}), _jsx(TouristsPicker, {}), _jsx(Checkbox, { appearance: "abroad", ...checkboxBaseAppearance, value: isAbroad, onChange: toggleAbroad, label: "\u042F \u0443\u0436\u0435 \u0437\u0430 \u0433\u0440\u0430\u043D\u0438\u0446\u0435\u0439" }), _jsx(Checkbox, { appearance: "foreign", ...checkboxBaseAppearance, value: isForeign, onChange: toggleForeign, label: "\u042F \u043D\u0435 \u0433\u0440\u0430\u0436\u0434\u0430\u043D\u0438\u043D \u0420\u043E\u0441\u0441\u0438\u0438" }), _jsx("div", { className: classes.searchButton, children: _jsx(Button, { passAppearancesToComponent: false, shouldFitContent: true, appearance: "search", onClick: handleSearch, component: Link, to: {
                            pathname: ROUTES.calculation,
                            search: queryString,
                        }, disabled: isLoading, children: "\u041D\u0410\u0419\u0422\u0418" }) })] }) }));
});
