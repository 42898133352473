const countableItems = {
    AGE: ['год', 'года', 'лет'],
    PERSON: ['человек', 'человека', 'человек'],
    TOURIST: ['турист', 'туриста', 'туристов'],
    DAY: ['день', 'дня', 'дней'],
    SELECTED: ['Выбран', 'Выбрано', 'Выбрано'],
    USD: ['доллар', 'доллара', 'долларов'],
    EUR: ['евро', 'евро', 'евро'],
    RUB: ['рубль', 'рубля', 'рублей'],
    SERVICE: ['Опция', 'Опции', 'Опций'],
    SERVICESMALL: ['опция', 'опции', 'опций'],
    COUNTRY: ['страна', 'страны', 'стран'],
    OFFER: ['предложение', 'предложения', 'предложений'],
    POLICY: ['полис', 'полиса', 'полисов'],
    BACKSPACE: ['пробела', 'пробелов', 'пробелов'],
};
export function getTypes(type) {
    return countableItems[type];
}
export const MEDICINE_CODE = 'medicine';
export const MULTIPOLICY_CODE = 'multipolicy';
export const ABROAD_CODE = 'abroad';
export const FOREIGN_CODE = 'foreign';
export const PREGNANCY_CODE = 'pregnancy';
export const ASSISTANCE_CODE = 'assistance';
export const COVID_CODE = 'covid';
export const OBSERVATION_CODE = 'observation';
export const DISABILITY_CODE = 'disability';
export const FRANCHISE_CODE = 'franchise';
export const MOTORCYCLE_CODE = 'motorcycle';
export const RESCUE_ACTIVITIES_CODE = 'rescueActivities';
export const SEARCH_ACTIVITIES_CODE = 'searchActivities';
export const DIVING_CHAMBER_CODE = 'divingChamber';
export const INSURED_RETURN_CODE = 'insuredReturn';
export const CHILDREN_RETURN_CODE = 'childrenReturn';
export const TERROR_ASSISTANCE_CODE = 'terrorAssistance';
export const DISASTER_ASSISTANCE_CODE = 'disasterAssistance';
export const CHRONIC_ARRESTING_CODE = 'chronicArresting';
export const ALLERGY_ARRESTING_CODE = 'allergyArresting';
export const SUNBURN_ASSISTANCE_CODE = 'sunburnAssistance';
export const ALCOHOL_ASSISTANCE_CODE = 'alcoholAssistance';
export const DENGE_CODE = 'denge';
export const THIRD_PARTY_TRANSPORTATION_CODE = 'thirdPartyTransportation';
export const RELATIVE_ILL_RETURN_CODE = 'relativeIllReturn';
export const TRIP_CANCEL_CODE = 'tripCancel';
export const VISA_CANCEL_CODE = 'visaCancel';
export const ISOLATION_CODE = 'isolation';
export const ACCIDENT_CODE = 'accident';
export const AVIA_CARGO_CODE = 'aviaCargo';
export const CIVIL_LIABILITY_CODE = 'civilLiability';
export const DELAY_CARGO_CODE = 'delayCargo';
export const REGULAR_DELAY_CODE = 'regularDelay';
export const DOCUMENT_CODE = 'document';
export const LEGAL_CODE = 'legal';
export const AUTO_CODE = 'auto';
export const WORK_CODE = 'work';
export const OFFICE_CODE = 'office';
export const EDUCATION_CODE = 'education';
export const URGENCY_STOMATOLOGY_CODE = 'urgentStomatology';
export const TRACKING_SPORT_CODE = 'tracking';
export const MOUNTAIN_TRACKING_SPORT_CODE = 'mountainTrekking';
export const MOUNTAIN_CLIMBING_SPORT_CODE = 'mountaineering';
export const recommendServices = [SUNBURN_ASSISTANCE_CODE];
export const recommendSports = [MOTORCYCLE_CODE];
export const popularServicesCodes = [
    MULTIPOLICY_CODE,
    ABROAD_CODE,
    FOREIGN_CODE,
    FRANCHISE_CODE,
    DISABILITY_CODE,
];
export const mainServicesCodes = [
    PREGNANCY_CODE,
    CHRONIC_ARRESTING_CODE,
    SUNBURN_ASSISTANCE_CODE,
    ALLERGY_ARRESTING_CODE,
    CHILDREN_RETURN_CODE,
    DISASTER_ASSISTANCE_CODE,
    ALCOHOL_ASSISTANCE_CODE,
    DENGE_CODE,
    COVID_CODE,
    OBSERVATION_CODE,
    INSURED_RETURN_CODE,
    THIRD_PARTY_TRANSPORTATION_CODE,
    RELATIVE_ILL_RETURN_CODE,
    TERROR_ASSISTANCE_CODE,
];
export const activeRestServicesCodes = [
    RESCUE_ACTIVITIES_CODE,
    SEARCH_ACTIVITIES_CODE,
    DIVING_CHAMBER_CODE,
];
export const additionalServicesCodes = [
    TRIP_CANCEL_CODE,
    VISA_CANCEL_CODE,
    ISOLATION_CODE,
    ACCIDENT_CODE,
    AVIA_CARGO_CODE,
    CIVIL_LIABILITY_CODE,
    DELAY_CARGO_CODE,
    REGULAR_DELAY_CODE,
    DOCUMENT_CODE,
    LEGAL_CODE,
    AUTO_CODE,
];
export const workAndEducationServicesCodes = [
    WORK_CODE,
    OFFICE_CODE,
    EDUCATION_CODE,
];
export const MULTIPOLICY_FULL_YEAR_VALUE = 'full-year';
export const FRANCHISE_SERVICE = {
    code: FRANCHISE_CODE,
    name: 'Без франшизы',
    description: 'Покажем страховки без франшизы. При страховом случае, все расходы по лечению возмещает страховая компания',
    serviceValues: [0, 1],
};
export const SERVICES_HIDDEN_FROM_DISPLAYING = [FRANCHISE_CODE];
