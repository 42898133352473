import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@che-ins-ui/modal';
import { ConnectedModal } from '@che-ins-ui/modal';
import { formatDate } from '@che-ins-ui/datepicker';
import { ASSETS_SRC } from '@/libs/constants';
import { dateStartSelector } from '@/models/request/selectors';
import { calculationSelector } from '@/models/calculation/selectors';
import { themeModeSelector } from '@/models/theme';
import { AgreeButton } from '@/components/common/Hint';
import { analytics } from '@/libs/analytics';
import { useStyles } from './styles';
const modalName = 'MORE_OFFERS_TOMORROW';
let wasNotified = false;
const MODAL_DELAY = 1700;
export const MoreOffersTomorrowHint = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const themeMode = useSelector(themeModeSelector);
    const dateStart = useSelector(dateStartSelector);
    const { matchedOffers, isLoading } = useSelector(calculationSelector);
    const currentDate = formatDate(new Date());
    const isSameDay = dateStart === currentDate;
    const hasMatchedOffers = matchedOffers.length !== 0;
    const isNotLoading = !isLoading;
    const shouldShowModal = isSameDay && hasMatchedOffers && isNotLoading;
    const handleClose = () => dispatch(modalActions.close({ name: modalName }));
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!wasNotified && shouldShowModal) {
                dispatch(modalActions.open({
                    name: modalName,
                }));
                wasNotified = true;
                analytics.sendEvent({
                    action: 'popup_move_date',
                    label: 'Действие пользователя',
                    category: 'Cherehapa',
                });
            }
        }, MODAL_DELAY);
        return () => {
            clearTimeout(timer);
        };
    }, [shouldShowModal]);
    return (_jsxs(ConnectedModal, { name: modalName, appearance: "hint", withCloseButton: true, title: _jsxs(_Fragment, { children: [_jsx("img", { className: classes.image, src: `${ASSETS_SRC.IMAGES}/modals/more-offers-tomorrow-${themeMode}.webp`, alt: "" }), _jsxs("span", { className: classes.title, children: ["\u041D\u0443\u0436\u043D\u043E \u0431\u043E\u043B\u044C\u0448\u0435", _jsx("br", {}), "\u0432\u0430\u0440\u0438\u0430\u043D\u0442\u043E\u0432 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043E\u043A?"] })] }), children: [_jsx("p", { className: classes.description, children: "\u041F\u043E\u0441\u0442\u0430\u0432\u044C\u0442\u0435 \u0434\u0430\u0442\u0443 \u043D\u0430\u0447\u0430\u043B\u0430 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F \u0441 \u0437\u0430\u0432\u0442\u0440\u0430\u0448\u043D\u0435\u0433\u043E \u0434\u043D\u044F \u0438 \u043C\u044B \u043F\u043E\u043A\u0430\u0436\u0435\u043C \u0431\u043E\u043B\u044C\u0448\u0435 \u0432\u0430\u0440\u0438\u0430\u043D\u0442\u043E\u0432 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043E\u043A" }), _jsx(AgreeButton, { onClick: handleClose })] }));
};
