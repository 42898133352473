const ISLAND = ['остров', 'острова', 'island'];
export const countriesTags = {
    schengen: ['шенген', 'schengen'],
    'all-world': ['all', 'world'],
    'world-no-russia': ['world'],
    'south-asia': ['юва', 'южная', 'южно'],
    caribbean: ['карибы', 'carribean', 'caribean'],
    ocean: [...ISLAND],
    bahamas: ['багамские', ...ISLAND],
    bermudas: ['бермудские', ...ISLAND],
    brazil: ['бразилиа', 'рио'],
    united_kingdom: [
        'uk',
        'great',
        'britain',
        'соединенное',
        'королевство',
        'англия',
        'британия',
    ],
    venezuela: ['венесуэлла', 'боливарианская', 'республика'],
    guiana: ['французская'],
    germany: ['berlin', 'берлин', 'мюнхен'],
    hong_kong: ['китай', 'china'],
    greece: ['крит'],
    guam: ['сша'],
    indonesia: ['бали', 'bali'],
    iran: ['исламская', 'республика'],
    cape_verde: ['зеленого', 'мыса', ...ISLAND],
    cyprus: ['северный', 'north'],
    kyrgyzstan: ['киргизия', 'киргизстан', 'кыргыстан', 'кыргызстан'],
    china: [
        'китайская',
        'народная',
        'республика',
        'кнр',
        'шанхай',
        'пекин',
        'гонконг',
        'hong',
    ],
    north_korea: [
        'кндр',
        'корейская',
        'народно',
        'демократическая',
        'democratic',
        'republic',
    ],
    cocos_islands: [...ISLAND],
    union_of_comoros: ['коморские', 'острова', 'islands'],
    cote_d_ivoire: ['кот', 'код', 'кот', 'дивуар'],
    cuba: ['гавана'],
    macedonia: ['северная', 'north'],
    maldives: ['мальдивские', ...ISLAND],
    marshall_islands: [...ISLAND],
    mexico: ['мехико'],
    moldavia: ['молдова'],
    myanmar: ['бирма'],
    nepal: ['катманду', 'himalayas', 'гималаи'],
    netherlands: ['голландия'],
    uae: [
        'united',
        'arab',
        'emirates',
        'объединенные',
        'объединённые',
        'арабские',
        'эмираты',
        'дубай',
    ],
    papua_novaya_guinea: ['new'],
    russia: ['ru', 'federation', 'российская', 'федерация', 'крым', 'сочи'],
    salvador: ['эль', 'эль-сальвадор'],
    saudi_arabia: ['хадж'],
    seychelles: ['сейшелы', ...ISLAND],
    solomon_islands: ['соломон', ...ISLAND],
    usa: [
        'united',
        'states',
        'us',
        'соединенные',
        'штаты',
        'америки',
        'америка',
    ],
    thailand: [
        'тайланд',
        'тай',
        'пхукет',
        'самуи',
        'паттайя',
        'бангкок',
        'phuket',
        'samui',
        'pattaya',
        'bangkok',
    ],
    republic_of_china: [
        'тайвань',
        'taiwan',
        'китай',
        'китайская',
        'республика',
        'republic',
        'china',
        'кнр',
    ],
    turkmenistan: ['туркмения'],
    sri_lanka: ['шри-ланка', 'шриланка'],
    south_africa: [
        'africa',
        'южная',
        'африка',
        'южно-африканская',
        'республика',
    ],
};
