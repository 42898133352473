import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDayTimestamp } from './helpers';
import { DAY_INNER_CLASSNAME, HOVERED_DAY_HIGHLIGHT_CLASSNAME, SELECTED_DAY_HIGHLIGHT_CLASSNAME, useStyles, } from './styles';
export const Day = ({ index, day, handleDateClick, dateStartTimestamp, dateEndTimestamp, year, month, minDateIncluded, maxDateIncluded, setHoveredDate, areDatesUnselected, }) => {
    const classes = useStyles();
    const isToday = day.toDateString() === new Date().toDateString();
    const isDisabled = getDayTimestamp(day) < getDayTimestamp(minDateIncluded) ||
        getDayTimestamp(day) > getDayTimestamp(maxDateIncluded);
    const dayTimestamp = getDayTimestamp(day);
    const areBothDatesSelected = dateStartTimestamp && dateEndTimestamp;
    const isInRange = Boolean(areBothDatesSelected &&
        dayTimestamp >= dateStartTimestamp &&
        dayTimestamp <= dateEndTimestamp);
    const isMonthStart = day.getDate() === 1;
    const isMonthEnd = day.getDate() === new Date(year, month + 1, 0).getDate();
    const isRangeStart = dayTimestamp === dateStartTimestamp;
    const isRangeEnd = dayTimestamp === dateEndTimestamp;
    const isSelected = (isRangeStart || isRangeEnd) && !areDatesUnselected;
    return (_jsxs("button", { tabIndex: -1, className: classes.day, disabled: isDisabled, onClick: () => handleDateClick(day), "data-disabled": isDisabled, "data-in-range": isInRange, "data-selected": isSelected, onMouseEnter: () => {
            setHoveredDate(isDisabled ? null : day);
        }, onMouseLeave: () => {
            setHoveredDate(null);
        }, children: [_jsxs("div", { className: classes[DAY_INNER_CLASSNAME], "data-in-range": isInRange, "data-selected": isSelected, children: [_jsx("span", { children: day.getDate() }), isInRange && (isMonthStart || isMonthEnd) && !isSelected && (_jsx("div", { className: classes.dayMonthEndGradient, "data-month-start": isMonthStart, "data-month-end": isMonthEnd })), isRangeStart && (_jsx("div", { className: classes.selectionOverlay, "data-left": true })), isRangeEnd && (_jsx("div", { className: classes.selectionOverlay, "data-right": true })), _jsx("div", { className: classes[HOVERED_DAY_HIGHLIGHT_CLASSNAME] }), _jsx("div", { className: classes[SELECTED_DAY_HIGHLIGHT_CLASSNAME] })] }), isToday && _jsx("div", { className: classes.today, children: day.getDate() })] }, index));
};
