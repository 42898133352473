import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiSelector } from '@/models/api/selectors';
import { requestSelector } from '@/models/request/selectors';
import { useCheTheme } from '@/styles/themes';
import { toggleDirection } from '@/models/request/toggleDirection';
import { analytics } from '@/libs/analytics';
import { useStyles } from './styles';
import { sortOptionsList } from './helper';
export const CountriesDropdown = memo((props) => {
    return _jsx(Component, { ...props });
});
export const Component = memo(({ selectedDirections, searchCodes, searchValue, resetSearch, onSelect, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { countryGroups: selectedGroups, countries: selectedCountries } = useSelector(requestSelector);
    const { directions } = useSelector(apiSelector);
    const [optionsList, setOptionsList] = useState(sortOptionsList({ directions, selectedDirections }));
    const selectedRef = useRef(selectedDirections);
    selectedRef.current = selectedDirections;
    useEffect(() => {
        const timeout = setTimeout(() => {
            setOptionsList(sortOptionsList({
                directions,
                selectedDirections: selectedRef.current,
            }));
        });
        return () => clearTimeout(timeout);
    }, [directions]);
    const options = searchCodes
        ? optionsList.filter(({ code }) => searchCodes.includes(code))
        : optionsList;
    return (_jsx("div", { className: classes.contentContainer, tabIndex: -1, children: _jsx("div", { className: classes.content, children: _jsxs("div", { className: classes.grid, children: [options.map(({ code, name, type }) => {
                        const isChecked = type === 'group'
                            ? selectedGroups.includes(code)
                            : selectedCountries.includes(code);
                        const onClick = () => {
                            dispatch(toggleDirection({ code, type }));
                            resetSearch();
                            if (!isChecked) {
                                onSelect();
                                analytics.sendEvent({
                                    action: 'travel_country_select',
                                    label: 'Действие пользователя',
                                    category: 'Cherehapa',
                                });
                            }
                        };
                        return (_jsx(DirectionCheckbox, { label: name, isChecked: isChecked, onChange: onClick }, code));
                    }), options.length === 0 && (_jsx("div", { className: classes.notFound, children: _jsxs("span", { children: ["\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E \u043F\u043E \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u00AB", _jsx("b", { children: searchValue }), "\u00BB"] }) }))] }) }) }));
});
const DirectionCheckbox = memo(({ label, isChecked, onChange }) => {
    const classes = useStyles();
    return (_jsxs("button", { className: classes.checkbox, "data-active": isChecked, onClick: onChange, tabIndex: -1, children: [_jsx("div", { className: classes.checkboxIcon, "data-active": isChecked, children: isChecked && (_jsx("svg", { fill: "none", viewBox: "0 0 10 10", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M3.9606 10C4.40643 10 4.74857 9.83042 4.98186 9.46389L9.76672 1.89825C9.93261 1.64114 10 1.37856 10 1.1488C10 0.492341 9.5127 0 8.87506 0C8.43961 0 8.16485 0.164114 7.90047 0.601751L3.94505 7.13348L1.97512 4.69365C1.74184 4.40919 1.47745 4.2779 1.11457 4.2779C0.471747 4.2779 0 4.77024 0 5.43217C0 5.73304 0.0777605 5.97374 0.32141 6.26915L2.986 9.53501C3.25039 9.85777 3.56143 10 3.9606 10Z" }) })) }), _jsx("div", { className: classes.checkboxLabel, children: _jsx("span", { children: label }) })] }));
});
const GlobeIcon = () => {
    const theme = useCheTheme();
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", height: 20, width: 20, fill: theme.colors.textSecondary, children: _jsx("path", { d: "M480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-7-.31-14.31-.31-7.31-.69-13.07-3.85 27.84-24.12 46.07-20.26 18.23-48.73 18.23h-90.77q-30.46 0-52.15-21.67-21.69-21.68-21.69-52.12v-36.9H413.85v-73.79q0-30.44 21.69-52.21t52.15-21.77h36.93v-15.38q0-26.08 15.77-43.2 15.76-17.11 38-23.88-23.47-7.69-47.85-11.85Q506.16-800 480-800q-134 0-227 93t-93 227v5.77q0 2.69.38 5.77H350q61.38 0 104.54 43.15 43.15 43.16 43.15 104.37v37.09H386.92v109.62q22.31 6.92 45.46 10.58Q455.54-160 480-160Z" }) }));
};
const WarningIcon = () => {
    const theme = useCheTheme();
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", height: 20, width: 20, fill: theme.colors.warning, children: _jsx("path", { d: "M74.62-140 480-840l405.38 700H74.62ZM178-200h604L480-720 178-200Zm302-47.69q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02T480-312.31q-13.73 0-23.02 9.29T447.69-280q0 13.73 9.29 23.02t23.02 9.29Zm-30-104.62h60v-200h-60v200ZM480-460Z" }) }));
};
