import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { ScrollIcon } from '@/components/SelectedFilters/ScrollButton';
import { monthButtonsDirections } from './Month';
import { useStyles } from './styles';
import { UnfoldMoreIcon } from './UnfoldMoreIcon';
import { getMonthName } from './helpers';
export const MonthHeader = ({ month, year, monthIndex, monthOptions, isMostLeft, isMostRight, isSingleMonthCalendar, scroll, isLeftObservedSeen, isRightObservedSeen, }) => {
    const classes = useStyles();
    const bothSeen = isLeftObservedSeen && isRightObservedSeen;
    const withNextButton = !isMostRight &&
        (isSingleMonthCalendar || (!bothSeen && isLeftObservedSeen));
    const withPrevButton = !isMostLeft &&
        (isSingleMonthCalendar || (!bothSeen && isRightObservedSeen));
    const isTwoMonthRight = !isMostLeft && !isSingleMonthCalendar && isLeftObservedSeen;
    const handleMonthChange = (e) => {
        const selectedMonthIndex = parseInt(e.target.value, 10) + (isTwoMonthRight ? -1 : 0);
        scroll({ monthIndex: selectedMonthIndex });
    };
    return (_jsxs("div", { className: classes.monthHeaderWrapper, children: [_jsxs("div", { className: classes.monthHeader, children: [_jsxs("button", { className: classes.monthHeaderTitle, tabIndex: -1, children: [_jsxs("span", { children: [getMonthName(month), " ", year] }), _jsx(UnfoldMoreIcon, { height: 20, width: 20 })] }), _jsx("select", { onChange: handleMonthChange, value: monthIndex, tabIndex: -1, children: monthOptions })] }), _jsx(AnimatePresence, { initial: false, children: monthButtonsDirections.map((direction) => {
                    if ((direction === 'left' && !withPrevButton) ||
                        (direction === 'right' && !withNextButton))
                        return null;
                    return (_jsx(motion.button, { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: {
                            opacity: 0,
                            transition: { duration: 0.05 },
                        }, className: classes.scrollButton, "data-direction": direction, onClick: () => scroll({ direction }), tabIndex: -1, children: _jsx("div", { children: _jsx(ScrollIcon, { height: 28, width: 28 }) }) }, direction));
                }) })] }));
};
