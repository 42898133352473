class TrieNode {
    constructor() {
        this.children = new Map();
        this.predictiveServices = new Set();
        this.endServices = new Set();
    }
}
export class SearchTrie {
    constructor(serviceTags) {
        this.root = new TrieNode();
        if (serviceTags) {
            this.build(serviceTags);
        }
    }
    build(serviceTags) {
        for (const [service, tags] of Object.entries(serviceTags)) {
            this.update(service, tags);
        }
    }
    normalizeString(str) {
        return str.toLowerCase().replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '');
    }
    insertTag(service, tag) {
        let node = this.root;
        for (let i = 0; i < tag.length; i++) {
            const char = tag[i];
            if (!node.children.has(char)) {
                node.children.set(char, new TrieNode());
            }
            node = node.children.get(char);
            node.predictiveServices.add(service);
        }
        node.endServices.add(service);
    }
    searchServicesByWord(word, isEnd = false) {
        let node = this.root;
        const endServices = new Set();
        for (let i = 0; i < word.length; i++) {
            const char = word[i];
            if (!node.children.has(char)) {
                return endServices;
            }
            node = node.children.get(char);
            if (i >= 3) {
                node.endServices.forEach(endServices.add, endServices);
            }
        }
        return new Set([
            ...endServices,
            ...(isEnd ? node.endServices : node.predictiveServices),
        ]);
    }
    update(service, tags, options = {}) {
        const normalizedTags = tags
            .join(' ')
            .split(' ')
            .reduce((acc, tag) => {
            if (tag &&
                (!options?.minWordLengthToInsert ||
                    tag.length > options?.minWordLengthToInsert)) {
                acc.add(this.normalizeString(tag));
            }
            return acc;
        }, new Set());
        for (const tag of normalizedTags) {
            this.insertTag(service, tag);
        }
    }
    search(input) {
        const words = input.split(' ').reduce((acc, word) => {
            if (word) {
                acc.push(this.normalizeString(word));
            }
            return acc;
        }, []);
        const services = new Set();
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const servicesByWord = this.searchServicesByWord(word, i !== words.length - 1 || input.endsWith(' '));
            servicesByWord.forEach(services.add, services);
        }
        return services;
    }
}
