import { DATEPICKER_COMPONENTS_NAMES } from '@che-ins-ui/datepicker';
import { convertPxToRem, DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, SEARCH_INPUT_HEIGHT, } from './consts';
export const getDatepickerTheme = (theme) => ({
    base: {
        [DATEPICKER_COMPONENTS_NAMES.titleWrapper]: {
            fontSize: convertPxToRem(15),
            color: theme.colors.sky,
            backgroundColor: 'transparent',
            cursor: 'default',
        },
        [DATEPICKER_COMPONENTS_NAMES.weekday]: {
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
        [DATEPICKER_COMPONENTS_NAMES.day]: {
            fontSize: MOBILE_FONT_SIZES.main,
            color: theme.colors.sky,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
            '&:hover': {
                background: theme.colors.brandTertiary,
                color: theme.colors.brand,
            },
            '&[data-is-selected="true"]': {
                background: theme.colors.brand,
                color: theme.colors.white,
            },
            '&[data-in-range="true"]': {
                background: theme.colors.brandTertiary,
                color: theme.colors.brand,
                borderRadius: '0',
            },
        },
        [DATEPICKER_COMPONENTS_NAMES.changeMonthButtonWrapper]: {
            '& svg > path': {
                stroke: theme.colors.brand,
            },
        },
        [DATEPICKER_COMPONENTS_NAMES.calendarWrapper]: {
            backgroundColor: theme.colors.cloud,
        },
        [DATEPICKER_COMPONENTS_NAMES.body]: {
            backgroundColor: theme.colors.cloud,
            boxShadow: theme.elevations.dropdown,
        },
    },
    'white-label-filters': {
        [DATEPICKER_COMPONENTS_NAMES.wrapper]: {
            height: SEARCH_INPUT_HEIGHT.mobile,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
        [DATEPICKER_COMPONENTS_NAMES.body]: {
            transform: 'translateY(2px)',
            backgroundColor: theme.colors.cloud,
        },
    },
    'header-filters': {
        [DATEPICKER_COMPONENTS_NAMES.wrapper]: {
            height: SEARCH_INPUT_HEIGHT.mobile,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
    },
    'header-date-from': {
        [DATEPICKER_COMPONENTS_NAMES.body]: {
            left: '0px',
        },
    },
    'header-date-to': {
        [DATEPICKER_COMPONENTS_NAMES.body]: {
            right: '0px',
        },
        [DATEPICKER_COMPONENTS_NAMES.table]: {
            marginBottom: '10px',
        },
    },
});
