export const DIRECTIONS_PRIORITY = [
    'Все страны Шенгена',
    'Юго-Восточная Азия',
    'Весь мир, кроме России',
    'Весь мир',
];
export const compareCountriesForSort = (a, b) => {
    const indexA = DIRECTIONS_PRIORITY.indexOf(a);
    const indexB = DIRECTIONS_PRIORITY.indexOf(b);
    if (indexA === -1 && indexB === -1) {
        return a.localeCompare(b);
    }
    if (indexA === -1) {
        return 1;
    }
    if (indexB === -1) {
        return -1;
    }
    return indexA - indexB;
};
export const displayDirections = (selectedCountries, selectedCountryGroups, directionsDictionary) => {
    return [
        ...selectedCountryGroups.map((code) => ({
            code,
            name: directionsDictionary[code] || code,
            type: 'group',
        })),
        ...selectedCountries.map((code) => ({
            code,
            name: directionsDictionary[code] || code,
            type: 'country',
        })),
    ].sort((a, b) => compareCountriesForSort(a.name, b.name));
};
export const sortOptionsList = ({ directions, selectedDirections, }) => {
    const selectedCodes = new Set(selectedDirections.map(({ code }) => code));
    return [...directions].sort((a, b) => {
        if (selectedCodes.has(a.code) && !selectedCodes.has(b.code)) {
            return -1;
        }
        if (!selectedCodes.has(a.code) && selectedCodes.has(b.code)) {
            return 1;
        }
        return 0;
    });
};
