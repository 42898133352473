import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { differenceInDays, formatDate } from '@che-ins-ui/datepicker';
import { memo } from 'react';
import { isMultipolicySelectedSelector, requestSelector, } from '@/models/request/selectors';
import { displayDate } from '@/libs/helpers';
import { apiSelector } from '@/models/api/selectors';
import { basketProductSelector } from '@/models/basket/selectors';
import { analytics } from '@/libs/analytics';
import { ROUTES } from '../../routes';
import { useStyles } from './useStyles';
const splitter = ' \u00A0·\u00A0 ';
const DATE_FORMAT = 'dd mm (ww)';
const formateDates = (dateStart, dateEnd, isAnnual) => {
    if (!dateStart || !dateEnd) {
        return 'Даты не выбраны';
    }
    const start = new Date(formatDate(dateStart, true));
    const end = new Date(formatDate(dateEnd, true));
    if (!start || !end) {
        return 'Неверные даты';
    }
    const insuredDaysCount = differenceInDays(start, end) + 1;
    let daysLabel = displayDate(start, DATE_FORMAT);
    if (isAnnual) {
        daysLabel += splitter + 'Годовой';
    }
    else if (insuredDaysCount > 1) {
        daysLabel += ' - ' + displayDate(end, DATE_FORMAT);
    }
    return [daysLabel].join(splitter);
};
const displayDirections = (directions, directionsDictionary) => {
    if (!directions.length) {
        return 'Страны не выбраны';
    }
    return directions
        .map((direction) => directionsDictionary[direction])
        .join(splitter);
};
export const FormsInfo = memo(() => {
    const navigate = useNavigate();
    const classes = useStyles();
    const product = useSelector(basketProductSelector);
    const isMultipolicySelected = useSelector(isMultipolicySelectedSelector);
    const { dateStart, dateEnd, countries: selectedCountries, countryGroups: selectedCountryGroups, } = useSelector(requestSelector);
    const { directionsDictionary } = useSelector(apiSelector);
    const linkToLogo = product ? product.company.linkToLogo : null;
    const companyName = product ? product.company.name : null;
    return (_jsxs("div", { className: classes.root, children: [_jsxs("div", { className: classes.company, children: [linkToLogo ? (_jsx("img", { src: linkToLogo, alt: "", className: classes.companyLogo })) : (_jsx("div", { className: classes.companyLogo, "data-loading": true })), _jsxs("div", { children: [companyName ? (_jsx("h3", { className: classes.companyName, children: companyName })) : (_jsx("div", { className: classes.companyNamePlaceholder })), companyName ? (_jsxs("div", { className: classes.companyDescription, children: [formateDates(dateStart, dateEnd, isMultipolicySelected), splitter, displayDirections([
                                        ...selectedCountryGroups,
                                        ...selectedCountries,
                                    ], directionsDictionary)] })) : (_jsx("div", { className: classes.companyDescriptionPlaceholder }))] })] }), _jsx("div", { className: classes.buttonContainer, children: _jsx(FormsChangeButton, { onClick: () => {
                        navigate(ROUTES.calculation);
                        analytics.sendEvent({
                            action: 'travel_forms_info_return_button',
                            label: 'Действие пользователя',
                            category: 'Cherehapa',
                        });
                    } }) })] }));
});
export const FormsChangeButton = memo(({ onClick, isRenaming }) => {
    const classes = useStyles();
    return (_jsxs("button", { className: classes.changeButton, onClick: onClick, "data-renaming": Boolean(isRenaming), children: [_jsx("span", { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0432\u044B\u0431\u043E\u0440" }), isRenaming ? (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", width: 22, height: 22, children: _jsx("path", { d: "M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z" }) })) : (_jsx("svg", { viewBox: "0 0 17 17", height: 19, width: 19, xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6.61856 8.39393L6.72463 8.5L6.61856 8.60607L1.51899 13.7056C1.03116 14.1935 1.03057 14.989 1.51923 15.4812C2.01123 15.973 2.80245 15.9729 3.29436 15.481L8.39393 10.3814L8.5 10.2754L8.60607 10.3814L13.7056 15.481C14.1976 15.973 14.989 15.973 15.481 15.481C15.973 14.989 15.973 14.1976 15.481 13.7056L10.3814 8.60607L10.2754 8.5L10.3814 8.39393L15.481 3.29436C15.973 2.80238 15.973 2.01098 15.481 1.51899C14.989 1.027 14.1976 1.027 13.7056 1.51899L8.60607 6.61856L8.5 6.72463L8.39393 6.61856L3.29436 1.51899C2.80237 1.027 2.01098 1.027 1.51899 1.51899C1.027 2.01098 1.027 2.80238 1.51899 3.29436L6.61856 8.39393Z", strokeWidth: "0.2" }) }))] }));
});
