import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { requestActions } from '@/models/request';
import { isVascoPartnerSelector, requestSelector, } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import { CURRENCIES_BADGES, VASCO_PROHIBITED_MEDICINE_SERVICES, } from '@/libs/constants';
import { separatePriceBySpaces } from '@/libs/helpers/utils';
import { MEDICINE_CODE } from '@/libs/static';
import { useStyles } from './styles';
export const InsuranceSumButtons = memo(() => {
    const dispatch = useDispatch();
    const { services: apiServices } = useSelector(apiSelector);
    const { currency, services } = useSelector(requestSelector);
    const isVascoPartner = useSelector(isVascoPartnerSelector);
    const currentValue = services[MEDICINE_CODE];
    const classes = useStyles();
    const medicineOptions = apiServices.find(({ code }) => code === MEDICINE_CODE)?.serviceValues ||
        [];
    const [isAnimated, setIsAnimated] = useState(false);
    const onChange = (value) => {
        setIsAnimated(true);
        setTimeout(() => {
            setIsAnimated(false);
        }, 300);
        dispatch(requestActions.setServices({ [MEDICINE_CODE]: value }));
    };
    const currencyBadge = CURRENCIES_BADGES[currency];
    const filteredOptions = isVascoPartner
        ? medicineOptions.filter((option) => !VASCO_PROHIBITED_MEDICINE_SERVICES.includes(option))
        : medicineOptions;
    return (_jsx("div", { className: classes.medicineButtons, children: filteredOptions.map((amount) => (_jsx(Button, { amount: amount, currentValue: currentValue, currencyBadge: currencyBadge, onChange: onChange, isAnimated: isAnimated }, amount))) }));
});
const Button = ({ amount, currentValue, currencyBadge, onChange, isAnimated, }) => {
    const classes = useStyles();
    const isSelected = currentValue === amount;
    const splittedText = [
        ...separatePriceBySpaces(amount).split(' '),
        currencyBadge,
    ];
    return (_jsxs("button", { onClick: () => {
            onChange(amount);
        }, className: classes.medicineButton, "data-selected": isSelected, children: [currentValue === amount && (_jsx(motion.div, { layoutId: "selected", className: classes.medicineButtonBackground, transition: { duration: isAnimated ? 0.25 : 0 } })), _jsx("span", { className: classes.medicineButtonLabel, "data-selected": isSelected, children: splittedText.map((text, i) => (_jsx("span", { children: text }, i))) })] }, amount));
};
