import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useStyles } from './styles';
export const Tooltip = memo(({ tooltipLabel, x = 0, y = 0, position = 'top', isShown = false, }) => {
    const classes = useStyles();
    const variants = {
        visible: { opacity: 1, y: 0, scale: 1 },
        hidden: {
            opacity: 0,
            y: -10 * (position === 'top' ? 1 : -1),
            scale: 0.7,
        },
    };
    return (_jsx(AnimatePresence, { children: isShown && tooltipLabel && (_jsx(motion.div, { variants: variants, initial: "hidden", animate: "visible", exit: "hidden", transition: {
                type: 'just',
                duration: 0.1,
                opacity: { duration: 0.05 },
            }, className: classes.tooltipWrapper, children: _jsxs("div", { className: classes.tooltip, style: {
                    transform: `translate(${x}px, ${y}px)`,
                }, "data-position": position, children: [_jsx("div", { className: classes.tooltipBody, "data-position": position, children: tooltipLabel }), _jsx("div", { className: classes.tooltipArrow, "data-position": position })] }) }, tooltipLabel)) }));
});
