import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@che-ins-ui/input';
import { InputSuggestions } from '@/components/common/InputSuggestions';
import { InputDropdpownPostfix } from '@/components/common/InputSuggestions/InputDropdpownPostfix';
import { InputError } from '@/components/common/InputError';
import { personsSelector } from '@/models/notebook/selectors';
import { getTouristSuggestions } from '@/models/notebook/helpers';
import { openNotebookForm } from '@/models/notebook/openNotebookForm';
import { notebookActions } from '@/models/notebook';
import { TouristField } from '@/models/forms/interface';
import { isFormDirtySelector, touristsFormsSelector, } from '@/models/forms/selectors';
import { setTouristName } from '@/models/forms/setTouristName';
import { formsActions } from '@/models/forms';
import { setTouristBirthday } from '@/models/forms/setTouristBirthday';
import { formatNameOnBlur } from '@/models/forms/helpers';
import { InputAlphabetPostfix } from '@/components/common/InputAlphabetPostfix';
import { TOURISTS_MAX_LAYOUT_ANIMATION_DURATION } from '../Tourists';
import { useStyles } from '../styles';
import { inputLabels, inputNames, placeholders } from '../../consts';
import { useIsMobileInputSuggestions, useIsMobileViewInsuranceForm, } from '../hooks';
import { TouristBirthday } from './TouristBirthday';
export const Tourist = memo(({ index, _uuid, firstName, lastName, birthday, fieldsErrors, fieldsDirty, }) => {
    const persons = useSelector(personsSelector);
    const tourists = useSelector(touristsFormsSelector);
    const isFormDirty = useSelector(isFormDirtySelector);
    const suggestions = useMemo(() => getTouristSuggestions(persons, tourists), [persons, tourists]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const tourisNameRef = useRef(null);
    const [hoveredSuggestion, setHoveredSuggestion] = useState(null);
    const [areSuggestionsShown, setAreSuggestionsShown] = useState(false);
    const [previewSuccess, setPreviewSuccess] = useState(false);
    const isMobileFormView = useIsMobileViewInsuranceForm();
    const isMobileSuggestionsView = useIsMobileInputSuggestions();
    const animationOrigin = useCallback((origin) => isMobileFormView ? { originY: origin } : { originX: origin }, [isMobileFormView]);
    const hasSuggestions = suggestions.length > 0;
    const getFormError = (field) => !hoveredSuggestion &&
        (isFormDirty || fieldsDirty[field]) &&
        fieldsErrors[field];
    const formErrors = {
        lastName: getFormError(TouristField.lastName),
        firstName: getFormError(TouristField.firstName),
        birthday: getFormError(TouristField.birthday),
    };
    const handleSuggestionHover = (suggestion) => {
        if (isMobileSuggestionsView) {
            return;
        }
        setHoveredSuggestion(suggestion);
    };
    const handleSuggestionClick = (suggestion) => {
        setHoveredSuggestion(null);
        setAreSuggestionsShown(false);
        const { firstName, lastName, birthday } = suggestion;
        setFieldNameValue(TouristField.lastName, lastName);
        setFieldNameValue(TouristField.firstName, firstName);
        dispatch(setTouristBirthday({ _uuid, value: birthday }));
        if (suggestion.fromNotebook) {
            dispatch(notebookActions.addPersonIdUsedForTourists(suggestion._uuid));
        }
        setPreviewSuccess(true);
        setTimeout(() => {
            setPreviewSuccess(false);
        }, 200);
    };
    const handleSuggestionBlur = () => {
        setHoveredSuggestion(null);
    };
    useEffect(() => {
        handleSuggestionBlur();
    }, []);
    const getInputsAppearance = () => {
        if (hoveredSuggestion) {
            return 'preview';
        }
        if (previewSuccess) {
            return 'preview-success';
        }
    };
    const appearance = getInputsAppearance();
    const setFieldDirty = (field) => dispatch(formsActions.setTouristFieldDirty({ field, _uuid }));
    const setFieldNameValue = (field, value) => dispatch(setTouristName({ _uuid, field, value }));
    return (_jsxs(motion.div, { className: classes.tourist, layout: "position", initial: {
            opacity: 0.4,
            scale: 0.5,
            ...animationOrigin(0),
        }, animate: {
            opacity: 1,
            scale: 1,
            ...animationOrigin(0.5),
        }, exit: {
            opacity: 0.4,
            scale: 0.6,
            ...animationOrigin(1),
        }, transition: {
            opacity: { duration: 0.2 },
            layout: {
                duration: Math.min((index + 1) * 0.1, TOURISTS_MAX_LAYOUT_ANIMATION_DURATION),
            },
        }, children: [_jsxs("div", { style: { position: 'relative' }, ref: tourisNameRef, children: [_jsx(Input, { value: hoveredSuggestion
                            ? hoveredSuggestion.lastName
                            : lastName, onChange: hoveredSuggestion
                            ? () => { }
                            : (value) => setFieldNameValue(TouristField.lastName, value), name: hasSuggestions ? undefined : inputNames.lastName, label: inputLabels.lastName, shouldFitContent: true, placeholder: placeholders.lastName, appearance: appearance, invalid: Boolean(formErrors.lastName), postfix: hasSuggestions ? (_jsx(InputDropdpownPostfix, { isShown: hasSuggestions, isActive: areSuggestionsShown, onClick: () => setAreSuggestionsShown((prev) => !prev) })) : (_jsx(InputAlphabetPostfix, { alphabet: "en", value: lastName })), onFocus: () => {
                            if (!lastName?.trim()) {
                                setAreSuggestionsShown(true);
                            }
                        }, onBlur: () => {
                            setFieldDirty(TouristField.lastName);
                            setFieldNameValue(TouristField.lastName, formatNameOnBlur(lastName));
                        } }), _jsx(InputError, { error: formErrors.lastName }), _jsx(InputSuggestions, { ref: tourisNameRef, suggestions: suggestions, onHover: handleSuggestionHover, onBlur: handleSuggestionBlur, onClick: handleSuggestionClick, areSuggestionsShown: areSuggestionsShown, hideSuggestions: () => setAreSuggestionsShown(false), onEdit: (personId) => {
                            dispatch(openNotebookForm({ personId }));
                        } })] }, "lastname"), _jsxs("div", { children: [_jsx(Input, { value: hoveredSuggestion
                            ? hoveredSuggestion.firstName
                            : firstName, onChange: hoveredSuggestion
                            ? () => { }
                            : (value) => setFieldNameValue(TouristField.firstName, value), name: inputNames.firstName, label: inputLabels.firstName, shouldFitContent: true, placeholder: placeholders.firstName, appearance: appearance, postfix: _jsx(InputAlphabetPostfix, { alphabet: "en", value: firstName }), invalid: Boolean(formErrors.firstName), onBlur: () => {
                            setFieldDirty(TouristField.firstName);
                            setFieldNameValue(TouristField.firstName, formatNameOnBlur(firstName));
                        } }), _jsx(InputError, { error: formErrors.firstName })] }, "firstname"), _jsx(TouristBirthday, { _uuid: _uuid, appearance: appearance, birthday: birthday, previewedBirthday: hoveredSuggestion ? hoveredSuggestion.birthday : null, errorMessage: formErrors.birthday }, "birthday")] }));
});
