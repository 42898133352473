import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
import { withAlpha, darken } from '@/styles/utils';
import { mixins } from '@/styles/mixins';
const BORDER_RADIUS = 12;
const INPUT_INLINE_PADDING = 12;
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gridArea: 'tourists',
    },
    dropdown: {
        maxWidth: '70%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 'unset',
        },
    },
    relative: {
        width: '100%',
        position: 'relative',
        display: 'flex',
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            maxWidth: 'unset',
        },
    },
    tourists: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        padding: 12,
    },
    tourist: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: 42,
    },
    touristInput: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        padding: `0 ${INPUT_INLINE_PADDING}px`,
        borderRadius: BORDER_RADIUS,
        fontSize: MOBILE_FONT_SIZES.input,
        backgroundColor: theme.colors.field,
        border: `1px solid ${theme.colors.textTertiary}`,
        color: theme.colors.sky,
        ...mixins.inputPlaceholderColor(theme.colors.textSecondary),
        '&:focus': {
            outline: 'none',
            borderColor: theme.colors.brand,
        },
    },
    agePlaceholder: {
        fontSize: MOBILE_FONT_SIZES.input,
        paddingLeft: INPUT_INLINE_PADDING,
    },
    inputPostfix: {
        marginTop: 1,
        zIndex: 1,
        fontSize: MOBILE_FONT_SIZES.input,
        color: theme.colors.sky,
        transition: theme.transition.global,
        pointerEvents: 'none',
        '&[data-input-focused="true"]': {
            color: theme.colors.textSecondary,
        },
    },
    deleteButton: {
        position: 'absolute',
        right: 0,
        padding: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& div': {
            height: 30,
            width: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: theme.transition.global,
            borderRadius: 999,
        },
        '&[data-type="remove"]': {
            '& div': {
                '&:hover': {
                    backgroundColor: theme.colors.deletion,
                },
                '& svg > path': {
                    fill: theme.colors.error,
                },
            },
            '&:hover': {
                '& div': {
                    backgroundColor: theme.colors.deletion,
                },
            },
        },
        '&[data-type="apply"]': {
            '& div': {
                '& svg > path': {
                    fill: theme.colors.success,
                },
            },
            '&:hover': {
                '& div': {
                    backgroundColor: withAlpha(theme.colors.success, 0.15),
                },
            },
        },
    },
    addTouristButton: {
        height: 42,
        width: '100%',
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.colors.brandTertiary,
        fontSize: DEKSTOP_FONT_SIZES.main,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.brand,
        transition: theme.transition.global,
        '&:hover': {
            backgroundColor: darken(theme.colors.brandTertiary, { light: 0.05, dark: 0.1 }[theme.mode]),
        },
    },
}), { name: 'tourists-picker' });
