import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { DeleteIcon } from '@che-ins-ui/icons/DeleteIcon';
import { Input } from '@che-ins-ui/input';
import { emailsSelector, isFormDirtySelector } from '@/models/forms/selectors';
import { InputError } from '@/components/common/InputError';
import { formsActions } from '@/models/forms';
import { setEmail } from '@/models/forms/setEmail';
import { MIN_EMAILS_AMOUNT } from '@/libs/constants';
import { useDelayedActivation } from '@/libs/hooks/useDelayedActivation';
import { useStyles } from './styles';
export const InsurerEmails = memo(() => {
    const classes = useStyles();
    const emails = useSelector(emailsSelector);
    const isFormDirty = useSelector(isFormDirtySelector);
    const isInitialized = useDelayedActivation(300);
    const dispatch = useDispatch();
    const withPostfix = emails.length > MIN_EMAILS_AMOUNT;
    const postfixAnimation = useMemo(() => ({
        opacity: withPostfix ? 1 : 0,
        scale: withPostfix ? 1 : 0,
        width: withPostfix ? 20 : 0,
        height: withPostfix ? 20 : 0,
        margin: withPostfix ? 2 : 0,
        x: -2,
    }), [withPostfix]);
    return (_jsx(_Fragment, { children: emails.map(({ _uuid, value, errorMessage, isDirty }) => {
            const message = (isFormDirty || isDirty) && errorMessage;
            const onChange = (email) => {
                dispatch(setEmail({
                    _uuid,
                    value: email,
                }));
            };
            return (_jsxs(motion.div, { layout: "position", initial: isInitialized ? { opacity: 0.5, scale: 0.4 } : {}, animate: { opacity: 1, scale: 1 }, exit: { opacity: 5, scale: 0.3 }, transition: { duration: 0.15 }, className: classes.insurerFieldWrapper, children: [_jsx(Input, { value: value, onChange: onChange, name: "email", label: "E-mail", type: "email", shouldFitContent: true, placeholder: "E-mail", invalid: Boolean(message), appearance: "with-delete-icon", postfix: _jsx(motion.div, { initial: postfixAnimation, animate: postfixAnimation, children: _jsx("button", { className: classes.deleteIcon, tabIndex: withPostfix ? 0 : -1, onClick: () => dispatch(formsActions.removeEmail(_uuid)), children: _jsx(DeleteIcon, {}) }) }), onBlur: () => {
                            dispatch(formsActions.setEmailDirty(_uuid));
                            onChange(value.trim());
                        } }), _jsx(InputError, { error: message })] }, _uuid));
        }) }));
});
