import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { changingToThemeSelector } from '@/models/theme';
import { useStyles } from './styles';
export const SelectedFiltersScrollButton = ({ direction, disabled, onClick, }) => {
    const classes = useStyles();
    const isThemeChanging = useSelector(changingToThemeSelector);
    const isRight = direction === 'right';
    return (_jsxs("div", { className: classes.scrollOverlay, "data-right": isRight, "data-theme-changing": Boolean(isThemeChanging), children: [_jsx("div", { className: classes.gradientEdge, "data-right": isRight }), _jsx(ScrollButton, { direction: direction, onClick: onClick, disabled: disabled })] }));
};
export const ScrollButton = ({ direction, ...props }) => {
    const classes = useStyles();
    return (_jsx("button", { className: classes.scrollButton, ...props, "data-right": direction === 'right', children: _jsx(ScrollIcon, {}) }));
};
export const ScrollIcon = (props) => (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", ...props, children: _jsx("path", { d: "M17 12.3594C17 12.625 16.9062 12.8516 16.6953 13.0547L10.6016 19.0156C10.4297 19.1875 10.2188 19.2734 9.96875 19.2734C9.46094 19.2734 9.05469 18.8828 9.05469 18.3672C9.05469 18.1172 9.16406 17.8906 9.32812 17.7188L14.8281 12.3594L9.32812 7C9.15625 6.82031 9.05469 6.59375 9.05469 6.34375C9.05469 5.83594 9.46094 5.44531 9.96875 5.44531C10.2188 5.44531 10.4297 5.53125 10.6016 5.70312L16.6953 11.6641C16.8984 11.8672 17 12.0938 17 12.3594Z" }) }));
