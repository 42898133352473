import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { getRestrictions } from '@/libs/helpers';
import ServiceList from '@/components/Services/components/serviceList';
import { basketProductSelector } from '@/models/basket/selectors';
import { requestSportsSelector } from '@/models/request/selectors';
import { useStyles } from './styles';
import { SignalIcon } from './components/SignalIcon';
export const Services = memo(({ isDesktopView, isExpanded }) => {
    const product = useSelector(basketProductSelector);
    const sports = useSelector(requestSportsSelector);
    const classes = useStyles();
    if (!product)
        return null;
    const restrictions = getRestrictions(product.calculation);
    return (_jsxs("div", { className: classes.root, children: [_jsx(ServiceList, { product: product, sports: sports, isDesktopView: isDesktopView, withTitle: true, isExpanded: isExpanded }), restrictions.length > 0 && (_jsxs("div", { className: classes.restrictionsWrapper, children: [_jsxs("div", { className: classes.restrictionsTitle, children: [_jsx("svg", { width: 22, height: 22, viewBox: "0 -960 960 960", xmlns: "http://www.w3.org/2000/svg", className: classes.warningIcon, children: _jsx("path", { d: "m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" }) }), _jsx("span", { children: "\u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435 \u0432\u043D\u0438\u043C\u0430\u043D\u0438\u0435!" })] }), _jsx("ul", { className: classes.restrictionsList, children: restrictions.map((text, idx) => (_jsxs("li", { className: classes.restrictionsItem, children: [_jsx("div", { className: classes.signalIconWrapper, children: _jsx(SignalIcon, {}) }), _jsx("span", { className: classes.restrictionText, children: text })] }, idx))) })] }))] }));
});
