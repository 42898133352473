import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { Button, ButtonGroup } from '@che-ins-ui/button';
import { addDays, addYears, parseDate } from '@che-ins-ui/datepicker';
import { MULTIPOLICY_OPTIONS, requestActions } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { SERVICES_SUB_DESCRIPTIONS } from '@/models/api/subDescriptions';
import { MULTIPOLICY_CODE } from '@/libs/static';
import { useStyles } from './styles';
export const MultiPolicyValue = () => {
    const { dateStart } = useSelector(requestSelector);
    const { services } = useSelector(requestSelector);
    const classes = useStyles();
    const dispatch = useDispatch();
    const parsedDateStart = useMemo(() => parseDate(dateStart), [dateStart]);
    const dateAfterYearFromStart = useMemo(() => {
        const nextYearDate = addYears(parsedDateStart, 1);
        const newDateEnd = addDays(nextYearDate, -1);
        return newDateEnd;
    }, [parsedDateStart]);
    const handleChangeMultipolicy = useCallback((value) => {
        dispatch(requestActions.setServices({
            [MULTIPOLICY_CODE]: value,
        }));
    }, [dateAfterYearFromStart]);
    return (_jsxs(_Fragment, { children: [_jsx("p", { className: classes.subDescription, dangerouslySetInnerHTML: {
                    __html: SERVICES_SUB_DESCRIPTIONS[MULTIPOLICY_CODE],
                } }), _jsx(ButtonGroup, { value: services[MULTIPOLICY_CODE], onChange: handleChangeMultipolicy, appearance: "filter-multi-value", children: MULTIPOLICY_OPTIONS.map(({ value, label }) => {
                    return (_jsx(Button, { value: value, appearance: "filter-multi-value", tabIndex: -1, children: label }, value));
                }) })] }));
};
