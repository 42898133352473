export const GLOBAL_LETTER_SPACING = '-0.3px';
export const GLOBAL_LINE_HEIGHT = 1.37;
export const DESKTOP_SIZES_BREAKPOINT = 'sm';
export const TABLET_SEARCH_FORM_BREAKPOINT = 'md';
export const DESKTOP_SEARCH_FORM_BREAKPOINT = 'lg';
export const DESKTOP_FILTERS_BREAKPOINT = 'lg';
export const SEARCH_INPUT_HEIGHT = {
    mobile: '57px',
    desktop: '61px',
};
export const TYPOGRAPHY = {
    primary: 'Aeroport, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    secondary: '"TT Runs", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    baseSize: 16,
};
export const convertPxToRem = (size) => `${size / TYPOGRAPHY.baseSize}rem`;
export const DEKSTOP_FONT_SIZES = {
    title: convertPxToRem(18),
    main: convertPxToRem(13),
    small: convertPxToRem(12),
    input: convertPxToRem(14),
    hintModalTitle: convertPxToRem(16),
    searchButton: convertPxToRem(17),
};
export const MOBILE_FONT_SIZES = {
    title: convertPxToRem(18),
    main: convertPxToRem(15),
    small: convertPxToRem(13),
    input: convertPxToRem(15),
    hintModalTitle: convertPxToRem(16),
    searchButton: convertPxToRem(16),
    button: convertPxToRem(15),
};
