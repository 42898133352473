import { createUseStyles } from 'react-jss';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, DESKTOP_SEARCH_FORM_BREAKPOINT, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    hintText: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.small,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    highlighted: {
        color: theme.colors.warning,
    },
    interactive: {
        textDecoration: 'underline',
        cursor: 'pointer',
        [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
            textDecoration: 'none',
        },
    },
}), { name: 'card-options-label' });
