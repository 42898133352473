import { compareCountriesForSort } from '@/components/TravelForm/components/Countries/helper';
import { directionsSearchTrie } from '@/components/TravelForm/components/Countries/Countries';
import { countriesTags } from '@/components/TravelForm/components/Countries/countriesTags';
import { activeRestServicesCodes, mainServicesCodes, popularServicesCodes, recommendServices, additionalServicesCodes, URGENCY_STOMATOLOGY_CODE, workAndEducationServicesCodes, } from '../static';
function carriedFilterService(availableServices) {
    return ({ code }) => availableServices.includes(code);
}
export const getMainServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(mainServicesCodes));
};
export const getPopularServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(popularServicesCodes));
};
export const getMandatoryServices = (apiServices) => {
    return apiServices.filter(({ serviceValues, code }) => code !== 'pcr' &&
        (serviceValues.length === 1 || code === URGENCY_STOMATOLOGY_CODE));
};
export const getRecomendServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(recommendServices));
};
export const getActiveRestServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(activeRestServicesCodes));
};
export const getAdditionalServices = (apiServices) => {
    return [
        ...apiServices.filter(carriedFilterService(additionalServicesCodes)),
    ].sort((a, b) => additionalServicesCodes.indexOf(a.code) -
        additionalServicesCodes.indexOf(b.code));
};
export const getWorkAndEducationServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(workAndEducationServicesCodes));
};
export const transformServicesArrayToDictionary = (apiServices) => {
    return apiServices.reduce((acc, { code, ...service }) => ({ ...acc, [code]: service }), {});
};
const splitRegexp = /[\s\/\-_]+/u;
export const buildDirection = (countryGroups, countries) => {
    const directions = [];
    const dictionary = {};
    const directionAction = (code, name, type) => {
        directions.push({ code, name, type });
        directionsSearchTrie.update(code, [
            ...name.split(splitRegexp),
            ...code.split(splitRegexp),
            ...(countriesTags[code] || []),
        ], { minWordLengthToInsert: 2 });
        dictionary[code] = name;
    };
    for (const { code, name } of countryGroups) {
        directionAction(code, name, 'group');
    }
    for (const { code, name } of countries) {
        directionAction(code, name, 'country');
    }
    directions.sort((a, b) => compareCountriesForSort(a.name, b.name));
    return { directions, dictionary };
};
