import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
import { darken } from '@/styles/utils';
import { mixins } from '@/styles/mixins';
const BORDER_RADIUS = 12;
const getBorderProps = (theme) => ({
    '&[data-type="tourists"]': {
        borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
        [theme.breakpoints.up('md')]: {
            borderRadius: `0 0 ${BORDER_RADIUS}px 0`,
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: `0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0`,
        },
    },
    '&[data-type="date-from"]': {
        [theme.breakpoints.up('md')]: {
            borderRadius: `0 0 0 ${BORDER_RADIUS}px`,
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: `0 0 0 0`,
        },
    },
    '&[data-type="countries"]': {
        borderRadius: `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0`,
        [theme.breakpoints.up('lg')]: {
            borderRadius: `${BORDER_RADIUS}px 0 0 ${BORDER_RADIUS}px`,
        },
    },
});
export const useStyles = createUseStyles((theme) => ({
    container: {
        position: 'relative',
        height: 53,
        width: '100%',
        ...getBorderProps(theme),
        overflow: 'hidden',
        isolation: 'isolate',
        backgroundColor: theme.colors.field,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '&[data-focused="true"]': {
            outline: '2px solid',
            outlineColor: theme.colors.accent,
            outlineOffset: 1,
            zIndex: 1,
        },
        [theme.breakpoints.up('md')]: {
            height: 61,
        },
    },
    input: {
        cursor: 'pointer',
        border: 'none',
        height: '100%',
        width: '100%',
        padding: '28px 40px 10px 14px',
        position: 'relative',
        fontSize: 14,
        backgroundColor: 'transparent',
        color: theme.colors.sky,
        letterSpacing: GLOBAL_LETTER_SPACING,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        outline: 'none',
        zIndex: 1,
        ...mixins.inputPlaceholderColor(theme.colors.textSecondary),
        '&[data-inactive="true"]': {
            ...mixins.inputPlaceholderColor('transparent', {
                transition: 'color 0.2s',
            }),
        },
        [theme.breakpoints.up('md')]: {
            padding: '28px 32px 10px 14px',
            fontSize: DEKSTOP_FONT_SIZES.input,
        },
    },
    label: {
        position: 'absolute',
        top: 8,
        left: 14,
        pointerEvents: 'none',
        fontSize: MOBILE_FONT_SIZES.small,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.textPrimary,
        transition: '.15s ease-in-out',
        userSelect: 'none',
        '&[data-focused="true"]': {
            color: theme.colors.brand,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
            top: 13,
        },
        '&[data-inactive="true"]': {
            color: theme.colors.textSecondary,
            top: 18,
            fontSize: MOBILE_FONT_SIZES.input,
            [theme.breakpoints.up('md')]: {
                fontSize: DEKSTOP_FONT_SIZES.input,
                top: 22,
            },
        },
    },
    postfixContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: 56,
        WebkitTapHighlightColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        '&[data-clickable="true"]': {
            zIndex: 2,
            cursor: 'pointer',
        },
        '&:hover': {
            '& $postfixConfirm': {
                backgroundColor: darken(theme.colors.brand),
            },
        },
        [theme.breakpoints.up('md')]: {
            width: 54,
        },
    },
    postfixWrapper: {
        width: 32,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    postfixIcon: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        '& svg': {
            fill: theme.colors.brand,
        },
    },
    postfixConfirm: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        width: 32,
        height: 32,
        backgroundColor: theme.colors.brand,
        transition: 'background-color .2s ease',
        borderRadius: 999,
        '& svg': {
            fill: theme.colors.white,
        },
    },
}), { name: 'travel-form-input' });
