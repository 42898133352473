import { getFingerprint } from './fingerprint';
import { getOrderCreationHash } from './hash';
export const getOrderCreationUuid = async () => {
    try {
        const response = await fetch(`${__VZR_API_URL__}nodeUuid`);
        if (response.status >= 400) {
            throw new Error();
        }
        const result = await response.json();
        return result?.nodeUuid;
    }
    catch (error) {
        return null;
    }
};
export const get2FAParams = async () => {
    const orderCreationUuid = await getOrderCreationUuid();
    const browserFingerprint = await getFingerprint();
    const orderCreationHash = orderCreationUuid && orderCreationUuid.length >= 10
        ? getOrderCreationHash(orderCreationUuid)
        : null;
    return {
        u: orderCreationUuid,
        a: orderCreationHash,
        i: browserFingerprint,
    };
};
