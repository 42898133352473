import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { modalActions } from '@che-ins-ui/modal';
import { Tooltip } from '@che-ins-ui/tooltip';
import { useIsModalScreenSize } from '@/styles/themes/components/hooks';
import { useStyles } from './styles';
import { HintModal } from './HintModal';
export const Hint = memo(({ children, id, title, label, postfixModalName = '', questionMarkTag: QuestionMarkTag = 'button', labelTag: LabelTag = 'p', className, actionType, serviceCode, value, withIcon, type = 'hint', disable, missingOptionsImage, withServiceInfoImage, }) => {
    const classes = useStyles();
    const isModalTooltip = useIsModalScreenSize();
    const dispatch = useDispatch();
    const name = `hint-${id}${postfixModalName}`;
    const handleOpenModal = useCallback(() => {
        !disable && isModalTooltip && dispatch(modalActions.open({ name }));
    }, [name, isModalTooltip, disable]);
    return (_jsxs(_Fragment, { children: [label ? (_jsx(LabelTag, { className: classNames(classes.hintLabel, className), "data-tip": true, "data-for": id, onClick: handleOpenModal, children: label })) : (_jsx(QuestionMarkTag, { className: classNames(classes.hintQuestionMark, className), "data-tip": true, "data-for": id, tabIndex: -1, onClick: handleOpenModal, children: "?" })), isModalTooltip ? (_jsx(HintModal, { title: title, name: name, actionType: actionType, serviceCode: serviceCode, value: value, withIcon: withIcon, type: type, missingOptionsImage: missingOptionsImage, withServiceInfoImage: withServiceInfoImage, children: children })) : (_jsx(Tooltip, { disable: disable, id: id, children: children }))] }));
});
export { HintModal } from './HintModal';
export { AgreeButton } from './AgreeButton';
export * from './CalculationExpiredModal';
export * from './ShareLinkModal';
