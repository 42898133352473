import { BUTTON_COMPONENTS_NAMES } from '@che-ins-ui/button';
import { mixins } from '@/styles/mixins';
export const getIconButtonTheme = (theme) => ({
    base: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            padding: '0px',
            '-webkit-tap-highlight-color': 'transparent',
            '-webkit-user-select': 'none',
            userSelect: 'none',
        },
    },
    share: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            '&:hover': {
                filter: 'initial',
            },
        },
    },
    'remove-tourist': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            marginLeft: '6px',
            '&:hover': {
                filter: 'initial',
            },
            '& svg': {
                height: 48,
                width: 40,
                '& .background': {
                    fill: theme.colors.deletion,
                    transition: '.15s',
                    '&:hover': {
                        filter: 'brightness(93%)',
                    },
                },
                '& .basket': {
                    fill: theme.colors.error,
                    pointerEvents: 'none',
                },
            },
        },
    },
    'header-search-close': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            padding: '15px 0px 2px 0px',
        },
    },
    'compare-move': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            margin: '-2px -2px',
            padding: '2px 2px',
            transformOrigin: 'center',
            '& svg, & svg > path': {
                stroke: theme.colors.brand,
                transition: theme.transition.global,
            },
            '&:active, &:active[data-disabled="false"]': {
                transform: 'scale(1)',
                '& svg': {
                    transform: 'scale(1)',
                },
            },
            '&[data-disabled="true"]': {
                opacity: 1,
                '& svg > path': {
                    stroke: theme.colors.textTertiary,
                },
            },
            '&:hover': {
                '& svg': {
                    transform: 'scale(1.1)',
                },
            },
            '&:hover[data-disabled="true"]': {
                filter: 'brightness(100%)',
                '& svg': {
                    transform: 'scale(1)',
                },
            },
        },
    },
    'compare-move-right': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            transform: 'rotate(180deg) scale(1)',
            '&:active, &:active[data-disabled="false"]': {
                transform: 'rotate(180deg) scale(1)',
            },
        },
    },
    'compare-close': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            flexShrink: 0,
            width: 28,
            height: 28,
            borderRadius: 999,
            backgroundColor: theme.colors.textBackground,
            ...mixins.centeredContent(),
            transition: theme.transition.global,
            zIndex: 1,
            '-webkit-tap-highlight-color': 'transparent',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            '&:active': {
                transform: 'scale(1) !important',
            },
            '& svg': {
                width: 15,
                height: 15,
                '& path': {
                    fill: theme.colors.textSecondary,
                    stroke: theme.colors.textSecondary,
                },
            },
        },
    },
    'compare-remove': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            flexShrink: 0,
            '&:hover': {
                filter: 'initial',
            },
            '& svg': {
                width: 32,
                height: 32 * (48 / 40),
                '& .background': {
                    fill: theme.colors.deletion,
                    transition: '.15s',
                    '&:hover': {
                        filter: 'brightness(93%)',
                    },
                },
                '& .basket': {
                    fill: theme.colors.error,
                    pointerEvents: 'none',
                },
            },
        },
    },
});
