import { ENTERING, ENTERED, EXITING } from 'react-transition-group/Transition';
import { MODAL_COMPONENTS_NAMES } from '@che-ins-ui/modal';
import { isIframe } from '@/libs/helpers';
import { EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT } from '@/components/common/EditPersonModal/consts';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, } from './consts';
export const getModalTheme = (theme) => ({
    base: {
        [MODAL_COMPONENTS_NAMES.modalContent]: {
            transition: theme.transition.global,
            backgroundColor: theme.colors.cloud,
            boxShadow: theme.elevations.modal,
            border: 'none',
            zIndex: theme.zIndex.max,
            [`&[data-animation-state="${ENTERED}"]`]: {
                transform: `scale(1) translate(-50%, ${isIframe() ? '-25vh' : '-50%'})`,
                opacity: 1,
            },
        },
        [MODAL_COMPONENTS_NAMES.closeButton]: {
            '& svg': {
                '& rect': {
                    fill: theme.colors.textBackground,
                },
                '& path': {
                    fill: theme.colors.textSecondary,
                },
            },
        },
    },
    hint: {
        [MODAL_COMPONENTS_NAMES.headerWrapper]: {
            padding: '3px 15px 0px 15px',
            height: 'auto',
            alignItems: 'flex-start',
        },
        [MODAL_COMPONENTS_NAMES.modalContent]: {
            borderRadius: '12px',
            width: 'calc(100% - 30px)',
            maxWidth: '400px',
            minHeight: '220px',
            [theme.breakpoints.up('sm')]: {
                minHeight: '205px',
            },
        },
        [MODAL_COMPONENTS_NAMES.titleWrapper]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '28px',
            textAlign: 'center',
            fontSize: MOBILE_FONT_SIZES.hintModalTitle,
            '& svg': {
                '& path': {
                    fill: theme.colors.brand,
                },
                '& rect': {
                    fill: theme.colors.brandTertiary,
                },
            },
        },
        [MODAL_COMPONENTS_NAMES.childrenWrapper]: {
            fontSize: MOBILE_FONT_SIZES.main,
            padding: '0px 10px 10px 10px',
            textAlign: 'center',
            color: theme.colors.textPrimary,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '24px',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    share: {
        [MODAL_COMPONENTS_NAMES.modalContent]: {
            width: 'calc(100% - 30px)',
            [theme.breakpoints.up('sm')]: {
                width: '290px',
            },
        },
    },
    offer: {
        [MODAL_COMPONENTS_NAMES.modalContent]: {
            [theme.breakpoints.down('md')]: {
                position: 'fixed',
                top: '0px',
                bottom: '0px',
                left: '0px',
                right: '0px',
                borderRadius: '0px',
                transformOrigin: 'center',
                height: '100%',
                minHeight: '100%',
                [`&[data-animation-state="${ENTERING}"]`]: {
                    transform: `scale(0.8)`,
                },
                [`&[data-animation-state="${ENTERED}"]`]: {
                    transform: 'scale(1)',
                },
                [`&[data-animation-state="${EXITING}"]`]: {
                    transform: `scale(0.8)`,
                },
            },
            [theme.breakpoints.up('md')]: {
                left: '50%',
                width: '700px',
                minHeight: '355px',
                borderRadius: '12px',
                padding: '0px',
                overflow: 'visible',
                [`&[data-animation-state="${ENTERING}"]`]: {
                    transform: `perspective(50px) translate3d(-50%, -50%, -5px)`,
                },
                [`&[data-animation-state="${ENTERED}"]`]: {
                    transform: `perspective(50px) translate3d(-50%, ${isIframe() ? '-35vh' : '-50%'}, 0px)`,
                },
                [`&[data-animation-state="${EXITING}"]`]: {
                    transform: `perspective(50px) translate3d(-50%, -50%, -5px)`,
                },
            },
        },
        [MODAL_COMPONENTS_NAMES.headerWrapper]: {
            height: 'auto',
            borderBottom: '1px solid rgba(113, 130, 153, 0.15)',
            padding: '0px',
            marginBottom: '12px',
        },
        [MODAL_COMPONENTS_NAMES.childrenWrapper]: {
            outline: 'none',
            [theme.breakpoints.down('md')]: {
                padding: '5px 15px 60px 15px',
                overflow: 'hidden',
            },
            [theme.breakpoints.up('md')]: {
                padding: '2px 15px 70px 16px',
            },
        },
        [MODAL_COMPONENTS_NAMES.titleWrapper]: {
            width: '100%',
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
                overflow: 'visible',
            },
        },
    },
    'offer-loading': {
        [MODAL_COMPONENTS_NAMES.childrenWrapper]: {
            padding: '2px 12px 12px',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    'overlay-loader': {
        [MODAL_COMPONENTS_NAMES.headerWrapper]: {
            display: 'none',
        },
        [MODAL_COMPONENTS_NAMES.modalContent]: {
            background: 'transparent',
        },
    },
    'notebook-form': {
        [MODAL_COMPONENTS_NAMES.modalContent]: {
            minWidth: 320,
            [theme.breakpoints.down(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
                position: 'fixed',
                inset: 0,
                borderRadius: '0px',
                transformOrigin: 'center',
                height: '100%',
                minHeight: '100%',
                transition: 'transform 0.4s cubic-bezier(0.76, 0, 0.24, 1)',
                [`&[data-animation-state="${ENTERING}"], &[data-animation-state="${EXITING}"]`]: {
                    transform: 'translateX(-100%)',
                    opacity: 1,
                },
                [`&[data-animation-state="${ENTERED}"]`]: {
                    transform: 'translateX(0%)',
                },
            },
            [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT + 0.99)]: {
                left: '50%',
                width: 'calc(100vw - 30px)',
                maxWidth: 900,
                minHeight: 100,
                borderRadius: '12px',
                padding: '0px',
                transformOrigin: 'center',
                [`&[data-animation-state="${ENTERING}"], &[data-animation-state="${EXITING}"]`]: {
                    transform: 'translate(-50%, -50%) scale(0.9)',
                },
                [`&[data-animation-state="${ENTERED}"]`]: {
                    transform: 'translate(-50%, -50%) scale(1)',
                },
            },
        },
        [MODAL_COMPONENTS_NAMES.headerWrapper]: {
            display: 'none',
        },
        [MODAL_COMPONENTS_NAMES.childrenWrapper]: {
            outline: 'none',
            [theme.breakpoints.down('md')]: {
                padding: 0,
                overflow: 'hidden',
            },
            [theme.breakpoints.up('md')]: {
                padding: 0,
            },
        },
        [MODAL_COMPONENTS_NAMES.titleWrapper]: {
            display: 'none',
        },
    },
    comparing: {
        [MODAL_COMPONENTS_NAMES.modalContent]: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                transition: theme.transition.global,
                display: 'initial',
                left: '50%',
                borderRadius: '12px',
                [`&[data-animation-state="${ENTERING}"], &[data-animation-state="${EXITING}"]`]: {
                    transform: 'translate(-50%, -50%)',
                },
                [`&[data-animation-state="${ENTERED}"]`]: {
                    transform: `translate(-50%, ${isIframe() ? '-35vh' : '-50%'}) scale(1)`,
                },
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
        },
        [MODAL_COMPONENTS_NAMES.headerWrapper]: {
            height: 0,
            display: 'none',
        },
        [MODAL_COMPONENTS_NAMES.childrenWrapper]: {
            outline: 'none',
            padding: 0,
        },
        [MODAL_COMPONENTS_NAMES.titleWrapper]: {
            width: '100%',
            overflow: 'hidden',
        },
    },
});
