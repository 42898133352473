import { createUseStyles } from 'react-jss';
const BORDER_RADIUS = 12;
export const useStyles = createUseStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 'calc(100% + 4px)',
        zIndex: 1,
        backgroundColor: theme.colors.cloud,
        boxShadow: theme.elevations.travelForm,
        overflow: 'hidden',
        borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
        '&[data-stretch="true"]': {
            width: '100%',
        },
    },
}), { name: 'travel-form-dropdown' });
