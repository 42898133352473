import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { getCardOptoinsLabel } from '@/libs/helpers/utils';
import { Hint } from '@/components/common/Hint';
import { SERVICES_HIDDEN_FROM_DISPLAYING } from '@/libs/static';
import MissingServicesHint from '../MissingServicesHint';
import { useStyles } from './styles';
export const CardOptionsLabel = memo((props) => {
    const classes = useStyles();
    const { matchedType } = props;
    const isHighlighted = matchedType === 'partial' || matchedType === 'none';
    const isInteractive = matchedType === 'partial';
    const cardLabel = useMemo(() => {
        switch (matchedType) {
            case 'none':
                return 'Отсутствуют выбранные опции';
            case 'partial':
                return `Отсутствует ${getCardOptoinsLabel(props.missingServicesCount)}`;
            case 'full':
                return getCardOptoinsLabel(props.totalServices);
        }
    }, [matchedType, props]);
    const tooltipId = `product-${props.productId}`;
    if (matchedType === 'partial') {
        return (_jsx(Hint, { id: tooltipId, label: cardLabel, className: classNames(classes.hintText, isHighlighted && classes.highlighted, isInteractive && classes.interactive), title: "\u041E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0449\u0438\u0435 \u043E\u043F\u0446\u0438\u0438", actionType: "agree", missingOptionsImage: true, children: _jsx(MissingServicesHint, { lowerSumServices: props.lowerSumServices, missingServices: props.missingServices.filter(({ code }) => !SERVICES_HIDDEN_FROM_DISPLAYING.includes(code)) }) }));
    }
    return (_jsx("span", { className: classNames(classes.hintText, isHighlighted && classes.highlighted), children: cardLabel }));
});
