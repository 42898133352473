import { createUseStyles } from 'react-jss';
import { DESKTOP_FILTERS_BREAKPOINT, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
const getBackground = (theme) => ({
    light: theme.colors.cloud,
    dark: theme.colors.header,
})[theme.mode];
export const useStyles = createUseStyles((theme) => ({
    container: {
        backgroundColor: getBackground(theme),
        top: 0,
        zIndex: 1,
        position: 'sticky',
        marginTop: -53,
        height: 53,
        boxShadow: theme.elevations.cloud,
        transition: theme.transition.global,
        padding: '8px 15px',
        '&[data-elevation="true"]': {
            boxShadow: theme.elevations[1],
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            padding: '8px 16px',
        },
    },
    content: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 15,
        fontWeight: 500,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.sky,
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100% - 50px)',
    },
    subtitle: {
        fontSize: 13,
        color: theme.colors.textPrimary,
        letterSpacing: GLOBAL_LETTER_SPACING,
    },
    buttonContainer: {
        position: 'absolute',
        right: 0,
        textAlign: 'center',
    },
    button: {
        width: 32,
        height: 32,
        borderRadius: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.brandTertiary,
        boxShadow: `0px 0px 7px 8px ${getBackground(theme)}`,
        '& svg > path': {
            fill: theme.colors.brand,
        },
    },
}), { name: 'travel-form' });
