import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { addDays, formatDate, isAfter } from '@che-ins-ui/datepicker';
import { ABROAD_CODE } from '@/libs/static';
import { analytics } from '@/libs/analytics';
import { requestActions } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { useLocalStorage } from '@/libs/hooks/useLocalStorage';
import { MustReadInfoBlock, MustReadInfoOptionEnabler, } from './MustReadInfoBlock';
import { useStyles } from './styles';
let hasSentEvent = false;
const onEnable = () => {
    if (!hasSentEvent) {
        hasSentEvent = true;
        analytics.sendEvent({
            action: 'travel_abroad_recommendations_selection',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
};
export const MustReadAbroad = memo(({ withRussia, dateStart }) => {
    const isPotentialAbroadDate = Boolean(dateStart &&
        !isAfter(new Date(formatDate(dateStart, true)), addDays(new Date(), 2)));
    if (!withRussia && !isPotentialAbroadDate) {
        return null;
    }
    return (_jsx(Component, { isRussiaAppearance: withRussia, isPotentialAbroadDate: isPotentialAbroadDate }));
});
const Component = ({ isRussiaAppearance, isPotentialAbroadDate, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { services } = useSelector(requestSelector);
    const [isOpened, setIsOpened] = useLocalStorage('must-read-abroad', true);
    const tabIndex = isOpened ? 0 : -1;
    const isAbroadEnabled = Boolean(services?.[ABROAD_CODE]);
    const allEnabled = isAbroadEnabled;
    const enabler = (_jsx(MustReadInfoOptionEnabler, { label: "\u042F \u0443\u0436\u0435 \u0437\u0430 \u0433\u0440\u0430\u043D\u0438\u0446\u0435\u0439", isActive: isAbroadEnabled, onCheck: () => {
            toggleService(ABROAD_CODE);
            onEnable();
        }, tabIndex: tabIndex }));
    const toggleService = (serviceCode) => {
        dispatch(requestActions.setServices({
            [serviceCode]: services[serviceCode] ? 0 : 1,
        }));
    };
    return (_jsx(MustReadInfoBlock, { isOpened: isOpened, setIsOpened: setIsOpened, icon: _jsx(motion.svg, { width: 32, height: 32, viewBox: "0 -960 960 960", xmlns: "http://www.w3.org/2000/svg", variants: { main: { rotate: isAbroadEnabled ? 180 : 0 } }, initial: "initial", animate: "main", children: _jsx("path", { d: "m300-300 280-80 80-280-280 80-80 280Zm180-120q-25 0-42.5-17.5T420-480q0-25 17.5-42.5T480-540q25 0 42.5 17.5T540-480q0 25-17.5 42.5T480-420Zm0 340q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Zm0-320Z" }) }), title: isRussiaAppearance
            ? 'Путешествуете по России?'
            : 'Уже за границей?', areAllItemsEnabled: allEnabled, children: _jsxs("div", { className: classes.infoContainer, children: [_jsx("div", { children: _jsx("p", { className: classes.info, children: isRussiaAppearance
                            ? 'Если на момент покупки полиса туристы покинули место постоянного проживания (находятся более чем в 90 км от границы населенного пункта), примените условие:'
                            : 'Если на момент покупки полиса туристы уже выехали за пределы страны постоянного проживания (пересекли границу), примените условие:' }) }), enabler, isPotentialAbroadDate && (_jsx("p", { className: classes.info, children: "\u041E\u0431\u044B\u0447\u043D\u043E \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u044B\u0435 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438 \u0441\u0442\u0440\u0430\u0445\u0443\u044E\u0442 \u0442\u0443\u0440\u0438\u0441\u0442\u043E\u0432, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0443\u0436\u0435 \u0437\u0430 \u0440\u0443\u0431\u0435\u0436\u043E\u043C, \u0441 \u043E\u0442\u0441\u0440\u043E\u0447\u043A\u043E\u0439 \u043D\u0430\u0447\u0430\u043B\u0430 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F. \u0427\u0442\u043E\u0431\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0432\u0430\u0440\u0438\u0430\u043D\u0442\u044B \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F, \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0441\u0434\u0432\u0438\u043D\u0443\u0442\u044C \u0434\u0430\u0442\u0443 \u043D\u0430\u0447\u0430\u043B\u0430 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0438 \u043D\u0430 4-6 \u0434\u043D\u0435\u0439 \u0432\u043F\u0435\u0440\u0435\u0434 \u043E\u0442 \u0434\u0430\u0442\u044B \u043F\u043E\u043A\u0443\u043F\u043A\u0438." }))] }) }));
};
