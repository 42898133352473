import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { mixins } from '@/styles/mixins';
export const FILTERS_PANEL_BOTTOM = 40;
export const useStyles = createUseStyles((theme) => ({
    searchWrapper: {
        borderRadius: '0 0 12px 12px',
        width: '100%',
        backgroundColor: theme.colors.cloud,
        boxShadow: theme.elevations.cloud,
        padding: 12,
        '& svg': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
            marginTop: 1,
            '& path': {
                fill: theme.colors.textSecondary,
            },
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            borderRadius: 12,
            padding: 0,
        },
    },
    searchInputContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        height: 50,
        borderRadius: 12,
        width: '100%',
        border: `1px solid ${theme.colors.textTertiary}`,
        backgroundColor: theme.colors.cloud,
        outline: '0px solid transparent',
        color: theme.colors.sky,
        '-webkit-tap-highlight-color': 'transparent',
        transition: 'outline 0.2s',
        '&[data-active="true"]': {
            borderColor: 'transparent',
            outline: `2px solid ${theme.colors.brand}`,
        },
        ...mixins.inputPlaceholderColor(theme.colors.textSecondary),
        fontSize: MOBILE_FONT_SIZES.input,
        letterSpacing: GLOBAL_LETTER_SPACING,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.input,
            border: 'none',
        },
        [theme.breakpoints.up(BREAKPOINTS.md)]: {
            height: 42,
        },
    },
    searchEraseButton: {
        position: 'absolute',
        borderRadius: 999,
        width: 46,
        height: 46,
        right: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        transform: 'translateY(-50%)',
        outlineOffset: -8,
        '& svg': {
            width: 20,
            height: 20,
            '& path': {
                fill: theme.colors.brand,
            },
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            width: 40,
            height: 40,
            right: 2,
            '& svg': {
                width: 17,
                height: 17,
            },
        },
    },
    searchNotFound: {
        fontSize: MOBILE_FONT_SIZES.small,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.sky,
        textAlign: 'center',
        marginTop: 8,
    },
    searchSecretResultContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    searchAnimationContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        isolation: 'isolate',
        height: 140,
    },
}), { name: 'filter-search' });
