import { TABS_COMPONENTS_NAMES } from '@che-ins-ui/tabs';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, GLOBAL_LINE_HEIGHT, MOBILE_FONT_SIZES, } from './consts';
export const getTabsTheme = (theme) => ({
    base: {
        [TABS_COMPONENTS_NAMES.tabLabel]: {
            letterSpacing: GLOBAL_LETTER_SPACING,
            lineHeight: GLOBAL_LINE_HEIGHT,
            fontSize: MOBILE_FONT_SIZES.main,
            '&[data-selected="true"]': {
                color: theme.colors.sky,
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
        [TABS_COMPONENTS_NAMES.highlighter]: {
            background: theme.colors.brand,
        },
    },
    'offer-modal-tabs': {
        [TABS_COMPONENTS_NAMES.tabsList]: {
            '&:after': {
                content: '""',
                display: 'inline-block',
                flexShrink: 0,
                width: '13px',
            },
        },
        [TABS_COMPONENTS_NAMES.tabLabel]: {
            color: theme.colors.sky,
        },
    },
    verticalTabList: {
        [TABS_COMPONENTS_NAMES.highlighter]: {
            display: 'none',
        },
        [TABS_COMPONENTS_NAMES.tabsList]: {
            backgroundColor: theme.colors.cloud,
            width: '200px',
            flexDirection: 'column',
            borderRadius: '12px',
            background: theme.colors.white,
            padding: '5px',
            flexShrink: 0,
            display: 'none',
            alignSelf: 'flex-start',
            boxShadow: theme.elevations.cloud,
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        [TABS_COMPONENTS_NAMES.tabLabel]: {
            borderRadius: '8px',
            padding: '8px',
            color: theme.colors.brand,
            marginLeft: '0px',
            textAlign: 'start',
            '&[data-selected="true"]': {
                color: theme.colors.brand,
                background: theme.colors.brandTertiary,
            },
        },
    },
});
