import { formatDate, isBefore } from '@che-ins-ui/datepicker';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusOutside } from '@/libs/hooks';
import { MULTIPOLICY_CODE } from '@/libs/static';
import { getDateEndWithDefaultOffset, requestActions } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { getDayTimestamp } from './helpers';
export const useDatepickerControls = () => {
    const dispatch = useDispatch();
    const startInputRef = useRef(null);
    const endInputRef = useRef(null);
    const { dateStart, dateEnd, services } = useSelector(requestSelector);
    const multipolicyValue = services[MULTIPOLICY_CODE];
    const isMultipolicySelected = Boolean(multipolicyValue);
    const setStartDate = (date) => {
        dispatch(requestActions.setDateStart(date));
    };
    const setEndDate = (date) => {
        dispatch(requestActions.setDateEnd(date));
    };
    const onReset = () => {
        setStartDate(null);
        if (isMultipolicySelected) {
            disableMultipolicy();
        }
        setEndDate(null);
        setFocused('start');
    };
    const containerRef = useRef(null);
    const [focused, _setFocused] = useState(null);
    const [lastOpenedType, setLastOpenedType] = useState('start');
    const [fallbackDates, setFallbackDates] = useState({
        dateStart,
        dateEnd,
    });
    const isFocused = Boolean(focused);
    const prevIsFocused = useRef(isFocused);
    useEffect(() => {
        if (isFocused) {
            if (!prevIsFocused.current) {
                setFallbackDates({ dateStart, dateEnd });
            }
        }
        else {
            if (!dateStart) {
                setStartDate(fallbackDates.dateStart);
            }
            const start = dateStart
                ? new Date(formatDate(dateStart, true))
                : null;
            if (!dateEnd && start) {
                if (fallbackDates.dateEnd &&
                    isBefore(start, new Date(formatDate(fallbackDates.dateEnd, true)))) {
                    setEndDate(fallbackDates.dateEnd);
                }
                else {
                    setEndDate(formatDate(getDateEndWithDefaultOffset(start)));
                }
            }
            setFallbackDates({ dateStart: null, dateEnd: null });
        }
        prevIsFocused.current = isFocused;
    }, [isFocused, dateStart, dateEnd]);
    const setFocused = (type) => {
        if (type) {
            setLastOpenedType(type);
        }
        _setFocused(type);
    };
    const selectDate = (date) => {
        const formattedDate = formatDate(date);
        if (focused === 'start') {
            setStartDate(formattedDate);
            if (dateEnd &&
                getDayTimestamp(date) >
                    getDayTimestamp(new Date(formatDate(dateEnd, true)))) {
                setEndDate(null);
            }
            if (endInputRef.current) {
                endInputRef.current.focus();
            }
        }
        else {
            if (isMultipolicySelected) {
                disableMultipolicy();
            }
            if (dateStart &&
                getDayTimestamp(date) <
                    getDayTimestamp(new Date(formatDate(dateStart, true)))) {
                setEndDate(null);
                setStartDate(formattedDate);
            }
            else {
                setEndDate(formattedDate);
                if (dateStart) {
                    setFocused(null);
                }
                else {
                    if (startInputRef.current) {
                        startInputRef.current.focus();
                    }
                }
            }
        }
    };
    const enableMultipolicy = (value) => {
        const parsedValue = parseInt(value, 10);
        const multipolicyValue = isNaN(parsedValue) ? value : parsedValue;
        dispatch(requestActions.setServices({
            [MULTIPOLICY_CODE]: multipolicyValue,
        }));
    };
    const disableMultipolicy = () => {
        enableMultipolicy('0');
    };
    useFocusOutside(containerRef, () => {
        setFocused(null);
    });
    return {
        dateStart,
        dateEnd,
        isMultipolicySelected,
        multipolicyValue,
        containerRef,
        lastOpenedType,
        focused,
        setFocused,
        startInputRef,
        endInputRef,
        selectDate,
        onReset,
        enableMultipolicy,
        disableMultipolicy,
    };
};
