import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { analytics } from '@/libs/analytics';
import { useLocalStorage } from '@/libs/hooks/useLocalStorage';
import { useStyles } from './styles';
import { MustReadInfoBlock } from './MustReadInfoBlock';
let hasSentEvent = false;
const Icon = () => (_jsx("svg", { width: 32, height: 32, viewBox: "0 -960 960 960", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M480-79q-16 0-30.5-6T423-102L102-423q-11-12-17-26.5T79-480q0-16 6-31t17-26l321-321q12-12 26.5-17.5T480-881q16 0 31 5.5t26 17.5l321 321q12 11 17.5 26t5.5 31q0 16-5.5 30.5T858-423L537-102q-11 11-26 17t-31 6Zm0-80 321-321-321-321-321 321 321 321Zm-40-281h80v-240h-80v240Zm40 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm0-160Z" }) }));
const Info = () => {
    const classes = useStyles();
    useEffect(() => {
        if (hasSentEvent) {
            return;
        }
        hasSentEvent = true;
        analytics.sendEvent({
            action: 'travel_israel_lebanon_recommendations_display',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }, []);
    return (_jsx("div", { className: classes.infoContainer, children: _jsx("div", { children: _jsx("p", { className: classes.info, children: "\u041C\u0418\u0414 \u0420\u0424 \u0440\u0435\u043A\u043E\u043C\u0435\u043D\u0434\u0443\u0435\u0442 \u0440\u043E\u0441\u0441\u0438\u0439\u0441\u043A\u0438\u043C \u0433\u0440\u0430\u0436\u0434\u0430\u043D\u0430\u043C \u0432\u043E\u0437\u0434\u0435\u0440\u0436\u0430\u0442\u044C\u0441\u044F \u043E\u0442 \u043F\u043E\u0435\u0437\u0434\u043E\u043A \u0432 \u0418\u0437\u0440\u0430\u0438\u043B\u044C, \u041B\u0438\u0432\u0430\u043D \u0438 \u043D\u0430 \u043F\u0430\u043B\u0435\u0441\u0442\u0438\u043D\u0441\u043A\u0438\u0435 \u0442\u0435\u0440\u0440\u0438\u0442\u043E\u0440\u0438\u0438, \u0437\u0430 \u0438\u0441\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0435\u043C \u0441\u043B\u0443\u0447\u0430\u0435\u0432 \u043A\u0440\u0430\u0439\u043D\u0435\u0439 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E\u0441\u0442\u0438. \u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435 \u0432\u043D\u0438\u043C\u0430\u043D\u0438\u0435, \u0447\u0442\u043E \u0442\u0440\u0430\u0432\u043C\u044B, \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u043D\u044B\u0435 \u0432 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0435 \u0432\u043E\u0435\u043D\u043D\u044B\u0445 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0439, \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u043E\u0439 \u043D\u0435 \u043F\u043E\u043A\u0440\u044B\u0432\u0430\u044E\u0442\u0441\u044F!" }) }) }));
};
export const MustReadIsrael = memo(() => {
    const [isOpened, setIsOpened] = useLocalStorage('must-read-israel', true);
    return (_jsx(MustReadInfoBlock, { isOpened: isOpened, setIsOpened: setIsOpened, icon: _jsx(Icon, {}), title: "\u0421\u043E\u0431\u0440\u0430\u043B\u0438\u0441\u044C \u0432 \u0418\u0437\u0440\u0430\u0438\u043B\u044C?", children: _jsx(Info, {}) }));
});
export const MustReadLebanon = memo(() => {
    const [isOpened, setIsOpened] = useLocalStorage('must-read-lebanon', true);
    return (_jsx(MustReadInfoBlock, { isOpened: isOpened, setIsOpened: setIsOpened, icon: _jsx(Icon, {}), title: "\u0421\u043E\u0431\u0440\u0430\u043B\u0438\u0441\u044C \u0432 \u041B\u0438\u0432\u0430\u043D?", children: _jsx(Info, {}) }));
});
