import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { getTouristsLabelValue } from '@/libs/helpers/utils';
import { useFocusOutside } from '@/libs/hooks';
import { setTouristAge } from '@/models/forms/setTouristAge';
import { debounce, declOfNum } from '@/libs/helpers';
import { getTypes } from '@/libs/static';
import { formsActions } from '@/models/forms';
import { MAX_AGE, MAX_TOURISTS_AMOUNT } from '@/libs/constants';
import { analytics } from '@/libs/analytics';
import { TravelFormInput } from '../TravelFormInput/TravelFormInput';
import { TravelFormDropdown } from '../TravelFormDropdown/TravelFormDropdown';
import { useStyles } from './styles';
const onTouristsInteraction = () => {
    analytics.sendEvent({
        action: 'travel_tourists_interaction',
        label: 'Действие пользователя',
        category: 'Cherehapa',
    });
};
export const TouristsPicker = memo(() => {
    const classes = useStyles();
    const tourists = useSelector(touristsFormsSelector);
    const [isFocused, setIsFocused] = useState(false);
    const containerRef = useRef(null);
    const onOutsideEvent = () => {
        setIsFocused(false);
    };
    useFocusOutside(containerRef, onOutsideEvent);
    return (_jsx("div", { className: classes.root, children: _jsxs("div", { className: classes.relative, ref: containerRef, children: [_jsx(TravelFormInput, { value: getTouristsLabelValue(tourists), label: "\u0422\u0443\u0440\u0438\u0441\u0442\u044B", isFocused: isFocused, onFocus: () => setIsFocused(true), onClose: () => setIsFocused(false), type: "tourists" }), _jsx(TravelFormDropdown, { isOpenend: isFocused, className: classes.dropdown, shouldStretch: true, children: _jsx(Dropdown, {}) })] }) }));
});
const Dropdown = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const tourists = useSelector(touristsFormsSelector);
    return (_jsx("div", { className: classes.content, children: _jsx("div", { className: classes.tourists, children: _jsxs(AnimatePresence, { initial: false, mode: "popLayout", children: [tourists.map(({ age, isNew, _uuid }, index) => (_jsx(Tourist, { _uuid: _uuid, age: age, isNew: isNew, isRemovable: tourists.length > 1, noExitAnimation: index + 1 === MAX_TOURISTS_AMOUNT }, _uuid))), tourists.length < MAX_TOURISTS_AMOUNT && (_jsx(motion.button, { onClick: () => {
                            dispatch(formsActions.addTourist());
                            onTouristsInteraction();
                        }, tabIndex: -1, className: classes.addTouristButton, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u0443\u0440\u0438\u0441\u0442\u0430" }, "button"))] }) }) }));
});
const getNumberValue = (value) => value === '' || isNaN(Number(value)) ? null : Number(value);
const Tourist = memo(({ age: propsAge, isRemovable, _uuid, isNew, noExitAnimation, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const setStoreAge = (age) => {
        dispatch(setTouristAge({ _uuid, age }));
    };
    const [age, _setAge] = useState(isNew ? '' : String(propsAge));
    const ageNumberValue = getNumberValue(age);
    const debouncedSetStoreAge = useMemo(() => debounce((age) => setStoreAge(age), 400), [_uuid]);
    const setAge = (age) => {
        _setAge(age === '0' ? age : age.replace(/^0+/, ''));
        const ageNumberValue = getNumberValue(age);
        onTouristsInteraction();
        if (ageNumberValue !== null) {
            debouncedSetStoreAge(ageNumberValue);
        }
    };
    const onDelete = () => {
        dispatch(formsActions.removeTourist(_uuid));
        onTouristsInteraction();
    };
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        const handleFocus = () => setIsFocused(true);
        const handleBlur = () => setIsFocused(false);
        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.addEventListener('focus', handleFocus);
            inputElement.addEventListener('blur', handleBlur);
        }
        return () => {
            if (inputElement) {
                inputElement.removeEventListener('focus', handleFocus);
                inputElement.removeEventListener('blur', handleBlur);
            }
        };
    }, [inputRef]);
    useEffect(() => {
        if (!isNew || !inputRef.current) {
            return;
        }
        inputRef.current.focus();
        return () => { };
    }, [isNew]);
    const getPostfix = () => {
        if (ageNumberValue === null) {
            return null;
        }
        const label = declOfNum(ageNumberValue, getTypes('AGE'));
        return label + (ageNumberValue === 0 ? ' (менее года)' : '');
    };
    const postfix = getPostfix();
    const getButtonType = () => {
        if (!isFocused || age === '') {
            if (!isRemovable) {
                return null;
            }
            return 'remove';
        }
        return 'apply';
    };
    const buttonType = getButtonType();
    return (_jsxs(motion.div, { className: classes.tourist, variants: {
            hidden: noExitAnimation ? {} : { scale: 0.9, opacity: 0 },
            visible: { scale: 1, opacity: 1 },
        }, transition: { duration: 0.15 }, layout: true, initial: "hidden", animate: "visible", exit: "hidden", children: [_jsx("div", { className: classes.agePlaceholder, children: age }), _jsx("input", { value: age, onChange: (e) => {
                    setAge(e.target.value.replace(/\D/g, ''));
                }, type: "text", maxLength: 2, inputMode: "decimal", autoComplete: "off", placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0432\u043E\u0437\u0440\u0430\u0441\u0442", tabIndex: -1, onKeyDown: (e) => {
                    if (ageNumberValue === null) {
                        return;
                    }
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        inputRef.current?.blur();
                    }
                    else if (e.key === 'ArrowUp' &&
                        ageNumberValue < MAX_AGE - 1) {
                        e.preventDefault();
                        setAge(String(ageNumberValue + 1));
                    }
                    else if (e.key === 'ArrowDown' &&
                        ageNumberValue > 0) {
                        e.preventDefault();
                        setAge(String(ageNumberValue - 1));
                    }
                }, onBlur: () => {
                    if (age === '' && !isNew) {
                        setAge('0');
                    }
                }, className: classes.touristInput, ref: inputRef }), buttonType && (_jsx("button", { className: classes.deleteButton, "data-input-focused": isFocused, onMouseDown: (e) => {
                    if (buttonType === 'apply') {
                        e.preventDefault();
                    }
                }, onClick: (e) => {
                    if (buttonType === 'remove') {
                        onDelete();
                    }
                    if (buttonType === 'apply') {
                        inputRef.current?.blur();
                    }
                }, tabIndex: -1, "data-type": buttonType, children: _jsx("div", { children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", width: 22, height: 22, children: [buttonType === 'apply' && (_jsx("path", { d: "m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" })), buttonType === 'remove' && (_jsx("path", { d: "m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" }))] }) }) })), postfix && (_jsxs("span", { className: classes.inputPostfix, "data-input-focused": isFocused, children: ['\u00a0\u00a0', postfix] }))] }));
});
