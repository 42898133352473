import { allSportTagValues } from '@/components/filters/sportSearchTags';
import { ABROAD_CODE, ALCOHOL_ASSISTANCE_CODE, ALLERGY_ARRESTING_CODE, ASSISTANCE_CODE, CHILDREN_RETURN_CODE, CHRONIC_ARRESTING_CODE, COVID_CODE, DENGE_CODE, DISASTER_ASSISTANCE_CODE, DIVING_CHAMBER_CODE, FOREIGN_CODE, FRANCHISE_CODE, INSURED_RETURN_CODE, MEDICINE_CODE, MULTIPOLICY_CODE, OBSERVATION_CODE, PREGNANCY_CODE, RELATIVE_ILL_RETURN_CODE, RESCUE_ACTIVITIES_CODE, SEARCH_ACTIVITIES_CODE, SUNBURN_ASSISTANCE_CODE, TERROR_ASSISTANCE_CODE, THIRD_PARTY_TRANSPORTATION_CODE, TRIP_CANCEL_CODE, VISA_CANCEL_CODE, ISOLATION_CODE, ACCIDENT_CODE, AVIA_CARGO_CODE, CIVIL_LIABILITY_CODE, DELAY_CARGO_CODE, REGULAR_DELAY_CODE, DOCUMENT_CODE, LEGAL_CODE, AUTO_CODE, WORK_CODE, OFFICE_CODE, EDUCATION_CODE, MOTORCYCLE_CODE, } from '@/libs/static';
export const SPORTS_SEARCH_CODE = 'sports';
export const ACTIVE_REST_OPTIONS_SEARCH_CODE = 'active_rest';
export const MEDICINE_SUM_SEARCH_CODE = 'medicine_sum';
export const SECRET_SEARCH_CODE = 'secret';
export const searchTags = {
    [FOREIGN_CODE]: ['иностран', 'граждан', 'я', 'не', 'росс'],
    [ABROAD_CODE]: ['за', 'границ', 'я', 'уже', 'путешеств'],
    [MULTIPOLICY_CODE]: ['год', 'годов', 'полис'],
    [FRANCHISE_CODE]: ['франшиз', 'franchise', 'без'],
    [COVID_CODE]: ['covid', 'ковид', 'корон', 'вирус', '19'],
    [OBSERVATION_CODE]: [
        'обсервац',
        'covid',
        'ковид',
        'корон',
        'вирус',
        '19',
        'наблюд',
        'карант',
    ],
    [ASSISTANCE_CODE]: ['ассист', 'асист', 'assis', 'asis'],
    [MEDICINE_CODE]: [
        'медицин',
        'страх',
        'мед',
        'амбул',
        'лечен',
        'прием',
        'приём',
        'врач',
        'меди',
        'больн',
        'возмещ',
        'расход',
        'лекар',
        'репатр',
        'смерт',
        'расход',
        'переговор',
        'сервис',
        'центр',
        'экстрен',
        'стоматол',
        'зуб',
        'зубн',
        'травм',
        'транспорти',
    ],
    [MEDICINE_SUM_SEARCH_CODE]: [
        'валюта',
        'евро',
        'сумма',
        'доллар',
        'usd',
        'eur',
        'euro',
        'sum',
        '30',
        '35',
        '40',
        '50',
        '100',
    ],
    [INSURED_RETURN_CODE]: ['перелет', 'после', 'лечен'],
    [CHILDREN_RETURN_CODE]: ['эвакуац', 'дети', 'ребен', 'ребён'],
    [TERROR_ASSISTANCE_CODE]: ['террор', 'акт', 'теракт'],
    [DISASTER_ASSISTANCE_CODE]: ['стих', 'бедств', 'наводн', 'цунам', 'земля'],
    [CHRONIC_ARRESTING_CODE]: ['хроническ', 'заболеван'],
    [ALLERGY_ARRESTING_CODE]: ['аллерг', 'алерг'],
    [SUNBURN_ASSISTANCE_CODE]: ['солнечн', 'ожог', 'загар'],
    [ALCOHOL_ASSISTANCE_CODE]: ['алкогол', 'опьян', 'пьян', 'травм'],
    [PREGNANCY_CODE]: ['берем'],
    [RELATIVE_ILL_RETURN_CODE]: ['досрочн', 'возвращен'],
    [THIRD_PARTY_TRANSPORTATION_CODE]: ['визит', 'родствен'],
    [DENGE_CODE]: ['лихорадк', 'денг'],
    [SPORTS_SEARCH_CODE]: [
        'спорт',
        'sport',
        'любител',
        'проф',
        'актив',
        'отдых',
        ...allSportTagValues,
    ],
    [MOTORCYCLE_CODE]: ['moto', 'мото', 'байк', 'мопед'],
    [SEARCH_ACTIVITIES_CODE]: ['эваку', 'вертол'],
    [RESCUE_ACTIVITIES_CODE]: ['поиск', 'спасател', 'меропр'],
    [DIVING_CHAMBER_CODE]: ['лечен', 'дайвинг', 'барок'],
    [TRIP_CANCEL_CODE]: ['отмен', 'поездк'],
    [VISA_CANCEL_CODE]: ['отмен', 'виза', 'отказ', 'visa'],
    [ISOLATION_CODE]: ['изоляц', 'карантин'],
    [ACCIDENT_CODE]: [
        'случ',
        'несчас',
        'авар',
        'происш',
        'проиш',
        'паден',
        'травм',
        'нс',
    ],
    [AVIA_CARGO_CODE]: ['страх', 'багаж', 'груз', 'ручна', 'кладь'],
    [CIVIL_LIABILITY_CODE]: ['граждан', 'ответс'],
    [DELAY_CARGO_CODE]: ['задерж', 'груз', 'багаж'],
    [REGULAR_DELAY_CODE]: ['задерж', 'рейс', 'полет'],
    [DOCUMENT_CODE]: ['потер', 'паспорт', 'докуме'],
    [LEGAL_CODE]: ['юрист', 'юридич', 'адвокат', 'помощ', 'закон'],
    [AUTO_CODE]: ['авто', 'трансп', 'личн', 'поездк'],
    [WORK_CODE]: ['работ', 'труд', 'профес', 'производ', 'промышл', 'професс'],
    [OFFICE_CODE]: [
        'работ',
        'офис',
        'компан',
        'бизнес',
        'предприним',
        'предприяти',
        'организац',
        'команд',
    ],
    [EDUCATION_CODE]: [
        'учеб',
        'учёб',
        'образов',
        'школ',
        'коледж',
        'колледж',
        'универс',
        'инстит',
        'обучен',
    ],
    [SECRET_SEARCH_CODE]: [
        'жопа',
        'пизд',
        'ху',
        'хуй',
        'хуе',
        'хуё',
        'хуйн',
        'хуев',
        'хуёв',
    ],
};
