import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { motion } from 'framer-motion';
import { withAlpha } from '@/styles/utils';
import { useCheTheme } from '@/styles/themes';
import { getServiceBackgroundColor, useTableStyles } from './tableStyles';
import { ComparingTagTooltip } from './ComparingTagTooltip';
export const ComparingTableService = memo(({ value, isHidden, isCentered, isEven, isDisadvantage, description, }) => {
    const theme = useCheTheme();
    const classes = useTableStyles();
    const animatableTransparent = withAlpha(theme.colors.white, 0);
    const serviceBackgroundColor = getServiceBackgroundColor(theme);
    const variants = {
        hidden: {
            opacity: 0,
            scaleY: 0.2,
            scaleX: 0.9,
            height: 0,
            padding: '0px 8px 0px',
            borderColor: animatableTransparent,
            backgroundColor: animatableTransparent,
            borderWidth: 0,
            originX: isCentered ? 0.5 : 1,
        },
        visible: {
            opacity: 1,
            scaleY: 1,
            scaleX: 1,
            height: 25,
            padding: '12px 8px',
            borderWidth: 1,
            borderColor: isEven
                ? theme.colors.textTertiary
                : animatableTransparent,
            backgroundColor: isEven
                ? serviceBackgroundColor
                : animatableTransparent,
            originX: isCentered ? 0.5 : 1,
        },
    };
    return (_jsxs(motion.div, { variants: variants, initial: "hidden", transition: {
            borderWidth: {
                duration: 0.05,
            },
        }, animate: isHidden ? 'hidden' : 'visible', className: classes.service, "data-centered": isCentered, "data-disadvantage": isDisadvantage, children: [description && (_jsx(ComparingTagTooltip, { service: value, description: description, selected: true, position: "bottom" })), value] }));
});
