import { useScreenSizes } from '@che-ins-ui/theme';
import { useEffect, useMemo, useRef } from 'react';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { TRAVEL_FORM_HORIZONTAL_PADDING } from '@/styles/themes/components/containerTheme';
import { MONTH_BASE_WIDTH } from './styles';
export const useCalendarDimensions = ({ selectedStartDate, initialMonthIndex, inputsWidth, }) => {
    const { width, sm } = useScreenSizes();
    const minCalendarWidth = sm ? 0 : inputsWidth;
    const isDesktop = width > BREAKPOINTS['md'];
    const monthWidth = useMemo(() => {
        const baseWidth = Math.max(MONTH_BASE_WIDTH, minCalendarWidth / 2);
        if (isDesktop) {
            return baseWidth;
        }
        const remainingWidth = width - 2 * TRAVEL_FORM_HORIZONTAL_PADDING;
        if (TRAVEL_FORM_HORIZONTAL_PADDING * 2 + baseWidth * 2 >= width) {
            return Math.max(remainingWidth * 0.9, baseWidth);
        }
        return remainingWidth / 2;
    }, [width, minCalendarWidth]);
    const monthWidthRef = useRef(monthWidth);
    monthWidthRef.current = monthWidth;
    const withDateStartRef = useRef(selectedStartDate);
    withDateStartRef.current = selectedStartDate;
    const isSingleMonth = width < TRAVEL_FORM_HORIZONTAL_PADDING * 2 + MONTH_BASE_WIDTH * 2;
    const calendarWidth = isSingleMonth ? monthWidth : monthWidth * 2;
    const scrollableRef = useRef(null);
    const hasInitiallyScrolled = useRef(true);
    useEffect(() => {
        if (scrollableRef.current &&
            initialMonthIndex >= 0 &&
            withDateStartRef.current) {
            const offset = monthWidthRef.current * initialMonthIndex;
            scrollableRef.current.scrollTo({
                left: offset,
                behavior: hasInitiallyScrolled.current
                    ? 'instant'
                    : 'smooth',
            });
        }
        hasInitiallyScrolled.current = false;
    }, [initialMonthIndex]);
    const scroll = ({ direction, monthIndex, }) => {
        if (typeof monthIndex === 'number') {
            const offset = monthWidth * monthIndex;
            scrollableRef.current?.scrollTo({
                left: offset,
                behavior: 'smooth',
            });
        }
        if (direction) {
            const offset = monthWidth;
            scrollableRef.current?.scrollBy({
                left: direction === 'left' ? -offset : offset,
                behavior: 'smooth',
            });
        }
    };
    return {
        monthWidth,
        calendarWidth,
        isSingleMonth,
        scroll,
        scrollableRef,
    };
};
