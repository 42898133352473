import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        position: 'relative',
    },
    suggestionsWrapper: {
        width: '100%',
        position: 'absolute',
        top: 60,
        zIndex: 1,
        padding: '2px 0',
        backgroundColor: theme.colors.cloud,
        borderRadius: 12,
        boxShadow: theme.elevations[2],
        userSelect: 'none',
        pointerEvents: 'none',
        '&[data-shown="true"]': {
            pointerEvents: 'auto',
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            top: 52,
        },
    },
    suggestions: {
        padding: '3px 5px',
        maxHeight: 200,
        overflowY: 'auto',
        overscrollBehavior: 'contain',
        '&::-webkit-scrollbar': {
            width: 5,
            margin: '10px 0',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#485B75',
            borderRadius: 10,
        },
    },
    suggestion: {
        width: '100%',
        display: 'flex',
        gap: 10,
        cursor: 'pointer',
        padding: 8,
        borderRadius: 8,
        transition: theme.transition.global,
        '-webkit-tap-highlight-color': 'transparent',
        '-webkit-user-select': 'none',
        backgroundColor: theme.colors.cloud,
        border: 'none',
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: theme.colors.brandTertiary,
            '& $title': {
                color: theme.colors.brand,
            },
        },
    },
    titles: {
        userSelect: 'none',
        textAlign: 'start',
        flexGrow: 1,
        minWidth: 0,
    },
    title: {
        display: 'flex',
        gap: 6,
        transition: theme.transition.global,
        marginBottom: -3,
        color: theme.colors.sky,
        '& p': {
            fontSize: MOBILE_FONT_SIZES.main,
            letterSpacing: GLOBAL_LETTER_SPACING,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    emojiIconImage: {
        width: 16,
        height: 16,
        transform: 'translateY(2px)',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            transform: 'translateY(1.5px)',
        },
    },
    subtitle: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.small,
        letterSpacing: GLOBAL_LETTER_SPACING,
        textAlign: 'start',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    editWrapper: {
        cursor: 'pointer',
        flexShrink: 0,
        ...mixins.centeredContent(),
        padding: '9px 8px 9px 1px',
        margin: '-9px -8px -9px -1px',
        backgroundColor: 'transparent',
        border: 'none',
        '-webkit-tap-highlight-color': 'transparent',
        '&:hover': {
            '& $editIconWrapper': {
                border: `1px solid ${theme.colors.brand}`,
                backgroundColor: theme.colors.field,
            },
        },
    },
    editIconWrapper: {
        flexShrink: 0,
        height: 34,
        width: 34,
        borderRadius: 6,
        ...mixins.centeredContent(),
        border: `1px solid ${theme.colors.brandTertiary}`,
        transition: theme.transition.global,
        margin: 'auto 0',
        backgroundColor: 'transparent',
        '-webkit-tap-highlight-color': 'transparent',
        '& svg': {
            width: 13,
            height: 13,
        },
    },
    dropdownButton: {
        padding: 10,
        ...mixins.centeredContent(),
        marginRight: -7,
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        '-webkit-tap-highlight-color': 'transparent',
        '& div': {
            ...mixins.centeredContent(),
        },
        '&[data-hidden="true"]': {
            pointerEvents: 'none',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: -10,
        },
    },
    nonNotebookSuggestion: {
        width: '100%',
        display: 'flex',
        cursor: 'pointer',
        padding: '8px 8px 9px',
        borderRadius: 8,
        transition: theme.transition.global,
        color: theme.colors.sky,
        '-webkit-tap-highlight-color': 'transparent',
        backgroundColor: theme.colors.cloud,
        border: 'none',
        '&:hover': {
            backgroundColor: theme.colors.brandTertiary,
            '& p': {
                color: theme.colors.brand,
            },
        },
        '& p': {
            fontSize: MOBILE_FONT_SIZES.main,
            letterSpacing: GLOBAL_LETTER_SPACING,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
}), {
    name: 'input-with-suggestions',
});
