import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@che-ins-ui/modal';
import { requestCurrencySelector } from '@/models/request/selectors';
import { apiIsTomorrowCurrencyRateMoreThenToday } from '@/models/api/selectors';
import { HintModal } from '@/components/common/Hint';
import { IS_DEVELOPMENT } from '@/libs/constants';
const MORE_EXPENSIVE_TOMORROW_MODAL_NAME = 'MORE_EXPENSIVE_TOMORROW';
const title = 'Завтра страховки будут дороже';
const MessageNotification = () => {
    return (_jsxs(_Fragment, { children: [_jsx("p", { children: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043F\u043E\u043B\u0438\u0441\u043E\u0432 \u0432\u0441\u0435\u0433\u0434\u0430 \u0437\u0430\u0432\u0438\u0441\u0438\u0442 \u043E\u0442 \u043A\u0443\u0440\u0441\u0430 \u0432\u0430\u043B\u044E\u0442." }), _jsx("p", { children: "\u0417\u0430\u0432\u0442\u0440\u0430 \u043A\u0443\u0440\u0441 \u0432\u044B\u0448\u0435, \u043F\u043E\u044D\u0442\u043E\u043C\u0443 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0438 \u0431\u0443\u0434\u0443\u0442 \u0434\u043E\u0440\u043E\u0436\u0435." })] }));
};
const MessageModal = () => {
    return (_jsx("p", { children: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043F\u043E\u043B\u0438\u0441\u043E\u0432 \u0432\u0441\u0435\u0433\u0434\u0430 \u0437\u0430\u0432\u0438\u0441\u0438\u0442 \u043E\u0442 \u043A\u0443\u0440\u0441\u0430 \u0432\u0430\u043B\u044E\u0442. \u0417\u0430\u0432\u0442\u0440\u0430 \u043A\u0443\u0440\u0441 \u0432\u044B\u0448\u0435, \u043F\u043E\u044D\u0442\u043E\u043C\u0443 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0438 \u0431\u0443\u0434\u0443\u0442 \u0434\u043E\u0440\u043E\u0436\u0435." }));
};
let wasNotified = false;
export const MoreExpensiveTomorrowAlert = () => {
    const dispatch = useDispatch();
    const currency = useSelector(requestCurrencySelector);
    const isTomorrowCurrencyRateMoreThenToday = useSelector(apiIsTomorrowCurrencyRateMoreThenToday(currency));
    const needToShowModal = isTomorrowCurrencyRateMoreThenToday && !wasNotified;
    const timeoutRef = useRef();
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            if (needToShowModal && !IS_DEVELOPMENT) {
                dispatch(modalActions.open({
                    name: MORE_EXPENSIVE_TOMORROW_MODAL_NAME,
                }));
                wasNotified = true;
            }
        }, 1000);
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [needToShowModal]);
    return (_jsx(HintModal, { name: MORE_EXPENSIVE_TOMORROW_MODAL_NAME, title: title, type: "alert", actionType: "agree", moreExpensiveTomorrowImage: true, children: _jsx("div", { children: _jsx(MessageModal, {}) }) }));
};
