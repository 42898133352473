import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@/components/routes';
import { basketPriceSelector } from '@/models/basket/selectors';
import { mindboxApi } from '../mindbox/mindboxApi';
import { isIframe, removeTrailingSlash } from '../helpers';
import { WITH_WEBPUSHES } from '../constants';
export const useMindboxFormsPrice = () => {
    const location = useLocation();
    const pathname = removeTrailingSlash(location.pathname);
    const price = useSelector(basketPriceSelector);
    const isIframeWidget = isIframe();
    const prevPathnameRef = useRef(null);
    useEffect(() => {
        if (prevPathnameRef.current === ROUTES.calculation &&
            pathname === ROUTES.insurance &&
            price &&
            !isIframeWidget &&
            WITH_WEBPUSHES) {
            mindboxApi.calcCost(price);
        }
        prevPathnameRef.current = pathname;
    }, [pathname]);
};
