import { stringify } from 'qs';
import IMask from 'imask';
import { COUNTRY_CODES, getCountryMask, } from '@che-ins-ui/phone-input';
import { getAffiliateTool, getDateStartAndInsuranceDays, } from '@/libs/helpers/utils';
import { ROUTES } from '@/components/routes';
import { FRANCHISE_SERVICE, MEDICINE_CODE, MOTORCYCLE_CODE, } from '@/libs/static';
import { normalizeBasename } from '@/libs/helpers/normalizeBasename';
import { isIframe, transformToBoolean } from '@/libs/helpers';
import { UPSELL_ENABLED } from '@/libs/constants';
import { analytics } from '@/libs/analytics';
import { getInitialUrl } from '@/libs/hooks/useInitialUrlCookie';
import { ACTIVE_SPORTS_CODES_FOR_UPSELL } from '@/components/insurance/components/Upsell/consts';
import { upsellSelector } from '../upsell/selectors';
import { requestSelector } from '../request/selectors';
import { basketProductSelector, isInsurerInRussianSelector, } from '../basket/selectors';
import { CHEREHAPA_PARTNER_ID } from '../request';
import { getNotebookUsageRate } from '../notebook/helpers';
import { emailsSelector, insurerFormsSelector, touristsForApiSelector, touristsFormsSelector, } from '../forms/selectors';
import { transformInsurerForApi } from '../forms/helpers';
import { getMindBoxDeviceUUID } from '../user/helpers';
import { themeModeSelector } from '../theme';
import { get2FAParams } from './orderCreation2FA/orderCreation2FA';
export const prepareStoreModelToServerCreateOrder = async ({ state, code, isOrderCreation, }) => {
    const tourists = touristsForApiSelector(state);
    const insurer = insurerFormsSelector(state);
    const touristsForms = touristsFormsSelector(state);
    const { chosen: { activeRest: upsellActiveRest, [MEDICINE_CODE]: upsellMedicine, moto: upsellMoto, }, } = upsellSelector(state);
    const basketMedicine = basketProductSelector(state)?.calculation.serviceProduct[MEDICINE_CODE];
    const startingPrice = basketProductSelector(state)?.calculation.priceRub;
    const { isIgnoreUpsell } = requestSelector(state);
    const isUpsellAvailable = UPSELL_ENABLED && !isIgnoreUpsell;
    const hasMotoUpsell = isUpsellAvailable && Boolean(upsellMoto);
    const hasActiveRestUpsell = isUpsellAvailable && Boolean(upsellActiveRest);
    if (hasActiveRestUpsell) {
        analytics.sendEvent({
            action: 'active_rest_option',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
    const medicineUpsell = isUpsellAvailable &&
        upsellMedicine &&
        basketMedicine &&
        upsellMedicine > basketMedicine
        ? upsellMedicine
        : null;
    if (hasMotoUpsell) {
        analytics.sendEvent({
            action: 'vzr_upsale_motobyke',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
    const { basename } = requestSelector(state);
    const normalizedBasename = normalizeBasename(basename);
    const returnUrlAfterPayment = [
        window.location.origin,
        normalizedBasename,
        ROUTES.success,
        '?',
        stringify(getEcomercePurchaseParamsFromOffer(basketProductSelector(state))),
    ].join('');
    const productId = getProductId(state);
    const emails = emailsSelector(state);
    const [email, ...additionalEmails] = emails.map(({ value }) => value);
    const { phoneCountryCode, phoneBody } = insurer;
    const isInsurerInRussian = isInsurerInRussianSelector(state);
    const { assistances, countryGroups, sports, countries, dateStart: requestDateStart, dateEnd: requestDateEnd, partnerId, marker, marker2, srcsale, currency, isPaytureInPay, isFrame, } = state.request;
    const services = { ...state.request.services };
    delete services[FRANCHISE_SERVICE.code];
    const { dateStart, insuredDays, testDateEnd } = getDateStartAndInsuranceDays({
        dateStart: requestDateStart,
        dateEnd: requestDateEnd,
        services,
    });
    if (medicineUpsell) {
        services[MEDICINE_CODE] = medicineUpsell;
        analytics.sendEvent({
            action: `protect_more_${[100000, 120000].includes(medicineUpsell) ? 100 : 50}`,
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
    const payment = {
        returnUrlAfterPayment,
        returnUrlAfterPaymentForReissue: returnUrlAfterPayment,
        language: 'RU',
        isPaytureInPay: transformToBoolean(isPaytureInPay),
    };
    const phone = phoneCountryCode + phoneBody.replace(/\D/g, '');
    const isWhiteLabelPage = isIframe() || (isFrame && window.location.pathname === basename);
    const orderMarketingParams = partnerId
        ? {
            partnerId,
            marker,
            marker2,
            affiliateTool: getAffiliateTool(srcsale, Boolean(isWhiteLabelPage)),
        }
        : { partnerId: CHEREHAPA_PARTNER_ID };
    const notebookPersonsFilled = getNotebookUsageRate({
        ...state.notebook,
        insurer,
        touristsForms,
        isInsurerInRussian,
    });
    const body = {
        payment,
        insurer: transformInsurerForApi(insurer, isInsurerInRussian),
        tourists,
        assistances,
        countryGroups,
        sports: getModifiedSports({
            currentSports: sports,
            withActiveRest: hasActiveRestUpsell,
            withMoto: hasMotoUpsell,
        }),
        countries,
        services,
        additionalEmails,
        email,
        phone,
        phoneCountryCode: Number(phoneCountryCode),
        currency: currency.toUpperCase(),
        insuredDays,
        dateStart,
        testDateEnd,
        productId,
        verificationCode: code,
        ...(isOrderCreation && (await get2FAParams())),
        ...orderMarketingParams,
        ...(isUpsellAvailable &&
            getAdditionalPurchase({
                startingPrice: startingPrice,
                medicineUpsell,
                hasActiveRestUpsell,
                hasMotoUpsell,
            })),
        notebookPersonsFilled,
        initialUrl: isIframe() ? 'WL' : getInitialUrl(),
        deviceUUID: getMindBoxDeviceUUID() || null,
        theme: isIframe() ? 'WL' : themeModeSelector(state),
    };
    return body;
};
const wasChosenSomeUpsell = ({ medicineUpsell, hasActiveRestUpsell, hasMotoUpsell, }) => !!medicineUpsell || hasActiveRestUpsell || hasMotoUpsell;
const getAdditionalPurchase = ({ startingPrice, medicineUpsell, hasActiveRestUpsell, hasMotoUpsell, }) => wasChosenSomeUpsell({ medicineUpsell, hasActiveRestUpsell, hasMotoUpsell })
    ? {
        additionalPurchase: {
            startingPrice,
            activeRest: hasActiveRestUpsell,
            [MEDICINE_CODE]: medicineUpsell || null,
            [MOTORCYCLE_CODE]: hasMotoUpsell,
        },
    }
    : {};
const getProductId = (state) => {
    const { offers: upsellOffers, finalPrice: upsellFinalPrice } = upsellSelector(state);
    const { isIgnoreUpsell } = requestSelector(state);
    const basketProductId = basketProductSelector(state)?.calculation.productId;
    if (!UPSELL_ENABLED || isIgnoreUpsell) {
        return basketProductId;
    }
    const upsellProductId = upsellFinalPrice &&
        upsellOffers.find((offer) => offer.price === upsellFinalPrice)
            ?.productId;
    return upsellProductId ?? basketProductId;
};
const getEcommerceYMProduct = ({ name, price, }) => ({
    name,
    category: 'Страховой полис/Туристический',
    price: Number(price),
});
export const getEcommerceYMAction = (offer, actionType) => {
    const { company: { name }, calculation: { priceRub: price }, } = offer;
    const action = {
        [actionType]: {
            products: [getEcommerceYMProduct({ name, price })],
        },
    };
    return action;
};
export const getEcommerceYMPurchaseAction = (orderParams) => {
    const { orderUuid, price, name } = orderParams;
    if (!orderUuid || !price || !name) {
        return null;
    }
    const action = {
        purchase: {
            actionField: {
                id: orderUuid,
                revenue: Number(price),
                goal_id: 23763045,
            },
            products: [getEcommerceYMProduct({ name, price })],
        },
    };
    return action;
};
const getEcommerceGAProduct = ({ name, price, }) => ({
    item_name: name,
    item_category: 'Туристический',
    price: Number(Number(price).toFixed(2)),
    quantity: 1,
});
export const getEcommerceGAAction = (offer, actionType) => {
    const { company: { name }, calculation: { price }, } = offer;
    const action = {
        event: actionType,
        ecommerce: {
            currency: 'EUR',
            value: Number(Number(price).toFixed(2)),
            items: [getEcommerceGAProduct({ name, price })],
        },
    };
    return action;
};
export const getEcommerceGAPurchaseAction = (orderParams) => {
    const { orderUuid, p: price, name } = orderParams;
    if (!orderUuid || !price || !name) {
        return null;
    }
    const action = {
        event: 'purchase',
        ecommerce: {
            items: [getEcommerceGAProduct({ name, price })],
            transaction_id: orderUuid,
            value: Number(Number(price).toFixed(2)),
            currency: 'EUR',
        },
    };
    return action;
};
const getEcomercePurchaseParamsFromOffer = (offer) => ({
    price: offer.calculation.priceRub,
    name: offer.company.name,
    p: offer.calculation.price,
});
export const getMaskValue = (phone) => {
    return getCountryMask(COUNTRY_CODES.find((country) => country.countryCode === Number(phone)));
};
export const preparePhoneCodeCountryAndPhoneForStore = (phoneFull, phoneCountryCode) => {
    let phoneForStore = '', phoneCountryCodeForStore = '';
    if (phoneFull &&
        phoneCountryCode &&
        phoneFull.startsWith(String(phoneCountryCode))) {
        phoneCountryCodeForStore = String(phoneCountryCode);
        phoneForStore = phoneFull.replace(String(phoneCountryCode), '');
        phoneForStore = IMask.createMask({
            mask: getMaskValue(phoneCountryCodeForStore),
        }).resolve(phoneForStore);
    }
    if (phoneFull && !phoneCountryCode && phoneFull.startsWith('7')) {
        phoneCountryCodeForStore = '7';
        phoneForStore = phoneFull.replace('7', '');
        phoneForStore = IMask.createMask({
            mask: getMaskValue(phoneCountryCodeForStore),
        }).resolve(phoneForStore);
    }
    if (phoneFull && !phoneCountryCode && !phoneFull.startsWith('7')) {
        phoneCountryCodeForStore = '';
        phoneForStore = phoneFull;
    }
    return {
        phoneCountryCode: phoneCountryCodeForStore,
        phoneBody: phoneForStore,
    };
};
export const getModifiedSports = ({ currentSports, withActiveRest, withMoto, }) => {
    if (!withActiveRest && !withMoto) {
        return currentSports;
    }
    return [
        ...(withActiveRest ? ACTIVE_SPORTS_CODES_FOR_UPSELL : []),
        ...(withMoto ? [MOTORCYCLE_CODE] : []),
    ];
};
