import { useEffect, useState } from 'react';
import { formatDate } from '@che-ins-ui/datepicker';
import { getDayTimestamp } from './helpers';
const FIRST_DAY_OF_MONTH = 1;
const MONTH_IN_YEAR = 12;
export const useCalendarDates = ({ selectedStartDate, selectedEndDate, type, }) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const totalMonths = today.getDate() === FIRST_DAY_OF_MONTH
        ? MONTH_IN_YEAR
        : MONTH_IN_YEAR + 1;
    const [hoveredDate, _setHoveredDate] = useState(null);
    const [dateStartTimestamp, setDateStartTimestamp] = useState(null);
    const [dateEndTimestamp, setDateEndTimestamp] = useState(null);
    const setHoveredDate = (date) => {
        if (selectedEndDate && selectedEndDate)
            return;
        _setHoveredDate(date);
    };
    useEffect(() => {
        const startTimestamp = () => {
            if (type === 'start' &&
                hoveredDate &&
                (!dateEndTimestamp ||
                    getDayTimestamp(hoveredDate) < dateEndTimestamp)) {
                return getDayTimestamp(hoveredDate);
            }
            return selectedStartDate
                ? getDayTimestamp(new Date(formatDate(selectedStartDate, true)))
                : null;
        };
        setDateStartTimestamp(startTimestamp());
    }, [type, hoveredDate, dateEndTimestamp, selectedStartDate]);
    useEffect(() => {
        const endTimestamp = (() => {
            if (type === 'end' &&
                hoveredDate &&
                (!dateStartTimestamp ||
                    getDayTimestamp(hoveredDate) > dateStartTimestamp)) {
                return getDayTimestamp(hoveredDate);
            }
            return selectedEndDate
                ? getDayTimestamp(new Date(formatDate(selectedEndDate, true)))
                : null;
        })();
        setDateEndTimestamp(endTimestamp);
    }, [type, hoveredDate, dateStartTimestamp, selectedEndDate]);
    return {
        currentMonth,
        currentYear,
        totalMonths,
        dateStartTimestamp,
        dateEndTimestamp,
        setHoveredDate,
        areDatesUnselected: !selectedStartDate && !selectedEndDate,
    };
};
