import { createUseStyles } from 'react-jss';
import { withAlpha } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
import { BREAKPOINTS } from '@/styles/breakpoints';
const HEIGHT = 38;
const PADDING = 15;
const OVERLAY_WIDTH = 50;
export const getGradientEdgeBackground = (theme, direction, customColor) => {
    const color = customColor || theme.colors.background;
    if (color === 'transparent') {
        return 'none';
    }
    const alphaColor = (alpha) => withAlpha(color, alpha);
    const isRight = direction === 'right';
    return `linear-gradient(to ${isRight ? 'left' : 'right'}, ${alphaColor(1)}, ${alphaColor(0.7)}, ${alphaColor(0)})`;
};
export const useStyles = createUseStyles((theme) => ({
    container: {
        position: 'relative',
        marginBottom: 16,
        boxShadow: theme.elevations.cloud,
        borderRadius: 12,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            zIndex: 'auto',
        },
    },
    scrollable: {
        margin: `0 -${PADDING}px`,
        padding: `0 ${PADDING}px`,
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    scrollOverlay: {
        display: 'none',
        position: 'absolute',
        width: OVERLAY_WIDTH,
        height: HEIGHT + 1,
        top: -1,
        left: -OVERLAY_WIDTH,
        alignItems: 'center',
        '&[data-right="true"]': {
            left: 'auto',
            right: -OVERLAY_WIDTH,
        },
        [theme.breakpoints.up(BREAKPOINTS.xl)]: {
            display: 'flex',
            flexDirection: 'row-reverse',
            '&[data-right="true"]': {
                flexDirection: 'row',
            },
            '&[data-theme-changing="true"]': {
                display: 'none',
            },
        },
    },
    scrollButton: {
        position: 'relative',
        zIndex: 1,
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        flexShrink: 0,
        backgroundColor: theme.colors.cloud,
        boxShadow: theme.elevations[1],
        transition: 'opacity 0.15s',
        '&:disabled': {
            opacity: 0,
            pointerEvents: 'none',
        },
        '&[data-right="false"]': {
            '& svg': {
                transform: 'rotate(180deg)',
            },
        },
        '& svg > path': {
            fill: theme.colors.brand,
        },
        '&[data-calendar="true"]': {
            boxShadow: 'none',
        },
    },
    gradientEdge: {
        height: '100%',
        width: PADDING + 4,
        flexShrink: 0,
        background: getGradientEdgeBackground(theme, 'left'),
        '&[data-right="true"]': {
            background: getGradientEdgeBackground(theme, 'right'),
        },
    },
    contentWrapper: {
        minWidth: 'max-content',
        width: '100%',
        borderRadius: 12,
        height: HEIGHT,
        backgroundColor: theme.colors.cloud,
    },
    content: {
        padding: '0 12px',
        transition: '.2s',
        display: 'flex',
        gap: 6,
        flexWrap: 'wrap',
        overflow: 'hidden',
        height: '100%',
        alignItems: 'center',
    },
    sectionLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        flexShrink: 0,
        height: 28,
        padding: '0 2px',
        backgroundColor: theme.colors.cloud,
        '&[data-small-gap]': {
            gap: 4,
        },
        '& svg': {
            width: 18,
            height: 18,
            '& path': {
                fill: theme.colors.sky,
            },
        },
        '& span': {
            fontSize: 14,
            letterSpacing: GLOBAL_LETTER_SPACING,
            color: theme.colors.sky,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
        '&[data-placeholder]': {
            '& svg > path': {
                ...theme.feature.iconFillStyles,
            },
            '& span': {
                ...theme.feature.textColorStyles,
            },
        },
    },
    splitter: {
        flexGrow: 1,
        minWidth: 6,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            minWidth: 12,
        },
    },
}), { name: 'selected-filters' });
