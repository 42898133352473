import { createAsyncThunk, createSelector, createSlice, } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { isIframe } from '@/libs/helpers';
import { BASE_PALETTE } from '@/styles/palette';
export const THEME_MODE_KEY = 'theme_mode';
export const themeModes = ['light', 'dark'];
export const defaultThemeMode = themeModes[0];
const isThemeMode = (theme) => themeModes.includes(theme);
const syncThemeMode = () => {
    try {
        if (isIframe()) {
            throw new Error();
        }
        const cookieThemeMode = Cookies.get(THEME_MODE_KEY);
        if (isThemeMode(cookieThemeMode)) {
            return cookieThemeMode;
        }
        const lsThemeMode = localStorage.getItem(THEME_MODE_KEY);
        if (isThemeMode(lsThemeMode)) {
            return lsThemeMode;
        }
        throw new Error();
    }
    catch (e) {
        return defaultThemeMode;
    }
};
export const themeInitialState = {
    themeMode: syncThemeMode(),
    changingToTheme: null,
};
export const THEME_STATE_NAMESPACE = 'theme';
const themeSlice = createSlice({
    name: THEME_STATE_NAMESPACE,
    initialState: themeInitialState,
    reducers: {
        _setTheme(state, action) {
            state.themeMode = action.payload;
        },
        _setIsChanging(state, action) {
            state.changingToTheme = action.payload;
        },
    },
});
const { _setTheme, _setIsChanging } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
const themeStateSelector = (state) => state[THEME_STATE_NAMESPACE];
export const themeModeSelector = createSelector(themeStateSelector, ({ themeMode }) => {
    if (isIframe() || !themeMode) {
        return defaultThemeMode;
    }
    return themeMode;
});
export const changingToThemeSelector = createSelector(themeStateSelector, ({ changingToTheme }) => changingToTheme);
function updateMetaTagsThemeColor(themeMode) {
    if (!isThemeMode(themeMode)) {
        return;
    }
    const themeColors = {
        light: '#FEFFFF',
        dark: BASE_PALETTE.darkHeader,
    };
    const color = themeColors[themeMode];
    try {
        document.documentElement.style.setProperty('--theme-color', color);
        const metatags = [
            'theme-color',
            'msapplication-navbutton-color',
            'apple-mobile-web-app-status-bar-style',
        ];
        metatags.forEach((tag) => {
            const meta = document.querySelector(`meta[name="${tag}"]`);
            if (meta) {
                meta.setAttribute('content', color);
            }
        });
    }
    catch (e) {
        console.error(e);
    }
}
try {
    const lsThemeMode = localStorage.getItem('theme_mode');
    updateMetaTagsThemeColor(lsThemeMode);
}
catch (e) {
    console.error(e);
}
const setTheme = createAsyncThunk('', async (themeMode, thunkApi) => {
    thunkApi.dispatch(_setTheme(themeMode));
    updateMetaTagsThemeColor(themeMode);
});
export const updateThemeAccordingToLs = createAsyncThunk('', async (themeMode, thunkApi) => {
    if (!isThemeMode(themeMode) || isIframe()) {
        return;
    }
    thunkApi.dispatch(setTheme(themeMode));
});
export const changeTheme = createAsyncThunk('', async (themeMode, thunkApi) => {
    const { theme: { changingToTheme, themeMode: currentThemeMode }, } = thunkApi.getState();
    if (!isThemeMode(themeMode) ||
        changingToTheme ||
        themeMode === currentThemeMode) {
        return;
    }
    thunkApi.dispatch(_setIsChanging(themeMode));
    setTimeout(() => {
        thunkApi.dispatch(setTheme(themeMode));
        try {
            localStorage.setItem(THEME_MODE_KEY, themeMode);
            Cookies.set(THEME_MODE_KEY, themeMode, {
                expires: new Date('9999-12-31'),
                httpOnly: false,
            });
        }
        catch (e) {
            console.error(e);
        }
    }, 50);
    setTimeout(() => {
        thunkApi.dispatch(_setIsChanging(null));
    }, 300);
});
export const toggleTheme = createAsyncThunk('', async (_, thunkApi) => {
    const { theme: { themeMode }, } = thunkApi.getState();
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
    thunkApi.dispatch(changeTheme(newThemeMode));
});
