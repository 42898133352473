import { addDays } from '@che-ins-ui/datepicker';
import { ACCIDENT_CODE, AVIA_CARGO_CODE, CIVIL_LIABILITY_CODE, MEDICINE_CODE, MULTIPOLICY_CODE, MULTIPOLICY_FULL_YEAR_VALUE, OBSERVATION_CODE, TRIP_CANCEL_CODE, URGENCY_STOMATOLOGY_CODE, } from '@/libs/static';
export const REQUEST_STATE_NAMESPACE = 'request';
export const CHEREHAPA_PARTNER_ID = 1;
export const ALL_SERVICE_DEFAULT = {
    [MEDICINE_CODE]: 50000,
    [MULTIPOLICY_CODE]: 30,
    [OBSERVATION_CODE]: 1500,
    [TRIP_CANCEL_CODE]: 500,
    [ACCIDENT_CODE]: 1000,
    [AVIA_CARGO_CODE]: 500,
    [CIVIL_LIABILITY_CODE]: 10000,
};
export const SERVICE_DEFAULT = {
    [MEDICINE_CODE]: ALL_SERVICE_DEFAULT[MEDICINE_CODE],
    [URGENCY_STOMATOLOGY_CODE]: 1,
};
export const DEFAULT_MARKETING_PARAMS = {
    partnerId: null,
    marker: null,
    marker2: null,
    srcsale: null,
};
export const currencies = ['eur', 'usd'];
export const minDateIncluded = new Date();
export const maxDateIncluded = new Date(minDateIncluded.getFullYear() + 1, minDateIncluded.getMonth(), minDateIncluded.getDate());
export const defaultDateStart = addDays(new Date(), 4);
export const getDateEndWithDefaultOffset = (dateStart) => addDays(dateStart, 5);
export const defaultDateEnd = getDateEndWithDefaultOffset(defaultDateStart);
export const EMPTY_MULTIPOLICY_OPTION = {
    value: 0,
    label: 'Не выбрано',
    selectOptionLabel: 'Не выбрано',
};
export const MULTIPOLICY_OPTIONS = [
    {
        value: 15,
        label: 15,
        selectOptionLabel: '15 дней',
    },
    {
        value: 30,
        label: 30,
        selectOptionLabel: '30 дней',
    },
    {
        value: 60,
        label: 60,
        selectOptionLabel: '60 дней',
    },
    {
        value: 90,
        label: 90,
        selectOptionLabel: '90 дней',
    },
    {
        value: 180,
        label: 180,
        selectOptionLabel: '180 дней',
    },
    {
        value: MULTIPOLICY_FULL_YEAR_VALUE,
        label: 365,
        selectOptionLabel: '365 дней',
    },
    {
        value: 365,
        label: 'до 90 дней каждая поездка',
        selectOptionLabel: 'до 90 дней каждая поездка',
        shortLabel: 'до 90 дней',
    },
];
