import { jsx as _jsx } from "react/jsx-runtime";
import { createUseStyles } from 'react-jss';
import { hasCyrillic, hasLatin } from '@/libs/validation/validateName';
export const InputAlphabetPostfix = ({ alphabet, value, }) => {
    const classes = useStyles();
    const isViolatingAlphabet = (alphabet === 'ru' && hasLatin(value)) ||
        (alphabet === 'en' && hasCyrillic(value));
    const shouldShow = !value || isViolatingAlphabet;
    if (!shouldShow) {
        return null;
    }
    return (_jsx("div", { className: classes.root, "data-error": isViolatingAlphabet, children: _jsx("span", { "aria-hidden": false, children: alphabet.toUpperCase() }) }));
};
export const useStyles = createUseStyles((theme) => ({
    root: {
        pointerEvents: 'none',
        padding: '0 3px',
        borderRadius: 4,
        border: `1px solid ${theme.colors.textSecondary}`,
        transition: theme.transition.global,
        '& span': {
            display: 'block',
            fontFamily: theme.font.primary,
            fontSize: 13,
            fontWeight: 500,
            color: theme.colors.textSecondary,
            transition: theme.transition.global,
        },
        '&[data-error="true"]': {
            borderColor: theme.colors.error,
            '& span': {
                color: theme.colors.error,
            },
        },
    },
}), { name: 'input-alphabet-postfix' });
