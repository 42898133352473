import { createAsyncThunk } from '@reduxjs/toolkit';
import { addToArrayIfNotExist, removeFromArray } from '@/libs/helpers/utils';
import { filterDirections } from '@/libs/helpers';
import { requestActions } from './index';
export const toggleDirection = createAsyncThunk('', async ({ code, type }, { dispatch, getState }) => {
    const { api: { countries: apiCountries, countryGroups: apiCountryGroups }, request: { countries: requestCountries, countryGroups: requestCountryGroups, currency, }, } = getState();
    let countries = requestCountries;
    let countryGroups = requestCountryGroups;
    if (type === 'country') {
        const isActived = countries.includes(code);
        if (isActived) {
            countries = removeFromArray(countries, code);
            switch (code) {
                case 'spain':
                    countries = removeFromArray(countries, 'andorra');
                    break;
                case 'denmark':
                    countries = removeFromArray(countries, [
                        'greenland',
                        'faroe_islands',
                    ]);
                    break;
                default:
                    break;
            }
        }
        else {
            countries = addToArrayIfNotExist(countries, code);
            switch (code) {
                case 'andorra':
                    countries = addToArrayIfNotExist(countries, 'spain');
                    break;
                case 'greenland':
                case 'faroe_islands':
                    countries = addToArrayIfNotExist(countries, 'denmark');
                    break;
                default:
                    break;
            }
        }
        dispatch(requestActions._setCountries(countries));
    }
    if (type === 'group') {
        const isActived = countryGroups.includes(code);
        if (isActived) {
            countryGroups = removeFromArray(countryGroups, code);
        }
        else {
            countryGroups = addToArrayIfNotExist(countryGroups, code);
        }
        dispatch(requestActions._setCountryGroups(countryGroups));
    }
    dispatch(requestActions._setCurrency(getZoneCurrency(apiCountries, apiCountryGroups, countries, countryGroups, currency)));
});
const getZoneCurrency = (apiCountries, apiCountryGroups, countries, countryGroups, currency) => {
    const checkMandatoryCurrency = ({ mandatoryCurrency }) => mandatoryCurrency?.toLowerCase() === 'eur';
    const choosenCountries = filterDirections(apiCountries, countries);
    const choosenCountryGroups = filterDirections(apiCountryGroups, countryGroups);
    const areChoosenCountriesLegit = choosenCountries.some(checkMandatoryCurrency);
    const areChoosenCountryGroupsLegit = choosenCountryGroups.some(({ countries }) => countries.some(checkMandatoryCurrency));
    return areChoosenCountriesLegit || areChoosenCountryGroupsLegit
        ? 'eur'
        : currency;
};
