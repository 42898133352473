import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { MONTH_HEADER_HEIGHT, useStyles } from './styles';
export const Weekdays = ({ isSingleMonth, topOffset, }) => {
    const classes = useStyles();
    const weekdays = (_jsx("div", { className: classNames(classes.days, classes.weekdays), children: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((day, index) => (_jsx("div", { className: classes.weekday, children: day }, index))) }));
    return (_jsxs(motion.div, { initial: { opacity: 0 }, animate: { opacity: 1 }, className: classes.weekdaysContainer, style: {
            transform: `translateY(${topOffset + MONTH_HEADER_HEIGHT}px)`,
        }, children: [weekdays, isSingleMonth || weekdays] }));
};
