import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useScreenSizes } from '@che-ins-ui/theme';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { useLocalStorage } from '@/libs/hooks/useLocalStorage';
import { useStyles } from './styles';
export const CalendarFooter = memo(() => {
    const classes = useStyles();
    const [isSwipeHintShown, setIsSwipeHintShown] = useLocalStorage('swipe-hint-shown', true);
    const { width } = useScreenSizes();
    const shouldShowSwipeHint = width < BREAKPOINTS.lg;
    if (!isSwipeHintShown || !shouldShowSwipeHint) {
        return null;
    }
    return (_jsxs("div", { className: classes.swipeHint, children: [_jsx(SwipeIcon, {}), _jsx("span", { children: "\u041B\u0438\u0441\u0442\u0430\u0439\u0442\u0435 \u043C\u0435\u0441\u044F\u0446\u044B \u0441\u0432\u0430\u0439\u043F\u043E\u043C \u0432\u043B\u0435\u0432\u043E \u0438 \u0432\u043F\u0440\u0430\u0432\u043E" }), _jsx("button", { tabIndex: -1, onClick: () => setIsSwipeHintShown(false), children: "\u0421\u043A\u0440\u044B\u0442\u044C" })] }));
});
const SwipeIcon = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", children: _jsx("path", { d: "M473-100q-22.08 0-41.96-8.23-19.89-8.23-35.73-24.08L194.77-333.08l24.62-24.84q12.53-12.16 29.03-16.12 16.5-3.96 32.89.5L370-350.31V-680q0-12.75 8.63-21.37 8.63-8.63 21.38-8.63 12.76 0 21.37 8.63Q430-692.75 430-680v409.54l-121.23-33.16 129.31 128.93q6.53 6.54 15.79 10.61Q463.13-160 473-160h157q37.61 0 63.81-26.19Q720-212.39 720-250v-160q0-12.75 8.63-21.38 8.63-8.62 21.38-8.62 12.76 0 21.37 8.62Q780-422.75 780-410v160q0 62.15-43.92 106.08-43.93 43.92-106 43.92H473Zm13.54-270v-160q0-12.77 8.63-21.38 8.63-8.62 21.38-8.62 12.76 0 21.37 8.62 8.62 8.63 8.62 21.38v160h-60Zm116.92 0v-120q0-12.77 8.63-21.38 8.63-8.62 21.38-8.62 12.76 0 21.38 8.62 8.61 8.63 8.61 21.38v120h-60Zm-33.08 92.69Zm289.62-395H672.31V-720H796q-67.15-56.84-147.58-89.77Q568-842.69 480-842.69q-88 0-168.42 32.92Q231.15-776.84 164-720h123.69v47.69H100V-860h47.69v90.62q71.62-56.69 156.12-88.85 84.5-32.15 176.19-32.15t176.19 32.15q84.5 32.16 156.12 88.85V-860H860v187.69Z" }) }));
};
