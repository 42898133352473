import { useEffect, useState } from 'react';
export const useDelayedActivation = (delay) => {
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsActive(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);
    return isActive;
};
