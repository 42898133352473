import { BUTTON_COMPONENTS_NAMES } from '@che-ins-ui/button';
import { DEFAULT_DROPDOWN_TITLE_BUTTON_APPEARANCE } from '@che-ins-ui/dropdown';
import { darken, withAlpha } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, GLOBAL_LINE_HEIGHT, MOBILE_FONT_SIZES, SEARCH_INPUT_HEIGHT, } from './consts';
export const DISABLED_BUTTONS_TEXT_OPACITY = 0.55;
export const getButtonTheme = (theme) => ({
    base: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            letterSpacing: GLOBAL_LETTER_SPACING,
            lineHeight: GLOBAL_LINE_HEIGHT,
            fontWeight: 400,
            background: theme.colors.brand,
            color: theme.colors.white,
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: '0.2s transform',
            '-webkit-tap-highlight-color': 'transparent',
            '&[data-disabled="true"]': {
                opacity: 1,
                color: withAlpha(theme.colors.white, DISABLED_BUTTONS_TEXT_OPACITY),
            },
            '&:hover': {
                background: darken(theme.colors.brand),
            },
            fontSize: MOBILE_FONT_SIZES.button,
        },
    },
    'hint-modal-button': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            minHeight: '42px',
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                minHeight: '38px',
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    filters: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    'toggle-item-forms-page': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            minHeight: '42px',
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                minHeight: '38px',
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    [DEFAULT_DROPDOWN_TITLE_BUTTON_APPEARANCE]: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            color: theme.colors.white,
            background: theme.colors.brand,
            padding: '10px 16px 10px 13px',
            '&:hover': {
                background: theme.colors.brand,
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.white, DISABLED_BUTTONS_TEXT_OPACITY),
            },
            minHeight: '42px',
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                minHeight: '38px',
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    outlined: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: 'transparent',
            color: theme.colors.brand,
            borderRadius: 12,
            border: '1px solid',
            borderColor: theme.colors.brand,
            '&:hover': {
                borderColor: darken(theme.colors.brand),
                color: darken(theme.colors.brand, 0.2),
                background: 'transparent',
                '& svg > path': {
                    fill: darken(theme.colors.brand, 0.2),
                },
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.brand, DISABLED_BUTTONS_TEXT_OPACITY),
            },
        },
    },
    success: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: theme.colors.success,
            '&:hover': {
                background: darken(theme.colors.success),
            },
        },
    },
    secondary: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: theme.colors.brandTertiary,
            color: theme.colors.brand,
            '&:hover': {
                background: darken(theme.colors.brandTertiary),
                color: darken(theme.colors.brand),
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.brand, DISABLED_BUTTONS_TEXT_OPACITY),
            },
        },
    },
    tertiary: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: 'rgba(113, 130, 153, 0.15)',
            color: theme.colors.brand,
            '&:hover': {
                background: theme.colors.brandTertiary,
                color: darken(theme.colors.brand),
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.brand, DISABLED_BUTTONS_TEXT_OPACITY),
            },
        },
    },
    warn: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: theme.colors.deletion,
            color: theme.colors.error,
            '&:hover': {
                background: darken(theme.colors.deletion),
                color: darken(theme.colors.error),
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.error, DISABLED_BUTTONS_TEXT_OPACITY),
            },
        },
    },
    tourist: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 0px 0px 13px',
            alignItems: 'center',
            minHeight: '42px',
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                minHeight: '38px',
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    share: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: theme.colors.brandTertiary,
            color: theme.colors.brand,
            '&:hover': {
                background: darken(theme.colors.brandTertiary),
                color: darken(theme.colors.brand),
            },
            '&[data-selected="true"]': {
                background: withAlpha(theme.colors.success, 0.12),
                color: theme.colors.success,
            },
        },
    },
    age: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            padding: '8px 8px 8px 8px',
            background: theme.colors.cloud,
            color: theme.colors.sky,
            '&:hover': {
                color: theme.colors.brand,
                background: theme.colors.brandTertiary,
            },
            '&[data-selected="true"]': {
                color: theme.colors.brand,
                background: theme.colors.brandTertiary,
            },
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    text: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: theme.colors.cloud,
            color: theme.colors.brand,
            padding: '10px 15px',
            '&:hover': {
                background: theme.colors.cloud,
                color: theme.colors.brand,
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.brand, DISABLED_BUTTONS_TEXT_OPACITY),
            },
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    search: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            color: theme.colors.white,
            background: theme.colors.accent,
            transition: '.15s',
            '&:hover': {
                background: darken(theme.colors.accent, 0.1),
            },
            fontWeight: 700,
            letterSpacing: '-0.2px',
            borderRadius: '12px',
            height: '48px',
            fontSize: MOBILE_FONT_SIZES.searchButton,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.searchButton,
            },
            [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
    },
    'card-pay': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'column',
            },
        },
    },
    'modal-pay': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            position: 'fixed',
            padding: '10.5px',
            left: '12px',
            right: '12px',
            bottom: '12px',
            [theme.breakpoints.up('md')]: {
                padding: '12px',
            },
        },
    },
    'mobile-filters-show': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            position: 'sticky',
            bottom: '15px',
            margin: 'auto',
            zIndex: 1,
            padding: '8.5px 15px',
            height: '35px',
            width: '175px',
            '& svg > path': {
                stroke: theme.colors.brand,
            },
            [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
                display: 'none',
            },
        },
    },
    'filters-reset': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            backgroundColor: theme.colors.cloud,
            color: theme.colors.error,
            '&:hover': {
                background: withAlpha(theme.colors.cloud, 0.2),
                color: darken(theme.colors.error),
            },
            [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
                boxShadow: theme.elevations.cloud,
                width: '100%',
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.error, DISABLED_BUTTONS_TEXT_OPACITY),
            },
        },
    },
    'mobile-filters-show-result': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            position: 'fixed',
            bottom: '15px',
            left: '12px',
            right: '12px',
            padding: '12px 25px',
            [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
                display: 'none',
            },
        },
    },
    'filters-accordion-title-button': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            transformOrigin: '20%',
            color: theme.colors.textPrimary,
            background: 'transparent',
            paddingLeft: '20px',
            textAlign: 'start',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '7px 25px',
            '&:hover': {
                background: 'transparent',
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.textPrimary, DISABLED_BUTTONS_TEXT_OPACITY),
            },
            '&:active:enabled': {
                transform: 'scale(1)',
            },
            height: '34px',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: '30px',
            },
            '&[data-medicine="true"]': {
                padding: '0 8px',
            },
        },
    },
    'toggle-show-services': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            paddingTop: '3px',
            paddingLeft: '24px',
            paddingBottom: 0,
        },
    },
    selectable: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            marginLeft: '12px',
            marginRight: '8px',
            marginBottom: '8px',
            padding: '5px 8px',
            borderRadius: '8px',
            border: `1px solid ${theme.colors.brand}`,
            backgroundColor: 'transparent',
            textDecoration: 'none',
            cursor: 'pointer',
            transition: '0.2s transform',
            color: theme.colors.brand,
            '&:hover': {
                background: theme.colors.brandTertiary,
            },
            '&[data-disabled="true"]': {
                color: theme.colors.brand,
                opacity: 0.7,
            },
            '&[data-selected="true"]': {
                color: theme.colors.white,
                background: theme.colors.brand,
            },
        },
    },
    'success-page': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            fontSize: theme.rem(15),
            borderRadius: '12px',
            height: '42px',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: '45px',
            },
        },
    },
    'download-policy': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: theme.colors.white,
            color: theme.colors.brand,
            '&:hover': {
                background: theme.colors.white,
                color: darken(theme.colors.brand, 0.2),
            },
            '&[data-disabled="true"]': {
                color: withAlpha(theme.colors.brand, DISABLED_BUTTONS_TEXT_OPACITY),
            },
        },
    },
    'download-rules': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: 'rgba(255, 255, 255, 0.2)',
            color: theme.colors.white,
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.1)',
            },
        },
    },
    'offer-modal-back': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            padding: '2px 15px',
            '& svg': {
                '& path': {
                    stroke: theme.colors.brand,
                },
            },
        },
    },
    continue: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            height: '42px',
            userSelect: 'none',
            boxShadow: theme.elevations.cloud,
            '& span > svg': {
                transform: 'translateY(3px) scale(1.07)',
                marginRight: 8,
                '& > path': {
                    fill: theme.colors.white,
                },
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: '45px',
                '& span > svg': {
                    transform: 'translateY(3px) scale(1.04)',
                },
            },
            '&[data-disabled="true"]': {
                '& span > svg > path': {
                    fill: withAlpha(theme.colors.white, DISABLED_BUTTONS_TEXT_OPACITY),
                },
            },
        },
    },
    gender: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            width: '100%',
            height: 56,
            backgroundColor: theme.colors.field,
            borderRadius: 0,
            display: 'flex',
            gap: 8,
            color: theme.colors.textSecondary,
            border: `1px solid ${theme.colors.textTertiary}`,
            transition: theme.transition.global,
            '-webkit-touch-callout': 'none',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            [theme.breakpoints.up('sm')]: {
                height: 48,
            },
            '&[data-invalid="true"]': {
                borderColor: theme.colors.error,
            },
            '& path': {
                fill: theme.colors.textSecondary,
                transition: theme.transition.global,
                opacity: 1,
            },
            '&:active:enabled': {
                transform: 'scale(1)',
            },
            '&:hover': {
                background: theme.colors.cloud,
                color: theme.colors.brand,
                '& path': {
                    fill: theme.colors.brand,
                    opacity: 1,
                },
            },
            '&[data-selected="true"]': {
                color: theme.colors.white,
                background: theme.colors.brand,
                borderColor: theme.colors.brand,
                zIndex: 2,
                '& path': {
                    fill: theme.colors.white,
                    opacity: 1,
                },
                '&[data-disabled="true"]': {
                    opacity: 1,
                    color: theme.colors.textPrimary,
                    background: theme.colors.textTertiary,
                    borderColor: theme.colors.textTertiary,
                    '& path': {
                        fill: theme.colors.textPrimary,
                        opacity: 1,
                    },
                },
                '&:hover': {
                    '& path': {
                        fill: theme.colors.white,
                        opacity: 1,
                    },
                },
            },
            '&[data-disabled="true"]': {
                opacity: '.55',
                border: '1px solid rgba(113, 130, 153, 0.5)',
                color: theme.colors.textSecondary,
                '&:hover': {
                    color: theme.colors.textSecondary,
                    '& path': {
                        fill: theme.colors.textSecondary,
                    },
                },
            },
            '&[data-error="true"]': {
                border: `1px solid ${theme.colors.error}`,
            },
        },
    },
    male: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            borderRadius: '12px 0 0 12px',
            '&[data-disabled="true"]': {
                borderRight: 'none',
            },
        },
    },
    female: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            marginLeft: -1,
            borderRadius: '0 12px 12px 0',
        },
    },
    'comparing-sorting': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            userSelect: 'none',
            fontSize: DEKSTOP_FONT_SIZES.main,
            background: 'transparent',
            color: theme.colors.brand,
            maxHeight: 24,
            display: 'flex',
            gap: 6,
            padding: 0,
            whiteSpace: 'nowrap',
            '&:hover': {
                background: 'transparent',
                color: darken(theme.colors.brand),
            },
            '&:active': {
                transform: 'scale(1) !important',
            },
        },
    },
    'edit-person-form-submit': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            transition: theme.transition.global,
            height: 48,
            position: 'relative',
            background: theme.colors.brand,
            boxShadow: theme.elevations.cloud,
            color: theme.colors.white,
            overflow: 'hidden',
            gap: 3,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: 45,
            },
            '&[data-selected="true"]': {
                background: theme.colors.success,
                color: theme.colors.white,
            },
            '&[data-disabled="true"]': {
                cursor: 'default',
                opacity: 1,
                color: theme.colors.white,
            },
            '&:active': {
                transform: 'scale(1) !important',
            },
        },
    },
    'edit-person-form-submit-deletion': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            background: theme.colors.deletion,
            color: theme.colors.error,
            '&:hover': {
                background: darken(theme.colors.deletion),
                color: darken(theme.colors.error),
            },
            '&[data-selected="true"]': {
                background: theme.colors.deletion,
                color: theme.colors.error,
            },
            '&[data-disabled="true"]': {
                color: theme.colors.error,
            },
        },
    },
    'edit-person-form-delete': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            fontSize: MOBILE_FONT_SIZES.main,
            background: 'transparent',
            color: theme.colors.error,
            maxHeight: 24,
            display: 'flex',
            gap: 6,
            padding: '4px 2px',
            whiteSpace: 'nowrap',
            maxWidth: 'fit-content',
            '-webkit-tap-highlight-color': 'transparent',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            '&:hover': {
                background: 'transparent',
                color: darken(theme.colors.error),
            },
            '&:active': {
                transform: 'scale(1) !important',
            },
        },
    },
    commission: {
        [BUTTON_COMPONENTS_NAMES.button]: {
            fontSize: MOBILE_FONT_SIZES.main,
            background: theme.colors.cloud,
            color: theme.colors.brand,
            display: 'flex',
            gap: 6,
            '-webkit-tap-highlight-color': 'transparent',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            '&:hover': {
                color: theme.colors.brand,
                background: theme.colors.cloud,
            },
            '&:active': {
                transform: 'scale(1) !important',
            },
            [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    'comparing-clear': {
        [BUTTON_COMPONENTS_NAMES.button]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
            background: 'transparent',
            color: theme.colors.error,
            maxHeight: 24 + 8,
            display: 'flex',
            gap: 6,
            margin: '-4px 0',
            padding: '8px 8px',
            whiteSpace: 'nowrap',
            maxWidth: 'fit-content',
            '-webkit-tap-highlight-color': 'transparent',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            '&:hover': {
                background: 'transparent',
                color: darken(theme.colors.error),
            },
            '&:active': {
                transform: 'scale(1) !important',
            },
        },
    },
});
