import { createUseStyles } from 'react-jss';
import { DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SEARCH_FORM_BREAKPOINT, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    wrapper: {
        width: '100%',
        zIndex: 3,
        position: 'relative',
        background: theme.colors.header,
        padding: '8px 0px 12px',
        WebkitOverflowScrolling: 'touch',
        willChange: 'transform',
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            padding: '10px 0px',
        },
    },
    fields: {
        position: 'relative',
        zIndex: 1,
        filter: 'drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.1))',
        display: 'grid',
        gridRowGap: '2px',
        gridColumnGap: '2px',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(3, auto) 36px auto',
        paddingTop: '2px',
        gridTemplateAreas: `
                "countries     countries"
                "dates         dates"
                "tourists      tourists"
                "abroad        foreign"
                "search-button search-button"
            `,
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'repeat(3, auto) 36px auto',
            gridTemplateAreas: `
                    "countries     countries"
                    "dates         dates"
                    "tourists      tourists"
                    "abroad        foreign"
                    "search-button search-button"
                `,
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'repeat(2, auto) 36px auto',
            gridTemplateAreas: `
                    "countries     countries     countries"
                    "dates         dates         tourists"
                    "abroad        foreign       foreign"
                    "search-button search-button search-button"
                `,
        },
        [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
            paddingTop: '0px',
            gridTemplateColumns: '1.85fr repeat(3, minmax(200px, 1fr)) 130px',
            gridTemplateRows: 'auto',
            gridTemplateAreas: `
                    "countries dates  dates   tourists search-button"
                    ".         abroad foreign foreign  ."
                `,
        },
    },
    searchButton: {
        gridArea: 'search-button',
        marginTop: '10px',
        [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
            marginTop: '0px',
            marginLeft: '10px',
        },
        '&:focus': {
            outline: `1px solid ${theme.colors.white}`,
            ountlineOffset: 0,
        },
    },
}), { name: 'travel-form' });
