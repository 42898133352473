import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useMemo } from 'react';
import { formatDate } from '@che-ins-ui/datepicker';
import { getCapatilizedMonthName } from './helpers';
export const useCalendarMonths = ({ currentMonth, currentYear, totalMonths, selectedStartDate, }) => {
    const initialMonthIndex = useMemo(() => {
        if (!selectedStartDate) {
            return 0;
        }
        const startDate = new Date(formatDate(selectedStartDate, true));
        return ((startDate.getFullYear() - currentYear) * 12 +
            (startDate.getMonth() - currentMonth));
    }, [selectedStartDate, currentMonth, currentYear]);
    const months = useMemo(() => {
        return Array.from({ length: totalMonths }).map((_, index) => {
            const month = (currentMonth + index) % 12;
            const year = currentYear + Math.floor((currentMonth + index) / 12);
            return { month, year, index };
        });
    }, [currentMonth, currentYear, totalMonths]);
    const monthOptions = useMemo(() => {
        const groupedMonths = months.reduce((acc, { month, year, index }) => {
            if (!acc[year])
                acc[year] = [];
            acc[year].push({ month, index });
            return acc;
        }, {});
        const elements = Object.entries(groupedMonths).map(([year, months]) => (_jsx(Fragment, { children: _jsx("optgroup", { label: year, children: months.map(({ month, index }) => (_jsx("option", { value: index, children: getCapatilizedMonthName(month) }, index))) }) }, year)));
        return _jsx(_Fragment, { children: elements });
    }, [months]);
    return {
        initialMonthIndex,
        months,
        monthOptions,
    };
};
