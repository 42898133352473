import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLockBodyScroll } from 'react-use';
import { Notification } from '@che-ins-ui/notification';
import { useScreenSizes } from '@che-ins-ui/theme';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import { isIframe } from '@/libs/helpers';
import { isFrameSelector, isIgnoreFormSelector, requestSelector, } from './models/request/selectors';
import AppLoader from './components/common/AppLoader';
import { SearchLayout, SuccessLayout } from './components/layouts';
import { fetchApiData, fetchApiServiceStatus } from './models/api';
import { CHEREHAPA_PARTNER_ID } from './models/request';
import Calculation from './pages/calculation';
import { DEV_WL_PATH, ROUTES } from './components/routes';
import { OverlayLoaderModal } from './components/common/OverlayLoaderModal';
import { useApiServiceStatus } from './libs/hooks/useApiServiceStatus';
import { iframeResizerScript } from './libs/constants';
import Insurance from './components/insurance';
import { getUserInfo } from './models/user/getUserInfo';
import { useInitialUrlCookie } from './libs/hooks/useInitialUrlCookie';
import { PageNotFound } from './components/TechnicalPages';
import { ThemeBackground } from './components/ThemeBackground/ThemeBackground';
import { useMindboxFormsPrice } from './libs/hooks/useMindboxFormsPrice';
const Index = lazy(() => import('./pages/index'));
const WidgetPage = lazy(() => import('./pages/widgetPage'));
const Success = lazy(() => import('./pages/success'));
const App = () => {
    const navigate = useNavigate();
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const dispatch = useDispatch();
    const isFrame = useSelector(isFrameSelector);
    const isIgnoreFirstFormInWidget = useSelector(isIgnoreFormSelector);
    const { isMobile } = useScreenSizes();
    useInitialUrlCookie();
    const IndexComponent = isFrame ? WidgetPage : Index;
    const request = useSelector(requestSelector);
    useEffect(() => {
        dispatch(fetchApiData(request.partnerId || CHEREHAPA_PARTNER_ID));
        dispatch(fetchApiServiceStatus());
        dispatch(getUserInfo());
        const isIframeWidget = isIframe();
        if (window.location.pathname === ROUTES.calculation &&
            isIframeWidget &&
            !isIgnoreFirstFormInWidget) {
            navigate('/');
        }
        if (window.location.pathname === '/' &&
            (isFrame || isIframeWidget) &&
            isIgnoreFirstFormInWidget) {
            navigate(ROUTES.calculation);
        }
        if (isIframeWidget) {
            const scriptTag = document.createElement('script');
            scriptTag.src = iframeResizerScript;
            document.body.appendChild(scriptTag);
        }
    }, []);
    useApiServiceStatus();
    useMindboxFormsPrice();
    useLockBodyScroll(!isMobile && isSomeModalOpen);
    return (_jsxs("div", { children: [_jsx(ThemeBackground, {}), _jsx(Suspense, { fallback: _jsx(AppLoader, {}), children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(IndexComponent, {}) }), _jsx(Route, { path: DEV_WL_PATH, element: _jsx(WidgetPage, {}) }), _jsxs(Route, { element: _jsx(SearchLayout, {}), children: [_jsx(Route, { path: ROUTES.calculation, element: _jsx(Calculation, {}) }), _jsx(Route, { path: ROUTES.insurance, element: _jsx(Insurance, {}) })] }), _jsx(Route, { element: _jsx(SuccessLayout, {}), children: _jsx(Route, { path: `${ROUTES.success}/`, element: _jsx(Success, {}) }) }), _jsx(Route, { path: "*", element: _jsx(PageNotFound, {}) })] }) }), _jsx(OverlayLoaderModal, {}), _jsx(Notification, {})] }));
};
export default App;
