import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useRef } from 'react';
import { useIntersection } from 'react-use';
import { useStyles } from './styles';
import { Day } from './Day';
import { MonthHeader } from './MonthHeader';
export const monthButtonsDirections = ['left', 'right'];
export const Month = memo(({ year, month, onSelectDate, dateStartTimestamp, dateEndTimestamp, scroll, monthIndex, monthOptions, isMostLeft, isMostRight, width, isSingleMonthCalendar, minDateIncluded, maxDateIncluded, setHoveredDate, areDatesUnselected, }) => {
    const classes = useStyles();
    const getDaysInMonth = (year, month) => {
        const date = new Date(year, month, 1);
        const days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    };
    const firstDayOfMonth = (new Date(year, month, 1).getDay() + 6) % 7;
    const daysInMonth = getDaysInMonth(year, month);
    const handleDateClick = (day) => {
        onSelectDate(day);
    };
    const leftObservedRef = useRef(null);
    const rightObservedRef = useRef(null);
    const isLeftObservedSeen = Boolean(useIntersection(leftObservedRef, {})?.isIntersecting);
    const isRightObservedSeen = Boolean(useIntersection(rightObservedRef, {})?.isIntersecting);
    return (_jsxs("div", { className: classes.month, style: { minWidth: width, maxWidth: width }, children: [_jsx(MonthHeader, { ...{
                    month,
                    year,
                    monthIndex,
                    monthOptions,
                    isMostLeft,
                    isMostRight,
                    isSingleMonthCalendar,
                    scroll,
                    isLeftObservedSeen,
                    isRightObservedSeen,
                } }), _jsx("div", { className: classes.weekdaysPlaceholder }), _jsxs("div", { className: classes.days, children: [Array.from({ length: firstDayOfMonth }).map((_, index) => (_jsx("div", { className: `${classes.day} ${classes.empty}` }, `empty-${index}`))), daysInMonth.map((day, index) => (_jsx(Day, { index: index, day: day, handleDateClick: handleDateClick, dateStartTimestamp: dateStartTimestamp, dateEndTimestamp: dateEndTimestamp, year: year, month: month, minDateIncluded: minDateIncluded, maxDateIncluded: maxDateIncluded, setHoveredDate: setHoveredDate, areDatesUnselected: areDatesUnselected }, index)))] }), [
                { ref: leftObservedRef, direction: 'left' },
                { ref: rightObservedRef, direction: 'right' },
            ].map(({ ref, direction }) => (_jsx("div", { className: classes.monthObservedElement, style: { [direction]: -60 }, ref: ref }, direction)))] }));
});
