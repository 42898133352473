import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useDeferredValue, useEffect, useMemo, useRef, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusOutside } from '@/libs/hooks';
import { requestSelector } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import { SearchTrie } from '@/models/search/search';
import { toggleDirection } from '@/models/request/toggleDirection';
import { hasCyrillic } from '@/libs/validation/validateName';
import { translateLatinToCyrillicKeyboardKeys } from '@/libs/helpers';
import { TravelFormInput } from '../TravelFormInput/TravelFormInput';
import { TravelFormDropdown } from '../TravelFormDropdown/TravelFormDropdown';
import { displayDirections } from './helper';
import { useStyles } from './styles';
import { CountriesDropdown } from './CountriesDropdown';
export const directionsSearchTrie = new SearchTrie();
export const COUNTRIES_INPUT_ID = 'countries-input';
export const Countries = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { countries, countryGroups } = useSelector(requestSelector);
    const { directionsDictionary } = useSelector(apiSelector);
    const [fallbackDirections, setFallbackDirections] = useState({
        countries,
        countryGroups,
    });
    const [isFocused, setIsFocused] = useState(false);
    const containerRef = useRef(null);
    const closeDropdown = () => {
        setIsFocused(false);
    };
    useFocusOutside(containerRef, () => {
        closeDropdown();
    });
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const onChange = (value) => {
        setSearchValue(value);
        if (value.trim() === '') {
            setSearchResults(null);
        }
        else {
            const results = Array.from(directionsSearchTrie.search(value));
            if (!hasCyrillic(value)) {
                results.push(...Array.from(directionsSearchTrie.search(translateLatinToCyrillicKeyboardKeys(value))));
            }
            setSearchResults(results);
        }
    };
    const selectedDirections = useMemo(() => displayDirections(countries, countryGroups, directionsDictionary), [countries, countryGroups, directionsDictionary]);
    const directionsString = Object.keys(directionsDictionary).length === 0
        ? ''
        : selectedDirections.map((direction) => direction.name).join(', ');
    useEffect(() => {
        if (!isFocused) {
            onChange('');
        }
    }, [isFocused]);
    const prevIsFocused = useRef(isFocused);
    useEffect(() => {
        if (isFocused) {
            if (!prevIsFocused.current) {
                setFallbackDirections({ countries, countryGroups });
            }
        }
        else {
            if (!countries.length && !countryGroups.length) {
                fallbackDirections.countries.forEach((country) => dispatch(toggleDirection({ code: country, type: 'country' })));
                fallbackDirections.countryGroups.forEach((group) => dispatch(toggleDirection({ code: group, type: 'group' })));
            }
            setFallbackDirections({ countries: [], countryGroups: [] });
        }
        prevIsFocused.current = isFocused;
    }, [isFocused, countries, countryGroups]);
    const searchCodes = useDeferredValue(searchResults);
    const hasSelected = Boolean(selectedDirections.length);
    return (_jsx("div", { className: classes.root, children: _jsxs("div", { className: classes.relative, ref: containerRef, children: [_jsx(TravelFormInput, { value: isFocused ? searchValue : directionsString, onChange: onChange, label: "\u041A\u0443\u0434\u0430", placeholder: hasSelected
                        ? directionsString
                        : 'Введите и выберите страну', isFocused: isFocused, onFocus: () => setIsFocused(true), onClose: closeDropdown, type: "countries", maxLength: isFocused ? 40 : 70, id: COUNTRIES_INPUT_ID }), _jsx(TravelFormDropdown, { isOpenend: isFocused, shouldStretch: true, children: _jsx(CountriesDropdown, { selectedDirections: selectedDirections, searchCodes: searchCodes, searchValue: searchValue, resetSearch: () => onChange(''), onSelect: () => setIsFocused(false) }) })] }) }));
});
