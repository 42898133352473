import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ROUTES } from '@/components/routes';
import { isMultipolicySelectedSelector, requestSelector, } from '@/models/request/selectors';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { getTypes } from '@/libs/static';
import { declOfNum, displayDate, isIframe, scrollToTop } from '@/libs/helpers';
import { apiSelector } from '@/models/api/selectors';
import { displayDirections } from '../Countries/helper';
import { useStyles } from './styles';
const SHOWCASE_MOBILE_ID = 'showcase-mobile';
export const scrollToShowcase = () => {
    const element = document.getElementById(SHOWCASE_MOBILE_ID);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};
const DATE_FORMAT = 'dd mm (ww)';
export const ShowcaseMobileInfo = memo(({ isFormSeen }) => {
    if (isIframe() || location.pathname !== ROUTES.calculation) {
        return null;
    }
    return _jsx(Component, { isFormSeen: isFormSeen });
});
const Component = ({ isFormSeen }) => {
    const classes = useStyles();
    const { directionsDictionary } = useSelector(apiSelector);
    const { dateStart, dateEnd, countries, countryGroups } = useSelector(requestSelector);
    const isMultipolicySelected = useSelector(isMultipolicySelectedSelector);
    const tourists = useSelector(touristsFormsSelector);
    const touristsCount = tourists.length;
    const touristsLabel = `${touristsCount} ${declOfNum(touristsCount, getTypes('TOURIST'))}`;
    const getDatesLabel = () => {
        if (!dateStart || !dateEnd) {
            return 'Даты не выбраны';
        }
        const start = displayDate(dateStart, DATE_FORMAT);
        if (isMultipolicySelected) {
            return start + ', Годовой полис';
        }
        const end = displayDate(dateEnd, DATE_FORMAT);
        return `${start} - ${end}`;
    };
    const selectedDirections = useMemo(() => displayDirections(countries, countryGroups, directionsDictionary), [countries, countryGroups, directionsDictionary]);
    const getCountriesLabel = () => {
        if (selectedDirections.length === 0) {
            return 'Страны не выбраны';
        }
        return Object.keys(directionsDictionary).length === 0
            ? '\u00A0'
            : selectedDirections.map((direction) => direction.name).join(', ');
    };
    return (_jsx("div", { className: classes.container, id: SHOWCASE_MOBILE_ID, "data-elevation": isFormSeen, children: _jsxs("button", { className: classes.content, onClick: () => {
                scrollToTop();
            }, tabIndex: -1, children: [_jsx("span", { className: classes.title, children: getCountriesLabel() }), _jsxs("span", { className: classes.subtitle, children: [getDatesLabel(), ", ", touristsLabel] }), _jsx("div", { className: classes.buttonContainer, children: _jsx("div", { className: classes.button, children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", height: 20, width: 20, children: _jsx("path", { d: "M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z" }) }) }) })] }) }));
};
