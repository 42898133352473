import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
const PADDING = 12;
const EMPTY_PLACEHOLDER_PADDING = 4;
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gridArea: 'countries',
    },
    relative: {
        width: '100%',
        position: 'relative',
    },
    contentContainer: {
        width: '100%',
        minWidth: 320,
        maxHeight: 324,
        maxWidth: 1130,
        overflowY: 'auto',
        overflowX: 'hidden',
        overscrollBehaviorBlock: 'contain',
        '&::-webkit-scrollbar': {
            width: 5,
            margin: '10px 0',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#485B75',
            borderRadius: 10,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '6px 0',
    },
    grid: {
        display: 'grid',
    },
    checkbox: {
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        gap: 8,
        padding: '4px 12px',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
            backgroundColor: theme.colors.brandTertiary,
        },
        [theme.breakpoints.up('md')]: {
            padding: '3px 12px',
        },
    },
    checkboxIcon: {
        height: 22,
        width: 22,
        border: '1px solid rgba(113, 130, 153, 0.45)',
        borderRadius: 6,
        flexShrink: 0,
        margin: '2px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&[data-active="true"]': {
            backgroundColor: theme.colors.brand,
            borderColor: theme.colors.brand,
        },
        '& svg': {
            width: 11,
            height: 11,
            '& path': {
                fill: theme.colors.white,
            },
        },
        [theme.breakpoints.up('md')]: {
            height: 20,
            width: 20,
            '& svg': {
                width: 10,
                height: 10,
            },
        },
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            color: theme.colors.sky,
            fontSize: 14,
            letterSpacing: GLOBAL_LETTER_SPACING,
            lineHeight: '16px',
            [theme.breakpoints.up('md')]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    checkboxSuffix: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        top: 0,
        width: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notFound: {
        width: '100%',
        padding: '8px 8px 6px',
        display: 'flex',
        justifyContent: 'center',
        '& span': {
            fontSize: 13,
            color: theme.colors.sky,
            textAlign: 'center',
            letterSpacing: GLOBAL_LETTER_SPACING,
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            '& b': {
                fontWeight: 500,
            },
        },
    },
}), { name: 'countries-picker' });
