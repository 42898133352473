import { createUseStyles } from 'react-jss';
import { DISABLED_BUTTONS_TEXT_OPACITY } from '@/styles/themes/components/buttonTheme';
import { GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
import { withAlpha } from '@/styles/utils';
import { getGradientEdgeBackground } from '@/components/SelectedFilters/styles';
export const MONTH_BASE_WIDTH = 290;
const CALENDAR_PADDING = 12;
const MONTH_PADDING = `0 ${CALENDAR_PADDING}px`;
const WEEKDAYS_ROW_HEIGHT = 36;
export const MONTH_HEADER_HEIGHT = 38;
const HIGHLIGHT_DAY_SIZE = 38;
export const DAY_INNER_CLASSNAME = 'dayInner';
export const HOVERED_DAY_HIGHLIGHT_CLASSNAME = 'hoveredDayHighlight';
export const SELECTED_DAY_HIGHLIGHT_CLASSNAME = 'selectedDayHighlight';
export const useStyles = createUseStyles((theme) => ({
    datePicker: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gridArea: 'dates',
    },
    relative: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        '&[data-type="start"]': {
            justifyContent: 'flex-start',
        },
        '&[data-type="end"]': {
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start !important',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center !important',
        },
    },
    inputs: {
        gap: 2,
        display: 'flex',
        width: '100%',
    },
    calendar: {
        padding: `${CALENDAR_PADDING}px 0`,
        width: '100%',
        userSelect: 'none',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonsRow: {
        margin: '0 -6px',
        width: 'calc(100% + 12px)',
        paddingBottom: 8,
        display: 'flex',
        flexFlow: 'row wrap',
        padding: `0 ${CALENDAR_PADDING}px`,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            margin: 0,
            width: '100%',
        },
        '& span': {
            fontSize: 13.5,
            [theme.breakpoints.up(366)]: {
                fontSize: 14,
            },
            letterSpacing: GLOBAL_LETTER_SPACING,
            color: theme.colors.sky,
        },
    },
    resetButton: {
        marginRight: -2,
        transition: theme.transition.global,
        borderRadius: 999,
        padding: '6px 10px',
        height: 36,
        width: 'fit-content',
        '& span': {
            color: theme.colors.error,
        },
        '&:hover': {
            backgroundColor: theme.colors.deletion,
        },
        '&:disabled': {
            '& span': {
                color: withAlpha(theme.colors.error, DISABLED_BUTTONS_TEXT_OPACITY),
            },
            cursor: 'not-allowed',
            backgroundColor: 'transparent',
        },
    },
    multipolicyContainer: {
        position: 'relative',
    },
    multipolicyLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 8,
        gap: 4,
        '& span': {
            fontSize: 13.5,
            [theme.breakpoints.up(366)]: {
                fontSize: 14,
            },
            letterSpacing: GLOBAL_LETTER_SPACING,
            color: theme.colors.sky,
            '&[data-selected="true"]': {
                color: theme.colors.brand,
                borderRadius: 999,
                marginTop: 1,
                height: 34,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 500,
                '& svg': {
                    fill: 'currentColor',
                    marginTop: 1,
                },
            },
        },
    },
    multipolicySelect: {
        inset: 0,
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
    },
    monthesRow: {
        overflowX: 'auto',
        width: '100%',
        scrollbarWidth: 'none',
        scrollSnapType: 'x mandatory',
        scrollSnapStop: 'always',
        scrollBehavior: 'smooth',
        overscrollBehaviorBlock: 'contain',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    monthes: {
        width: 'max-content',
        display: 'flex',
    },
    month: {
        position: 'relative',
        scrollSnapAlign: 'start',
        width: '100%',
        padding: MONTH_PADDING,
    },
    section: {
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        minWidth: 0,
    },
    swipeHint: {
        marginTop: 8,
        width: '100%',
        padding: `4px ${CALENDAR_PADDING}px`,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        ...theme.feature.backgroundColorStyles,
        '& svg': {
            width: 32,
            height: 32,
            flexShrink: 0,
            '& path': {
                ...theme.feature.iconFillStyles,
            },
        },
        '& span': {
            ...theme.feature.textColorStyles,
            textWrap: 'balance',
            fontSize: 13,
            letterSpacing: GLOBAL_LETTER_SPACING,
            flexGrow: 1,
            marginRight: 'auto',
        },
        '& button': {
            fontSize: 13,
            color: theme.colors.error,
            letterSpacing: GLOBAL_LETTER_SPACING,
            padding: '8px 12px',
            margin: '-8px -8px',
        },
    },
    monthHeaderWrapper: {
        height: MONTH_HEADER_HEIGHT,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 0',
        userSelect: 'none',
        isolation: 'isolate',
    },
    monthHeader: {
        position: 'relative',
        width: 156,
        height: 30,
        '& select': {
            position: 'absolute',
            inset: 0,
            opacity: 0,
            zIndex: 1,
            cursor: 'pointer',
        },
        '&:hover': {
            '& $monthHeaderTitle': {
                '& span': {
                    color: theme.colors.brand,
                },
                '& svg > path': {
                    fill: theme.colors.brand,
                },
            },
        },
    },
    monthObservedElement: {
        position: 'absolute',
        pointerEvents: 'none',
        top: 4,
    },
    monthHeaderTitle: {
        position: 'absolute',
        inset: 0,
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 2px',
        '& span': {
            transition: theme.transition.global,
            width: 'fit-content',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: GLOBAL_LETTER_SPACING,
            color: theme.colors.sky,
            textTransform: 'capitalize',
        },
        '& svg > path': {
            transition: theme.transition.global,
            fill: theme.colors.sky,
        },
    },
    scrollButton: {
        position: 'absolute',
        margin: -6,
        padding: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& div': {
            borderRadius: 8,
            flexShrink: 0,
            width: 32,
            height: 32,
            transition: theme.transition.global,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& svg > path': {
            fill: theme.colors.brand,
        },
        '&[data-direction="left"]': {
            left: 0,
            '& svg': {
                transform: 'rotate(180deg)',
            },
        },
        '&[data-direction="right"]': {
            right: 0,
        },
        '&:hover': {
            '& div': {
                background: theme.colors.brandTertiary,
            },
        },
    },
    weekdaysPlaceholder: {
        height: WEEKDAYS_ROW_HEIGHT,
    },
    weekdaysContainer: {
        width: '100%',
        height: WEEKDAYS_ROW_HEIGHT,
        zIndex: 1,
        position: 'absolute',
        left: 0,
        display: 'flex',
        alignItems: 'center',
    },
    weekdays: {
        padding: MONTH_PADDING,
        width: '100%',
    },
    weekday: {
        width: '100%',
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.textPrimary,
        textTransform: 'uppercase',
    },
    days: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        textAlign: 'center',
    },
    day: {
        width: '100%',
        maxHeight: 40,
        position: 'relative',
        padding: '1px 0',
        height: 38,
        '&[data-disabled="true"]': {
            cursor: 'default',
            opacity: 0.3,
        },
        [theme.breakpoints.up('md')]: {
            '&[data-disabled="false"]': {
                '&:hover': {
                    [`& $${DAY_INNER_CLASSNAME}`]: {
                        [`& $${HOVERED_DAY_HIGHLIGHT_CLASSNAME}`]: {
                            display: 'block',
                        },
                    },
                },
            },
        },
    },
    [DAY_INNER_CLASSNAME]: {
        position: 'relative',
        height: 'calc(100%)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.sky,
        '&[data-in-range="true"]': {
            background: theme.colors.brandTertiary,
            color: theme.colors.brand,
            borderRadius: 0,
        },
        '&[data-selected="true"]': {
            [`& $${SELECTED_DAY_HIGHLIGHT_CLASSNAME}`]: {
                display: 'block',
            },
            color: theme.colors.white,
        },
        '& span': {
            zIndex: 2,
        },
    },
    [HOVERED_DAY_HIGHLIGHT_CLASSNAME]: {
        display: 'none',
        position: 'absolute',
        width: HIGHLIGHT_DAY_SIZE,
        height: HIGHLIGHT_DAY_SIZE,
        borderRadius: HIGHLIGHT_DAY_SIZE,
        border: `2px solid ${theme.colors.brand}`,
    },
    [SELECTED_DAY_HIGHLIGHT_CLASSNAME]: {
        display: 'none',
        position: 'absolute',
        width: HIGHLIGHT_DAY_SIZE,
        height: HIGHLIGHT_DAY_SIZE,
        borderRadius: HIGHLIGHT_DAY_SIZE,
        backgroundColor: theme.colors.brand,
    },
    selectionOverlay: {
        position: 'absolute',
        width: '50%',
        height: '100%',
        backgroundColor: theme.colors.cloud,
        top: 0,
        '&[data-left="true"]': {
            left: 0,
        },
        '&[data-right="true"]': {
            right: 0,
        },
    },
    today: {
        position: 'absolute',
        width: 'min-content',
        whiteSpace: 'nowrap',
        color: 'transparent',
        pointerEvents: 'none',
        height: 2,
        backgroundColor: theme.colors.error,
        bottom: 7,
        left: 0,
        right: 0,
        margin: 'auto',
    },
    dayMonthEndGradient: {
        width: 10,
        position: 'absolute',
        height: '100%',
        background: `linear-gradient(to right, ${withAlpha(theme.colors.brandTertiary, 0)}, ${theme.colors.brandTertiary})`,
        '&[data-month-start="true"]': {
            left: -10,
        },
        '&[data-month-end="true"]': {
            right: -10,
            transform: 'rotateY(180deg)',
        },
    },
    empty: {
        visibility: 'hidden',
    },
    scrollButtonWrapper: {
        position: 'absolute',
        width: 90,
        display: 'flex',
        padding: '0 10px',
        top: 12,
        left: 0,
        background: getGradientEdgeBackground(theme, 'right', theme.colors.cloud),
        '&[data-right="true"]': {
            left: 'auto',
            right: 0,
            justifyContent: 'flex-end',
            background: getGradientEdgeBackground(theme, 'left', theme.colors.cloud),
        },
    },
}), { name: 'date-picker' });
