import { analytics } from '@/libs/analytics';
export const getDayTimestamp = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
export const getMonthName = (month) => new Date(0, month).toLocaleString('ru', { month: 'long' });
export const getCapatilizedMonthName = (month) => {
    const monthName = getMonthName(month);
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
};
export const onDatepickerInteraction = () => {
    analytics.sendEvent({
        action: 'travel_datepicker_interaction',
        label: 'Действие пользователя',
        category: 'Cherehapa',
    });
};
export const onMultipolicySelect = () => {
    analytics.sendEvent({
        action: 'travel_multipolicy_select_from_datepicker',
        label: 'Действие пользователя',
        category: 'Cherehapa',
    });
};
