import { createAsyncThunk } from '@reduxjs/toolkit';
import { notify } from '@che-ins-ui/notification';
import { LSHelper } from '@/libs/localStorage';
import { getErrorMessage, toErrorWithMessage, isIframe } from '@/libs/helpers';
import { captureError } from '@/libs/helpers/utils';
import { analytics } from '@/libs/analytics';
import { isFrameSelector } from '../request/selectors';
import { getUserToken, headersWithToken } from '../user/helpers';
import { isAuthenticatedSelector } from '../user/selectors';
import { prepareStoreModelToServerCreateOrder } from './utils';
import { processActions } from './slice';
const clearDataInLocaleStorage = () => {
    if (!isIframe()) {
        LSHelper.removeField('basket');
        LSHelper.removeField('form');
    }
};
export const createOrder = createAsyncThunk('process/createOrder', async ({ code }, store) => {
    try {
        const state = store.getState();
        const isFrame = isFrameSelector(state);
        store.dispatch(processActions.setIsOrderCreating(true));
        const body = await prepareStoreModelToServerCreateOrder({
            state,
            code,
            isOrderCreation: true,
        });
        if (body?.phone && body?.phone.length <= 6) {
            throw new Error('Некорректный номер телефона');
        }
        const userToken = getUserToken();
        const isAuthenticated = isAuthenticatedSelector(state);
        const response = await fetch(`${__VZR_API_URL__}order`, {
            method: 'POST',
            ...headersWithToken(isAuthenticated ? userToken : undefined, {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(body),
        });
        const result = await response.json();
        const paymentPageSearchString = isFrame ? `&isWhiteLabel=1` : '';
        if (response.status >= 400) {
            throw new Error(JSON.stringify(result));
        }
        else {
            clearDataInLocaleStorage();
            location.replace(result.paymentLink + paymentPageSearchString);
        }
    }
    catch (error) {
        store.dispatch(processActions.setIsOrderCreating(false));
        captureError(toErrorWithMessage(error), 'process/createOrder');
        const errorMessage = getErrorMessage(error);
        const { message: transformedErrorMessage, action: eventAction } = getErrorMessageAndEventAction(errorMessage);
        notify.error({
            title: 'Validation Error',
            message: transformedErrorMessage,
            autoClose: 60 * 1000,
        });
        analytics.sendEvent({
            action: eventAction,
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
        console.error(error);
        throw new Error('process/createOrder');
    }
});
const getErrorMessageAndEventAction = (message) => {
    if (message.includes('AccessDeniedError: You dont have permission to view this resource.')) {
        return {
            message: 'Упс! Что-то пошло не так. Попробуйте перезагрузить страницу и попробовать ещё раз.',
            action: 'vzr_order_creation_h_error',
        };
    }
    if (message.includes('AccessDeniedError: You dont have permission to view this resource')) {
        return {
            message,
            action: 'vzr_order_creation_f_error',
        };
    }
    return {
        message,
        action: 'vzr_order_creation_error',
    };
};
