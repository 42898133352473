import { PALETTE } from '../palette';
import { withAlpha } from '../utils';
import { themeComponents } from './components';
import { AbstractTheme } from './AbstractTheme';
import { ELEVATIONS } from './elevations';
export const LIGHT_COLORS = {
    white: PALETTE.white,
    black: PALETTE.black,
    brand: PALETTE.brand,
    brandTertiary: PALETTE.brandTertiary,
    success: PALETTE.success,
    error: PALETTE.error,
    warning: PALETTE.warning,
    deletion: PALETTE.deletion,
    accent: PALETTE.accent,
    yellow: PALETTE.yellow,
    textPrimary: PALETTE.textPrimary,
    textSecondary: PALETTE.textSecondary,
    textTertiary: PALETTE.textTertiary,
    textBackground: PALETTE.textBackground,
    background: PALETTE.background,
    header: PALETTE.brand,
    field: PALETTE.white,
    sky: PALETTE.black,
    cloud: PALETTE.white,
    tertiary: PALETTE.brandTertiary,
};
export class LightColors extends AbstractTheme {
    constructor() {
        super(...arguments);
        this.colors = LIGHT_COLORS;
        this.mode = 'light';
        this.elevations = {
            1: ELEVATIONS.day[1],
            2: ELEVATIONS.day[2],
            3: ELEVATIONS.day[3],
            4: ELEVATIONS.day[4],
            5: ELEVATIONS.day[5],
            cloud: ELEVATIONS.cloud,
            dropdown: ELEVATIONS.day[2],
            travelForm: ELEVATIONS.day[3],
            modal: ELEVATIONS.day[4],
        };
    }
}
export class LightTheme extends LightColors {
    constructor() {
        super(...arguments);
        this.components = themeComponents.reduce((acc, { name, getter }) => {
            acc[name] = getter(this);
            return acc;
        }, {});
        this.feature = getFeatureStyles(this);
    }
}
export const getFeatureStyles = (theme) => ({
    iconFillStyles: {
        fill: theme.colors.accent,
    },
    backgroundColorStyles: {
        background: `linear-gradient(103deg, ${withAlpha(theme.colors.accent, 0.05)} 17.4%, ${withAlpha(theme.colors.brand, 0.05)} 77.89%)`,
    },
    textColorStyles: {
        color: theme.colors.sky,
        background: `linear-gradient(103deg, ${theme.colors.accent} 17.4%, ${theme.colors.brand} 77.89%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
});
