import { nanoid } from '@reduxjs/toolkit';
import { FRANCHISE_CODE, MEDICINE_CODE } from '../static';
const sortOffersByMatchingRating = (prevCalc, nextCalc) => nextCalc.calculation.matchRating - prevCalc.calculation.matchRating;
const sortOffersByPrice = (prevCalc, nextCalc) => prevCalc.priceRub - nextCalc.priceRub;
export const getCalculationState = (calculations = [], companies, request) => {
    const matchedCalculations = [];
    const partiallyCalculations = [];
    const withoutFranchise = !!request.services[FRANCHISE_CODE];
    const franchiseFilter = (calculation) => {
        if (!withoutFranchise) {
            return true;
        }
        const medicine = calculation?.info?.[MEDICINE_CODE] ?? [];
        const hasFranchiseInCalculation = medicine.find((med) => med.type === FRANCHISE_CODE && med.value);
        return !hasFranchiseInCalculation;
    };
    const matchedCompaniesIds = calculations
        .filter((calculation) => franchiseFilter(calculation))
        .map(({ companyId }) => companyId);
    const missedCalculations = companies
        .filter(({ id }) => !matchedCompaniesIds.includes(id))
        .map((company) => ({ company }));
    calculations.forEach((calculation) => {
        const { companyId, matchDetails: { missingServices, lowerSumms }, } = calculation;
        const company = companies.find(({ id }) => companyId === id);
        if (!company || !franchiseFilter(calculation)) {
            return;
        }
        if (missingServices || lowerSumms) {
            const _missingServices = [];
            const lowerSumServices = [];
            Object.entries(request.services).forEach(([code, requestedValue]) => {
                if (!calculation.serviceProduct[code]) {
                    _missingServices.push({ code });
                    return;
                }
                if (calculation.serviceProduct[code] < requestedValue) {
                    lowerSumServices.push({
                        code,
                        value: calculation.serviceProduct[code],
                    });
                }
            });
            partiallyCalculations.push({
                calculation: calculation,
                missingServices: _missingServices,
                lowerSumServices,
                company,
            });
            return;
        }
        if (matchedCompaniesIds.includes(companyId)) {
            matchedCalculations.push({
                calculation,
                company,
                missingServices: [],
                lowerSumServices: [],
            });
        }
    });
    return {
        matchedOffers: matchedCalculations.sort(sortOffersByMatchingRating),
        partiallyOffers: partiallyCalculations.sort(sortOffersByMatchingRating),
        missingOffers: missedCalculations,
        originalCalculations: calculations.sort(sortOffersByPrice),
        isLoading: false,
        lastRequestId: nanoid(),
    };
};
