import { MAX_NAME_LENGTH } from '../constants';
import { declOfNum } from '../helpers';
import { getTypes } from '../static';
import { CYRILLIC_SPACES_LIMIT, DEFAULT_FIELD_ERROR, LATIN_SPACES_LIMIT, } from './constants';
export const hasLatin = (value) => /[a-zA-Z]/.test(value);
export const hasCyrillic = (value) => /[а-яёА-ЯЁ]/.test(value);
const isNotEmpty = (value) => Boolean(value?.trim());
const isMatchingRegexp = (value, isCyrillic) => (isCyrillic ? /^[а-яёА-ЯЁ\-\s]+$/ : /^[a-zA-Z\-\s]+$/).test(value);
const startsAndEndsNotWithDash = (value) => {
    const trimmedValue = value.trim();
    return !trimmedValue.startsWith('-') && !trimmedValue.endsWith('-');
};
const withinSpacesLimit = (value, limit = 1) => value?.trim().split(' ').filter(Boolean).length <= limit + 1;
const withinHyphensLimit = (value, limit = 1) => value.split('-').length <= limit + 1;
const underMaxLength = (value) => value.length <= MAX_NAME_LENGTH;
export const validateName = (value, { isCyrillic } = { isCyrillic: false }) => {
    const spacesLimit = isCyrillic ? CYRILLIC_SPACES_LIMIT : LATIN_SPACES_LIMIT;
    const validation = [
        {
            validator: isNotEmpty,
            message: DEFAULT_FIELD_ERROR,
        },
        {
            validator: (value) => isMatchingRegexp(value, isCyrillic),
            message: `Используйте ${!isCyrillic ? 'латинницу' : 'кириллицу'}, пробелы или тире`,
        },
        {
            validator: startsAndEndsNotWithDash,
            message: 'Поле не должно начинаться или заканчиваться дефисом',
        },
        {
            validator: (value) => withinSpacesLimit(value, spacesLimit),
            message: `Не более ${spacesLimit} ${declOfNum(spacesLimit, getTypes('BACKSPACE'))}`,
        },
        {
            validator: withinHyphensLimit,
            message: 'Не более одного дефиса',
        },
        {
            validator: underMaxLength,
            message: `Не более ${MAX_NAME_LENGTH} символов`,
        },
    ];
    const error = validation.find(({ validator }) => !validator(value));
    return { error: error ? error.message : null };
};
