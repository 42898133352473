import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useScreenSizes } from '@che-ins-ui/theme';
import { EMPTY_MULTIPOLICY_OPTION, MULTIPOLICY_OPTIONS, } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { MULTIPOLICY_CODE } from '@/libs/static';
import { useStyles } from './styles';
import { UnfoldMoreIcon } from './UnfoldMoreIcon';
export const CalendarHeader = memo(({ onReset, selectedStartDate, selectedEndDate, enableMultipolicy, disableMultipolicy, }) => {
    const classes = useStyles();
    const { isMobile } = useScreenSizes();
    const { services } = useSelector(requestSelector);
    const multipolicyValue = services[MULTIPOLICY_CODE];
    const setMultipolicyValue = (value) => {
        if (!value) {
            disableMultipolicy();
            return;
        }
        enableMultipolicy(value);
    };
    const getLabel = () => {
        const option = MULTIPOLICY_OPTIONS.find(({ value }) => value === multipolicyValue);
        if (!option) {
            return EMPTY_MULTIPOLICY_OPTION.label;
        }
        return 'shortLabel' in option
            ? option.shortLabel
            : option.selectOptionLabel;
    };
    const label = getLabel();
    return (_jsxs("div", { className: classes.buttonsRow, children: [_jsx("button", { tabIndex: -1, className: classes.resetButton, onClick: onReset, disabled: !selectedStartDate &&
                    !selectedEndDate &&
                    !multipolicyValue, children: _jsx("span", { children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C" }) }), _jsxs("div", { className: classes.multipolicyContainer, children: [_jsxs("div", { className: classes.multipolicyLabel, children: [_jsx("span", { children: "\u0413\u043E\u0434\u043E\u0432\u043E\u0439 \u043F\u043E\u043B\u0438\u0441:" }), _jsxs("span", { "data-selected": true, children: [label, _jsx(UnfoldMoreIcon, { width: 18, height: 18, isBold: true })] })] }), _jsx("select", { onChange: (e) => setMultipolicyValue(e.target.value), value: multipolicyValue || EMPTY_MULTIPOLICY_OPTION.value, className: classes.multipolicySelect, tabIndex: -1, children: _jsxs("optgroup", { label: `Кол-во застрахованных дней в году${isMobile ? ' или любое кол-во поездок не более 90 дней каждая' : ''}`, children: [_jsx("hr", {}), [
                                    EMPTY_MULTIPOLICY_OPTION,
                                    ...MULTIPOLICY_OPTIONS,
                                ].map(({ value, selectOptionLabel }) => (_jsx("option", { value: value.toString(), children: selectOptionLabel }, value)))] }) })] })] }));
});
