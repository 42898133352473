import { createSlice } from '@reduxjs/toolkit';
import { SEARCH_STATE_NAMESPACE } from './consts';
import { SearchTrie } from './search';
import { searchTags } from './filtersSearchTags';
export const searchInitialState = {
    input: '',
    results: null,
};
export const filtersSearchTrie = new SearchTrie(searchTags);
const SearchSlice = createSlice({
    name: SEARCH_STATE_NAMESPACE,
    initialState: searchInitialState,
    reducers: {
        _setInput: (state, action) => {
            state.input = action.payload;
        },
        _setResults: (state, action) => {
            state.results = action.payload;
        },
    },
});
const { reducer: searchReducer, actions: searchActions } = SearchSlice;
export { searchReducer, searchActions };
export { SEARCH_STATE_NAMESPACE };
