import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntersection, useScrollbarWidth } from 'react-use';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import { useScreenSizes } from '@che-ins-ui/theme';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { removeTrailingSlash } from '@/libs/helpers';
import { ROUTES } from '../routes';
import { useStyles } from './styles';
import { Search } from './components/Search';
import { scrollToShowcase, ShowcaseMobileInfo, } from './components/ShowcaseInfo/ShowcaseInfo';
const initialPathname = '/c/';
const initialSearch = location.search;
export const TravelForm = memo(() => {
    const classes = useStyles();
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const scrollbarWidth = useScrollbarWidth();
    const paddingRight = isSomeModalOpen ? scrollbarWidth + 'px' : '0px';
    const wrapperRef = useRef(null);
    const { width } = useScreenSizes();
    const isTablet = width >= BREAKPOINTS['md'];
    const isTabletRef = useRef(isTablet);
    isTabletRef.current = isTablet;
    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY;
            const parallaxStart = 170;
            const parralaxThreshold = 160;
            if (wrapperRef.current) {
                if (scrolled < parallaxStart) {
                    wrapperRef.current.style.transform = `translateY(0px)`;
                }
                else {
                    const multiplier = isTabletRef.current ? 0.75 : 0.49;
                    const offset = Math.min(parralaxThreshold, scrolled - parallaxStart) *
                        multiplier;
                    wrapperRef.current.style.transform = `translateY(-${offset}px)`;
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const entry = useIntersection(wrapperRef, {});
    const isFormSeen = !entry?.isIntersecting;
    useEffect(() => {
        if (removeTrailingSlash(initialPathname) === ROUTES.calculation) {
            const queryParams = new URLSearchParams(initialSearch);
            if (queryParams.get('target') === 'showcase') {
                scrollToShowcase();
            }
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.wrapper, style: { paddingRight }, ref: wrapperRef, children: _jsx(Search, {}) }), _jsx(ShowcaseMobileInfo, { isFormSeen: isFormSeen })] }));
});
