import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { DeleteIcon } from '@che-ins-ui/icons/DeleteIcon';
import { declOfNum } from '@/libs/helpers';
import { getTypes } from '@/libs/static';
import { comparingActions } from '@/models/comparing';
import { useCheTheme } from '@/styles/themes';
import { ButtonWrapperWithTooltip } from '../Tooltip/ButtonWrapperWithTooltip';
import { ComparingTableOfferServices } from './ComparingTableOfferServices';
import { useTableStyles } from './tableStyles';
import { ComparingTableOfferLimitations } from './ComparingTableOfferLimitations';
import { comparingTableOfferMoveDuration } from './ComparingTableOffers';
import { ComparingPriceButton } from './ComparingPriceButton';
export const ComparingTableOffer = memo(({ id, name, linkToLogo, services, servicesCount, missingServicesCount, price, allServices, repeatingServices, missingServices, isExpanded, limitations, addToBasket, }) => {
    const classes = useTableStyles();
    const dispatch = useDispatch();
    const theme = useCheTheme();
    return (_jsxs(motion.div, { className: classes.offer, exit: { scaleY: 0.6, scaleX: 0.9 }, layout: "position", transition: {
            height: { duration: 0.1 },
            layout: {
                duration: comparingTableOfferMoveDuration / 1000,
            },
        }, "data-expanded": isExpanded, children: [_jsxs("div", { className: classes.offerHeaderWrapper, children: [_jsxs("div", { className: classes.offerHeader, children: [_jsx("img", { className: classes.offerImage, src: linkToLogo, alt: "" }), _jsxs("div", { className: classes.offerTitles, children: [_jsx("span", { className: classes.offerTitle, children: name }), _jsx("span", { className: classes.offerDescription, "data-warning": Boolean(missingServicesCount), children: missingServicesCount
                                            ? `Отсутствует ${missingServicesCount} ${declOfNum(missingServicesCount, getTypes('SERVICESMALL'))}`
                                            : `${servicesCount} опций` })] }), _jsx(ButtonWrapperWithTooltip, { tooltipLabel: "", position: "bottom", tooltipY: 4, onClick: () => {
                                    dispatch(comparingActions.toggleComparing(id));
                                }, additionalStyles: {
                                    borderRadius: 999,
                                    outlineOffset: -4,
                                    position: 'absolute',
                                    top: -10,
                                    right: -13,
                                    left: 'auto',
                                }, children: _jsx("div", { className: classes.offerDelete, children: _jsx(DeleteIcon, {}) }) })] }), _jsx("div", { className: classes.offerPriceWrapper, children: _jsx(ComparingPriceButton, { price: price, onClick: addToBasket }) })] }), _jsx(ComparingTableOfferServices, { services: services, allServices: allServices, repeatingServices: repeatingServices, missingServices: missingServices }), _jsx(ComparingTableOfferLimitations, { limitations: limitations })] }));
});
