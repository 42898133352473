import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useCheTheme } from '@/styles/themes';
import { useDelayedActivation } from '@/libs/hooks/useDelayedActivation';
import { useStyles } from './styles';
export const MustReadInfoBlock = memo(({ isOpened, setIsOpened, icon, title, areAllItemsEnabled, children, onHide, }) => {
    const classes = useStyles();
    return (_jsxs("div", { className: classes.root, children: [_jsxs("div", { className: classes.titleButton, onClick: () => {
                    setIsOpened(!isOpened);
                }, role: "button", children: [_jsx("div", { className: classes.logo, "data-all-enabled": areAllItemsEnabled, children: icon }), _jsx("h3", { children: title }), isOpened && onHide && (_jsx("button", { "data-hide-button": true, onClick: (e) => {
                            e.stopPropagation();
                            onHide();
                        }, children: _jsx(CloseIcon, {}) })), _jsx("button", { "data-dropdown-button": true, children: _jsx(AccordionIcon, { isOpened: isOpened }) })] }), _jsx(motion.div, { variants: {
                    main: isOpened
                        ? { height: 'auto', y: 0, opacity: 1 }
                        : { height: 0, y: -8, opacity: 0.6 },
                }, transition: { type: 'just', duration: 0.2 }, initial: "main", animate: "main", style: { overflow: 'hidden' }, children: children })] }));
});
const animateProps = {
    attributeName: 'points',
    dur: '280ms',
    fill: 'freeze',
    keyTimes: '0; 0.5; 1',
    calcMode: 'spline',
    begin: 'indefinite',
};
const opened = '15 7.72 8.5 1.13 2 7.72';
const closed = '15 1.13 8.5 7.72 2 1.13';
const middle = '15.85 4.42 8.5 4.42 1.15 4.42';
export const AccordionIcon = memo(({ isOpened, width = 16, height = 16, }) => {
    const theme = useCheTheme();
    const isInitialized = useDelayedActivation(200);
    const expandRef = useRef(null);
    const collapseRef = useRef(null);
    useEffect(() => {
        if (isOpened) {
            expandRef.current?.beginElement();
        }
        else {
            collapseRef.current?.beginElement();
        }
    }, [isOpened]);
    const polygonProps = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 2.4,
        fill: 'none',
        fillRule: 'evenodd',
        points: isOpened ? opened : closed,
        stroke: theme.colors.textSecondary,
    };
    return (_jsxs("svg", { viewBox: "0 0 17 8.85", width: width, height: height, xmlns: "http://www.w3.org/2000/svg", children: [_jsx("polyline", { ...polygonProps, opacity: isInitialized ? 0 : 1 }), _jsxs("polyline", { ...polygonProps, opacity: isInitialized ? 1 : 0, children: [_jsx("animate", { ...animateProps, ref: expandRef, values: `${closed}; ${middle}; ${opened}`, keySplines: "0.12, 0, 0.38, 0; 0.2, 1, 0.68, 1" }), _jsx("animate", { ...animateProps, ref: collapseRef, values: `${opened}; ${middle}; ${closed}`, keySplines: "0.2, 0, 0.68, 0; 0.2, 1, 0.68, 1" })] })] }));
});
const CloseIcon = () => (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", height: 28, width: 28, children: _jsx("path", { d: "M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" }) }));
export const MustReadInfoOptionEnabler = ({ label, isActive, onCheck, tabIndex, }) => {
    const classes = useStyles();
    return (_jsxs("button", { className: classes.optionEnabler, onClick: onCheck, tabIndex: tabIndex, children: [_jsx("div", { className: classes.optionIconContainer, children: _jsx("div", { className: classes.optionIcon, "data-active": isActive, children: isActive && (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 -960 960 960", width: "24px", children: _jsx("path", { d: "M382-200 113-469l97-97 172 173 369-369 97 96-466 466Z" }) })) }) }), _jsx("span", { "data-active": isActive, className: classes.optionLabel, children: label })] }));
};
