export const ELEVATIONS = {
    cloud: '0px 4px 24px rgba(0, 0, 0, 0.06)',
    day: {
        1: '0 1px 2px rgba(0, 0, 0, 0.16),0 0px 2px rgba(0, 0, 0, 0.05)',
        2: '0 4px 8px rgba(0, 0, 0, 0.16),0 0px 4px rgba(0, 0, 0, 0.05)',
        3: '0 6px 16px rgba(0, 0, 0, 0.16),0 0px 4px rgba(0, 0, 0, 0.05)',
        4: '0 12px 32px rgba(0, 0, 0, 0.16),0 0px 8px rgba(0, 0, 0, 0.05)',
        5: '0 32px 64px rgba(0, 0, 0, 0.16),0 0px 16px rgba(0, 0, 0, 0.05)',
    },
    night: {
        1: '0 1px 2px rgba(0, 0, 0, 0.4),0 0px 2px rgba(0, 0, 0, 0.4)',
        2: '0 4px 8px rgba(0, 0, 0, 0.4),0 0px 4px rgba(0, 0, 0, 0.4)',
        3: '0 6px 16px rgba(0, 0, 0, 0.5),0 0px 4px rgba(0, 0, 0, 0.4)',
        4: '0 12px 32px rgba(0, 0, 0, 0.5),0 0px 8px rgba(0, 0, 0, 0.4)',
        5: '0 32px 64px rgba(0, 0, 0, 0.6),0 0px 16px rgba(0, 0, 0, 0.4)',
    },
};
