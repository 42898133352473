import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendSearchActivity } from './sendSearchActivity';
import { filtersSearchTrie, searchActions } from './index';
const DEBOUNCE_SEARCH_DELAY = 50;
const DEBOUNCE_PERSIST_DELAY = 800;
const DEBOUNCE_SEND_DELAY = DEBOUNCE_PERSIST_DELAY + 6800;
let timeouts = {
    setResults: null,
    persistActivity: null,
    sendActivity: null,
};
let collectedQueries = [];
const clearAllTimeouts = () => {
    Object.values(timeouts).forEach((timeout) => {
        if (timeout)
            clearTimeout(timeout);
    });
};
const persistActivity = (input) => {
    const formattedInput = input.toLowerCase().trim();
    timeouts.persistActivityTimeout = setTimeout(() => {
        collectedQueries = collectedQueries.filter((word) => !formattedInput.startsWith(word));
        collectedQueries.push(formattedInput);
    }, DEBOUNCE_PERSIST_DELAY);
};
const sendActivity = () => {
    timeouts.sendActivityTimeout = setTimeout(() => {
        sendSearchActivity(collectedQueries);
        collectedQueries = [];
    }, DEBOUNCE_SEND_DELAY);
};
const setResults = (input, dispatch) => {
    timeouts.setResultsTimeout = setTimeout(() => {
        dispatch(searchActions._setResults(Array.from(filtersSearchTrie.search(input))));
    }, DEBOUNCE_SEARCH_DELAY);
};
const resetResults = (dispatch) => {
    dispatch(searchActions._setResults(null));
};
export const setSearchInput = createAsyncThunk('', async (input, listenerApi) => {
    try {
        listenerApi.dispatch(searchActions._setInput(input));
        clearAllTimeouts();
        const isEmpty = !input.trim();
        if (!isEmpty) {
            setResults(input, listenerApi.dispatch);
            persistActivity(input);
        }
        else {
            resetResults(listenerApi.dispatch);
        }
        sendActivity();
    }
    catch (error) {
        console.error(error);
    }
});
