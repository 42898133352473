import { combineReducers } from '@reduxjs/toolkit';
import { TABS_STATE_NAMESPACE, tabsReducer } from '@che-ins-ui/tabs';
import { modalReducer, MODAL_STATE_NAMESPACE } from '@che-ins-ui/modal';
import { phoneInputReducer, PHONE_INPUT_NAMESPACE, } from '@che-ins-ui/phone-input';
import { API_STATE_NAMESPACE, apiReducer } from './api/index';
import { REQUEST_STATE_NAMESPACE, requestReducer } from './request/index';
import { CALCULATION_STATE_NAMESPACE, calculationReducer, } from './calculation/index';
import { BASKET_STATE_NAMESPACE, basketReducer } from './basket/index';
import { PROCESS_STATE_NAMESPACE, processReducer } from './process/index';
import { UPSELL_STATE_NAMESPACE, upsellReducer } from './upsell';
import { COMPARING_STATE_NAMESPACE, comparingReducer } from './comparing';
import { USER_STATE_NAMESPACE, userReducer } from './user/';
import { NOTEBOOK_STATE_NAMESPACE, notebookReducer } from './notebook';
import { FORMS_STATE_NAMESPACE, formsReducer } from './forms';
import { THEME_STATE_NAMESPACE, themeReducer } from './theme';
import { SEARCH_STATE_NAMESPACE, searchReducer } from './search';
const rootReducer = combineReducers({
    [API_STATE_NAMESPACE]: apiReducer,
    [REQUEST_STATE_NAMESPACE]: requestReducer,
    [CALCULATION_STATE_NAMESPACE]: calculationReducer,
    [BASKET_STATE_NAMESPACE]: basketReducer,
    [COMPARING_STATE_NAMESPACE]: comparingReducer,
    [SEARCH_STATE_NAMESPACE]: searchReducer,
    [UPSELL_STATE_NAMESPACE]: upsellReducer,
    [PROCESS_STATE_NAMESPACE]: processReducer,
    [MODAL_STATE_NAMESPACE]: modalReducer,
    [FORMS_STATE_NAMESPACE]: formsReducer,
    [TABS_STATE_NAMESPACE]: tabsReducer,
    [PHONE_INPUT_NAMESPACE]: phoneInputReducer,
    [USER_STATE_NAMESPACE]: userReducer,
    [NOTEBOOK_STATE_NAMESPACE]: notebookReducer,
    [THEME_STATE_NAMESPACE]: themeReducer,
});
export default rootReducer;
