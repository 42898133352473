import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo, useId } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useStyles } from './styles';
const animation = {
    variants: {
        hidden: { opacity: 0, scale: 0.3 },
        visible: { opacity: 1, scale: 1 },
    },
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    transition: { duration: 0.08, bounce: 40 },
};
const selectPath = (_jsx("path", { d: "M7.80568 11.2347C8.17649 11.5884 8.82351 11.5884 9.19432 11.2347L12.7593 7.83382C13.2988 7.31909 12.8724 6.5 12.0649 6.5H4.93506C4.12756 6.5 3.70118 7.31909 4.24074 7.83382L7.80568 11.2347Z" }));
const calendarPath = (_jsxs(_Fragment, { children: [_jsx("path", { clipRule: "evenodd", fillRule: "evenodd", d: "M2.83333 0C1.26853 0 0 1.26853 0 2.83333V14.1667C0 15.7315 1.26853 17 2.83333 17H14.1667C15.7315 17 17 15.7315 17 14.1667V2.83333C17 1.26853 15.7315 0 14.1667 0H2.83333ZM1.88889 14.1667V4.72222H15.1111V14.1667C15.1111 14.6883 14.6883 15.1111 14.1667 15.1111H2.83333C2.31173 15.1111 1.88889 14.6883 1.88889 14.1667Z" }), _jsx("path", { d: "M3.77778 7.55556C3.77778 7.03395 4.20062 6.61111 4.72222 6.61111H7.55556C8.07716 6.61111 8.5 7.03395 8.5 7.55556V9.44444C8.5 9.96605 8.07716 10.3889 7.55556 10.3889H4.72222C4.20062 10.3889 3.77778 9.96605 3.77778 9.44444V7.55556Z" })] }));
const postfixTypes = {
    countries: selectPath,
    tourists: selectPath,
    'date-from': calendarPath,
    'date-to': calendarPath,
};
export const TravelFormInput = memo(forwardRef(({ value, label, isFocused, placeholder, onFocus, onClose, onChange, type, ...props }, ref) => {
    const classes = useStyles();
    const isInactive = !(isFocused || Boolean(value));
    const generatedId = useId();
    const id = props.id || generatedId;
    return (_jsxs("div", { className: classes.container, "data-type": type, "data-focused": isFocused, children: [_jsx("button", { className: classes.postfixContainer, "data-clickable": isFocused, tabIndex: -1, onClick: () => {
                    if (isFocused) {
                        onClose();
                    }
                }, children: _jsx("div", { className: classes.postfixWrapper, "data-is-focused": isFocused, children: _jsx(AnimatePresence, { initial: false, children: !isFocused ? (_createElement(motion.div, { ...animation, className: classes.postfixIcon, key: "postfix" },
                            _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 17 17", height: 17, width: 17, children: postfixTypes[type] }))) : (_createElement(motion.div, { ...animation, className: classes.postfixConfirm, key: "confirm" },
                            _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: 24, width: 24, viewBox: "0 -960 960 960", children: _jsx("path", { d: "M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" }) }))) }) }) }), _jsx("input", { onDoubleClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }, id: id, type: "text", autoComplete: "off", value: value, placeholder: placeholder, onChange: (e) => onChange?.(e.target.value), className: classes.input, onFocus: onFocus, readOnly: !onChange, ref: ref, "data-type": type, "data-inactive": isInactive, "data-focused": isFocused, ...props }), _jsx("label", { htmlFor: id, className: classes.label, "data-focused": isFocused, "data-inactive": isInactive, children: label })] }));
}));
