import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apiSelector } from '@/models/api/selectors';
import { ASSISTANCE_CODE } from '@/libs/static';
import { ACTIVE_REST_OPTIONS_SEARCH_CODE, SPORTS_SEARCH_CODE, } from '@/models/search/filtersSearchTags';
import { filtersSearchTrie } from '@/models/search';
let hasSetAssistancesToSearch = false;
let hasSetSportsToSearch = false;
export const useFillFiltersSearchTags = () => {
    const { activeRestServices, commonSports, competitionSports, assistances } = useSelector(apiSelector);
    useEffect(() => {
        if (!hasSetAssistancesToSearch) {
            filtersSearchTrie.update(ASSISTANCE_CODE, assistances.map(({ name }) => name));
            hasSetAssistancesToSearch = true;
        }
    }, [assistances]);
    useEffect(() => {
        if (!hasSetSportsToSearch) {
            const updateOptions = { minWordLengthToInsert: 3 };
            filtersSearchTrie.update(ACTIVE_REST_OPTIONS_SEARCH_CODE, activeRestServices.map(({ name }) => name), updateOptions);
            filtersSearchTrie.update(SPORTS_SEARCH_CODE, [...commonSports, ...competitionSports].map(({ name }) => name), updateOptions);
            hasSetSportsToSearch = true;
        }
    }, [activeRestServices, commonSports, competitionSports]);
};
