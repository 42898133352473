import { ROUTES } from '@/components/routes';
const PRODUCT = 'vzr';
class MindboxApi {
    constructor() {
        this.checkIsMindbox = (obj) => !!obj && typeof obj === 'function';
    }
    sendAction(operation, data, onSuccess, onError) {
        const mindbox = window.mindbox;
        if (!this.checkIsMindbox(mindbox)) {
            return;
        }
        try {
            mindbox('async', {
                operation,
                data,
                onSuccess,
                onError,
            });
        }
        catch (error) {
            console.error('Mindbox operation failed:', error);
        }
    }
    calcCost(price) {
        this.sendAction('calcCost', {
            customerAction: {
                customFields: {
                    calcPrice: Math.ceil(price),
                    calcProduct: PRODUCT,
                    calcURL: __HOMEPAGE__ + ROUTES.insurance,
                },
            },
        });
    }
}
export const mindboxApi = new MindboxApi();
