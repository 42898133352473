import set from 'lodash.set';
import { LSHelper } from '@/libs/localStorage';
import { requestInitialState } from '@/models/request';
import { parseAndNormalizeQueryStringParams } from '@/libs/helpers';
import { getWhiteLabelParams, setCountriesAndCountryGroups, setMarketingsParamsToState, setNormalizedActualDates, setTouristsToPersistedState, } from './libs/transformers/prepareStateTransformers';
import { basketInitialState, BASKET_STATE_NAMESPACE } from './models/basket';
import { COMPARING_STATE_NAMESPACE, comparingInitialState, } from './models/comparing';
import { USER_STATE_NAMESPACE, userInitialState } from './models/user';
import { formsInitialState } from './models/forms/index';
import { FORMS_STATE_NAMESPACE } from './models/forms';
import { getDeepCopy } from './models/notebook/helpers';
import { THEME_STATE_NAMESPACE, themeInitialState } from './models/theme';
import { SEARCH_STATE_NAMESPACE, searchInitialState } from './models/search';
export const REQUEST_STATE_NAMESPACE = 'request';
export const getPersistedState = () => {
    const { countriesParamsQS, marketingParamsQS, touristsParamsQS, requestParamsQS, isIgnoreLocalStorage, } = parseAndNormalizeQueryStringParams();
    const localeStorageValue = isIgnoreLocalStorage ? {} : LSHelper.get();
    const { countriesParamsDataCheOptions, marketingParamsDataCheOptions, requestParamsDataCheOptions, isWhiteLabelPage, isFrame, isShowExpiredCalculationModal, isLogo, isPaytureInPay, isIgnoreForm, isIgnoreUpsell, isCheSupport, basename, } = getWhiteLabelParams();
    const persistedState = {
        [REQUEST_STATE_NAMESPACE]: {},
        [BASKET_STATE_NAMESPACE]: basketInitialState,
        [FORMS_STATE_NAMESPACE]: {},
        [COMPARING_STATE_NAMESPACE]: {},
        [SEARCH_STATE_NAMESPACE]: searchInitialState,
        [USER_STATE_NAMESPACE]: userInitialState,
        [THEME_STATE_NAMESPACE]: themeInitialState,
    };
    if (isWhiteLabelPage && Object.keys(requestParamsDataCheOptions).length) {
        persistedState[REQUEST_STATE_NAMESPACE] = {
            ...requestInitialState,
            ...requestParamsDataCheOptions,
        };
        persistedState[REQUEST_STATE_NAMESPACE].services = {
            ...requestInitialState.services,
            ...persistedState[REQUEST_STATE_NAMESPACE].services,
        };
    }
    else if (Object.keys(requestParamsQS).length) {
        persistedState[REQUEST_STATE_NAMESPACE] = {
            ...requestInitialState,
            ...requestParamsQS,
        };
        persistedState[REQUEST_STATE_NAMESPACE].services = {
            ...requestInitialState.services,
            ...persistedState[REQUEST_STATE_NAMESPACE].services,
        };
    }
    else if (!isIgnoreLocalStorage) {
        persistedState[REQUEST_STATE_NAMESPACE] = {
            ...requestInitialState,
            ...(localeStorageValue?.[REQUEST_STATE_NAMESPACE] || {}),
        };
        setNormalizedActualDates({ persistedState });
    }
    else {
        persistedState[REQUEST_STATE_NAMESPACE] = requestInitialState;
    }
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isFrame`, isFrame);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isShowExpiredCalculationModal`, isShowExpiredCalculationModal);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.basename`, basename);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isLogo`, isLogo);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isPaytureInPay`, isPaytureInPay);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isIgnoreForm`, isIgnoreForm);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isIgnoreUpsell`, isIgnoreUpsell);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isCheSupport`, isCheSupport);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.isCurrencyInited`, false);
    if (!isIgnoreLocalStorage) {
        persistedState[BASKET_STATE_NAMESPACE] =
            localeStorageValue?.[BASKET_STATE_NAMESPACE] || basketInitialState;
        persistedState[COMPARING_STATE_NAMESPACE] =
            localeStorageValue?.[COMPARING_STATE_NAMESPACE] ||
                comparingInitialState;
        persistedState[USER_STATE_NAMESPACE] =
            localeStorageValue?.[USER_STATE_NAMESPACE] ||
                getDeepCopy(userInitialState);
        persistedState[FORMS_STATE_NAMESPACE] =
            localeStorageValue?.[FORMS_STATE_NAMESPACE] ||
                getDeepCopy(formsInitialState);
    }
    setTouristsToPersistedState({
        localeStorageValue,
        touristsParamsQS,
        persistedState,
        isIgnoreLocalStorage,
    });
    setCountriesAndCountryGroups({
        localeStorageValue,
        countriesParamsQS,
        countriesParamsDataCheOptions,
        persistedState,
        isIgnoreLocalStorage,
    });
    setMarketingsParamsToState({
        localeStorageValue,
        marketingParamsQS,
        marketingParamsDataCheOptions,
        persistedState,
    });
    return persistedState;
};
