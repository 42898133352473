import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    termsAndAgreement: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        fontWeight: 400,
        color: theme.colors.textPrimary,
        marginTop: '8px',
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        fontSize: MOBILE_FONT_SIZES.main,
        '& a': {
            color: theme.colors.brand,
            '&:-webkit-any-link, &:visited': {
                color: theme.colors.brand,
            },
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    root: {
        background: theme.colors.cloud,
        borderRadius: '12px',
        marginBottom: '8px',
        boxShadow: theme.elevations.cloud,
        padding: '12px',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            padding: '15px',
            '&[data-insurer="true"]': {
                '& #input-error': {
                    '&[data-error="true"]': {
                        [theme.breakpoints.up('lg')]: {
                            marginBottom: -6,
                        },
                    },
                },
            },
        },
    },
    touristsHeader: {
        display: 'flex',
        gap: 20,
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    header: {
        marginBottom: '20px',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            marginBottom: '25px',
        },
    },
    title: {
        fontSize: MOBILE_FONT_SIZES.title,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.title,
        },
    },
    authBlock: {
        display: 'flex',
        padding: '8px 12px',
        alignItems: 'flex-start',
        gap: 8,
        borderRadius: 8,
        background: '#F4F5F7',
        cursor: 'pointer',
    },
    authImage: {
        height: 14,
        width: 14,
        marginTop: 4,
    },
    authTitle: {
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.brand,
        fontWeight: 700,
        marginBottom: 2,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    authText: {
        fontSize: MOBILE_FONT_SIZES.small,
        color: theme.colors.textPrimary,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    tourist: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            marginBottom: '6px',
        },
        marginTop: '14px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
            '& > div': {
                marginBottom: '6px',
            },
            display: 'grid',
            gridTemplateColumns: '6fr 6fr 3fr',
            gap: '0px 6px',
        },
    },
    birthdayWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            gap: '0px',
        },
    },
    touristbirthday: {
        flexGrow: 1,
        marginBottom: '6px',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '0px',
        },
    },
    buttons: {
        marginTop: '12px',
        display: 'flex',
        gap: '6px',
        justifyContent: 'space-between',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    sendLinkIcon: {
        marginRight: '10px',
    },
    insurer: {
        marginBottom: '6px',
        [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridColumnGap: '6px',
            gridRowGap: '12px',
            gridTemplateColumns: 'repeat(3, 1fr)',
            marginBottom: '12px',
        },
    },
    insurerWithoutSecondName: {
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '5fr 5fr 3fr',
            '& > :nth-child(3)': {
                gridColumn: 'span 1',
            },
            '& > :nth-child(6)': {
                gridColumn: '1 / span 1',
            },
            '& > :nth-child(8)': {
                gridColumn: '1 / span 1',
            },
        },
    },
    insurerFieldWrapper: {
        position: 'relative',
        marginBottom: '6px',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '0px',
        },
    },
    secondNameWrapper: {
        marginBottom: '14px',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '0px',
        },
    },
    hasSecondNameCheckbox: {
        marginTop: '12px',
        maxHeight: '32px',
        [theme.breakpoints.up('sm')]: {
            maxHeight: '30px',
        },
    },
    deleteIcon: {
        margin: '0px 0px 2px 2px',
        width: 20,
        height: 20,
        borderRadius: 999,
        outlineOffset: 2,
        '& svg > path': {
            fill: `${theme.colors.error} !important`,
        },
    },
    shareIcon: {
        marginRight: '10px',
        '& path': {
            fillOpacity: 1,
        },
    },
    addTouristButtonWrapper: {
        marginTop: '8px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
        },
    },
    crossIcon: {
        transition: '0.2s',
        opacity: 'unset',
        cursor: 'pointer',
        '&[data-is-open="true"]': {
            transform: 'rotate(180deg)',
        },
    },
}), { name: 'insurance' });
export const useInsurerAndTouristStyles = createUseStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
        width: '100%',
        marginBottom: 8,
        position: 'relative',
    },
    root: {
        marginBottom: 0,
        flex: '1 1 auto',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 12,
        marginBottom: 12,
    },
    itemsContainer: {
        display: 'flex',
        gap: 8,
        flexWrap: 'wrap',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    item: {
        display: 'flex',
        gap: 6,
        padding: '12px 16px 12px 8px',
        alignItems: 'stretch',
        backgroundColor: theme.colors.textBackground,
        borderRadius: 12,
        flex: '1 1 auto',
        [theme.breakpoints.up('lg')]: {
            flex: 'inherit',
        },
    },
    itemIconBlock: {
        flexShrink: 0,
        maxHeight: 32,
        paddingTop: 4,
        '& svg': {
            width: 28,
            height: 28,
            '& path': {
                fill: theme.colors.textPrimary,
            },
        },
    },
    itemTitles: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    itemTitle: {
        fontSize: 13,
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        textDecoration: 'none',
        '&[data-type="secondary"]': {
            color: theme.colors.textPrimary,
            fontWeight: 400,
        },
        '&[data-type="main"]': {
            color: theme.colors.sky,
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        '&[data-loading="true"]': {
            width: 170,
            height: 18,
            borderRadius: 6,
            backgroundColor: theme.colors.textTertiary,
        },
    },
}), { name: 'insurance' });
