import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { CloseIcon } from '@che-ins-ui/icons/CloseIcon';
import { AlertIcon } from '@che-ins-ui/icons/AlertIcon';
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import { apiSelector } from '@/models/api/selectors';
import { DESKTOP_FILTERS_BREAKPOINT } from '@/styles/themes/components';
import { CURRENCIES_BADGES } from '@/libs/constants';
import { requestCurrencySelector } from '@/models/request/selectors';
import { PREGNANCY_CODE } from '@/libs/static';
import { useStyles } from './styles';
const MissingServicesHint = ({ lowerSumServices, missingServices, }) => {
    const { servicesDictionary } = useSelector(apiSelector);
    const currency = useSelector(requestCurrencySelector);
    const classes = useStyles();
    const { width } = useScreenSizes();
    const isDesktop = width > BREAKPOINTS[DESKTOP_FILTERS_BREAKPOINT];
    const lowerSumIconSize = isDesktop
        ? { width: 15, height: 15 }
        : { width: 11, height: 11 };
    const missingServiceIconSize = isDesktop
        ? { width: 13, height: 13 }
        : { width: 10, height: 10 };
    return (_jsxs("ul", { className: classes.root, children: [lowerSumServices.map(({ code, value }, index) => (_jsxs("li", { className: classes.tooltipItem, children: [_jsx(AlertIcon, { className: classes.iconAlert, ...lowerSumIconSize }), servicesDictionary[code]?.name, ":", ' ', value
                        ? `${value} ${code === PREGNANCY_CODE ? 'нед.' : CURRENCIES_BADGES[currency]}`
                        : null] }, index))), missingServices.map(({ code }, index) => (_jsxs("li", { className: classes.tooltipItem, children: [_jsx(CloseIcon, { className: classes.iconClose, ...missingServiceIconSize }), servicesDictionary[code]?.name] }, index)))] }));
};
export default MissingServicesHint;
