import { createUseStyles } from 'react-jss';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        background: theme.colors.cloud,
        borderRadius: 12,
        minHeight: 169,
        display: 'flex',
        alignItems: 'stretch',
        boxShadow: theme.elevations.cloud,
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            minHeight: 117,
        },
    },
    info: {
        flexShrink: 1,
        width: '100%',
        padding: '12px 0px 12px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '118px',
        borderBottom: `1px solid ${theme.colors.textTertiary}`,
        borderRight: 'none',
        [theme.breakpoints.up('md')]: {
            padding: '12px 0px 15px 11px',
            borderBottom: 'none',
            borderRight: `1px solid ${theme.colors.textTertiary}`,
            height: 117,
        },
    },
    offerInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    companyInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    companyName: {
        color: theme.colors.sky,
        fontSize: 14,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    companyLogo: {
        alignSelf: 'flex-start',
        marginRight: '10px',
        width: '32px',
        height: '32px',
        borderRadius: '9px',
    },
    tags: {
        display: 'flex',
        gap: 6,
    },
    trimmableText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    payButtonBlock: {
        width: '100%',
        flexShrink: 0,
        padding: '12px',
        [theme.breakpoints.up('md')]: {
            width: '180px',
        },
    },
    payButtonSkeletonWrapper: {
        overflow: 'hidden',
    },
    payButtonText: {
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    commissionBlock: {
        marginTop: 10,
        [theme.breakpoints.up('md')]: {
            marginTop: 13,
        },
    },
    commission: {
        width: '100%',
        textAlign: 'center',
        color: theme.colors.success,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    companyDescription: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: -3,
        },
    },
}), { name: 'offer-card' });
