import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { ExportIcon } from '@che-ins-ui/icons/ExportIcon';
import { ADDITIONAL_SHARE_TEXT, MODAL_PRODUCT_ID_QUERY_PARAM, } from '@/libs/constants';
import { shareLinkModalOpen } from '@/components/common/Hint/actions';
import { comparingProductsSelector } from '@/models/comparing/selectors';
import { comparingActions } from '@/models/comparing';
import { ComparingIcon } from '@/components/common/Comparing/ComparingIcon';
import { ButtonWrapperWithTooltip } from '@/components/common/Tooltip/ButtonWrapperWithTooltip';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { useStyles } from './styles';
const tooltipsY = -2;
export const IconsBlock = ({ comparingOnly, productId, company, noPaddingRight, uncheckCompareOnly, withComparingText, checkedComparingTooltipLabel = 'Убрать из сравнения', noTooltips, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const comparingProducts = useSelector(comparingProductsSelector);
    const queryString = useCalculationQueryString({
        addParams: {
            [MODAL_PRODUCT_ID_QUERY_PARAM]: productId,
        },
    });
    const { name: companyName, linkToLogo: companyLogoUrl } = company;
    const isCompared = Boolean(comparingProducts.find((product) => product === company.id));
    const tooltipDisabling = noTooltips
        ? {
            tooltipLabel: undefined,
        }
        : {};
    const shareUrl = __HOMEPAGE__ + '/widget?' + queryString;
    const handleOpenShareModal = () => {
        dispatch(shareLinkModalOpen({
            companyName,
            companyLogoUrl,
            shareUrl,
            additionalText: ADDITIONAL_SHARE_TEXT,
        }));
    };
    const withShareButton = !comparingOnly && productId;
    return (_jsxs("div", { className: classes.root, "data-no-padding-right": noPaddingRight, children: [withShareButton && (_jsx(ButtonWrapperWithTooltip, { tooltipLabel: "\u041F\u043E\u0434\u0435\u043B\u0438\u0442\u044C\u0441\u044F", ...tooltipDisabling, onClick: handleOpenShareModal, height: 21, tooltipY: tooltipsY, children: _jsx(ExportIcon, { className: classes.exportIcon }) })), _jsx("div", { className: classes.compare, children: (!uncheckCompareOnly || isCompared) && (_jsx(ButtonWrapperWithTooltip, { tooltipLabel: isCompared
                        ? checkedComparingTooltipLabel
                        : 'Добавить в сравнение', ...tooltipDisabling, onClick: () => {
                        dispatch(comparingActions.toggleComparing(company.id));
                    }, height: 21, tooltipX: -1, tooltipY: tooltipsY, children: _jsx(ComparingIcon, { checked: isCompared, withText: withComparingText }) })) })] }));
};
