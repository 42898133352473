import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { requestActions } from '@/models/request';
import { TagPlainButton } from '../common/TagButton/TagButton';
import { useStyles } from './styles';
const SELECTED_COUNT_TO_SHOW_RESET = 4;
export const SelectedFixedPart = memo(({ isDesktop, selectedCount, openFilters }) => {
    return isDesktop ? (_jsx(Desktop, { selectedCount: selectedCount })) : (_jsx(Mobile, { openFilters: openFilters }));
});
const ResetButton = () => {
    const dispatch = useDispatch();
    const resetServices = () => {
        dispatch(requestActions.setDefault());
    };
    return (_jsx(TagPlainButton, { onClick: resetServices, appearance: "reset", children: _jsx("span", { children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C" }) }, "reset"));
};
const TuneIcon = () => {
    return (_jsx("svg", { viewBox: "0 0 24 24", width: 24, height: 24, children: _jsx("path", { d: "M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" }) }));
};
const FilterListIcon = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 -960 960 960", children: _jsx("path", { d: "M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z" }) }));
};
const Desktop = ({ selectedCount }) => {
    const classes = useStyles();
    if (!selectedCount) {
        return (_jsxs(motion.div, { initial: { x: -10, opacity: 0 }, animate: { x: 0, opacity: 1 }, transition: { type: 'just' }, className: classes.sectionLabel, "data-placeholder": true, children: [_jsx(FilterListIcon, {}), _jsx("span", { children: "\u0417\u0434\u0435\u0441\u044C \u043F\u043E\u044F\u0432\u044F\u0442\u0441\u044F \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u043E\u043F\u0446\u0438\u0438" })] }, "placeholder"));
    }
    const shouldShowResetButton = selectedCount >= SELECTED_COUNT_TO_SHOW_RESET;
    if (shouldShowResetButton) {
        return _jsx(ResetButton, {});
    }
    return (_jsxs("div", { className: classes.sectionLabel, children: [_jsx(TuneIcon, {}), _jsx("span", { children: "\u0412\u044B\u0431\u0440\u0430\u043D\u043E:" })] }, "selected"));
};
const Mobile = ({ openFilters }) => {
    return (_jsx(motion.div, { "data-button": true, layout: "position", children: _jsxs(TagPlainButton, { onClick: openFilters, children: [_jsx(TuneIcon, {}), _jsx("span", { children: "\u0424\u0438\u043B\u044C\u0442\u0440\u044B" })] }) }, "filters-button"));
};
