import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@che-ins-ui/button';
import { calculationIsLoadingSelector, minimalPriceSelector, } from '@/models/calculation/selectors';
import { formatPrice } from '@/libs/helpers/utils';
import { scrollToTop } from '@/libs/helpers';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { RESTORABLE_QS_PARAMS } from '@/libs/constants';
import { resetSearchInput } from '@/models/search/resetSearchInput';
import { CircleLoader } from '../common/CircleLoader/CircleLoader';
export const MobileFilterShowResultButton = memo(() => {
    const dispatch = useDispatch();
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const minimalPrice = useSelector(minimalPriceSelector);
    const queryString = useCalculationQueryString({
        removeParams: [RESTORABLE_QS_PARAMS.mobileFilters],
    });
    const isMinimalPrice = minimalPrice != null;
    const onClose = () => {
        scrollToTop({ behavior: 'auto' });
        dispatch(resetSearchInput());
    };
    return (_jsxs(Button, { appearance: "mobile-filters-show-result", component: Link, to: { search: queryString }, passAppearancesToComponent: false, onClick: onClose, children: ["\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C", isLoadingCalculation ? (_jsx("div", { style: { marginLeft: 4 }, children: _jsx(CircleLoader, {}) })) : (Boolean(isMinimalPrice) && (_jsxs("span", { children: ['\u00a0', "\u043E\u0442", _jsx("b", { style: { marginLeft: 3 }, children: `${formatPrice(minimalPrice)} ₽` })] })))] }));
});
