import { HEADER_COMPONENTS_NAMES } from '@che-ins-ui/header';
import { withAlpha } from '@/styles/utils';
const tooltipElementsSelector = '& $tooltipBody, & $tooltipArrow, & $tooltipArrowShadow';
const buttonWrappersSelector = '& $authWrapperBorder, $dropdownTitle, $whyNew';
export const getHeaderTheme = (theme) => ({
    base: {
        [HEADER_COMPONENTS_NAMES.headerWrapper]: {
            minWidth: 320,
        },
        [HEADER_COMPONENTS_NAMES.whyNew]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.menuListItem]: {
            '&:hover': {
                color: theme.colors.brand,
                backgroundColor: theme.colors.brandTertiary,
            },
        },
        [HEADER_COMPONENTS_NAMES.menuListItemIcon]: {
            '& svg > path': {
                stroke: 'transparent',
                fill: theme.colors.brand,
            },
        },
        [HEADER_COMPONENTS_NAMES.profileWrapper]: {
            fontFamily: theme.font.primary,
            color: theme.colors.white,
            '& svg > path': {
                stroke: theme.colors.white,
            },
        },
        [HEADER_COMPONENTS_NAMES.title]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.description]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.navigation]: {
            minWidth: 320,
            backgroundColor: theme.colors.header,
        },
        [HEADER_COMPONENTS_NAMES.tooltipBody]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.authWrapper]: {
            overflow: 'visible',
        },
        [HEADER_COMPONENTS_NAMES.dropdownBody]: {
            backgroundColor: theme.colors.cloud,
            boxShadow: theme.elevations.dropdown,
        },
        [HEADER_COMPONENTS_NAMES.menuList]: {
            '& div > div': {
                backgroundColor: `${theme.colors.textTertiary} !important`,
            },
        },
        [HEADER_COMPONENTS_NAMES.menuTopListItem]: {
            '&:hover': {
                color: theme.colors.sky,
                backgroundColor: theme.colors.brandTertiary,
            },
        },
        [HEADER_COMPONENTS_NAMES.iconWrapper]: {
            '& svg > path': {
                fill: theme.colors.white,
            },
        },
        [HEADER_COMPONENTS_NAMES.authWrapperBorder]: {
            borderColor: withAlpha(theme.colors.white, 0.55),
            '&:hover': {
                borderColor: theme.colors.white,
            },
        },
        [HEADER_COMPONENTS_NAMES.dropdownTitle]: {
            borderColor: withAlpha(theme.colors.white, 0.55),
            '& svg > path': {
                fill: theme.colors.white,
            },
        },
        [HEADER_COMPONENTS_NAMES.buttonWrapper]: {
            '&:hover': {
                [buttonWrappersSelector]: {
                    borderColor: withAlpha(theme.colors.white, 1),
                },
            },
        },
        [HEADER_COMPONENTS_NAMES.mainLogo]: {
            '& svg > path': {
                fill: theme.colors.white,
            },
        },
    },
});
