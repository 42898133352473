import { PALETTE } from '../palette';
import { themeComponents } from './components';
import { AbstractTheme } from './AbstractTheme';
import { getFeatureStyles, LIGHT_COLORS } from './LightTheme';
import { ELEVATIONS } from './elevations';
export class DarkColors extends AbstractTheme {
    constructor() {
        super(...arguments);
        this.colors = {
            ...LIGHT_COLORS,
            white: PALETTE.darkWhite,
            brand: PALETTE.darkBrand,
            brandTertiary: PALETTE.darkBrandTertiary,
            accent: PALETTE.darkAccent,
            success: PALETTE.darkSuccess,
            deletion: PALETTE.darkDeletion,
            error: PALETTE.darkError,
            textPrimary: PALETTE.darkTextPrimary,
            textSecondary: PALETTE.darkTextSecondary,
            textTertiary: PALETTE.darkTextTertiary,
            textBackground: PALETTE.darkTextBackground,
            background: PALETTE.darkBackground,
            header: PALETTE.darkHeader,
            field: PALETTE.darkField,
            sky: PALETTE.darkWhite,
            cloud: PALETTE.darkCloud,
        };
        this.mode = 'dark';
        this.elevations = {
            1: ELEVATIONS.night[1],
            2: ELEVATIONS.night[2],
            3: ELEVATIONS.night[3],
            4: ELEVATIONS.night[4],
            5: ELEVATIONS.night[5],
            cloud: 'none',
            dropdown: ELEVATIONS.night[2],
            modal: ELEVATIONS.night[4],
            travelForm: ELEVATIONS.night[3],
        };
    }
}
export class DarkTheme extends DarkColors {
    constructor() {
        super(...arguments);
        this.components = themeComponents.reduce((acc, { name, getter }) => {
            acc[name] = getter(this);
            return acc;
        }, {});
        this.feature = getFeatureStyles(this);
    }
}
