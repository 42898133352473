import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useMeasure } from 'react-use';
import { maxDateIncluded, minDateIncluded } from '@/models/request';
import { useStyles } from './styles';
import { Month } from './Month';
import { useCalendarDates } from './useCalendarDates';
import { useCalendarMonths } from './useCalendarMonths';
import { useCalendarDimensions } from './useCalendarScrollAndDimensions';
import { Weekdays } from './Weekdays';
export const Calendar = memo(({ onSelectDate, selectedStartDate, selectedEndDate, type, inputsWidth, header, footer, }) => {
    const classes = useStyles();
    const { currentMonth, currentYear, totalMonths, dateStartTimestamp, dateEndTimestamp, areDatesUnselected, setHoveredDate, } = useCalendarDates({
        selectedStartDate,
        selectedEndDate,
        type,
    });
    const { initialMonthIndex, months, monthOptions } = useCalendarMonths({
        selectedStartDate,
        currentMonth,
        currentYear,
        totalMonths,
    });
    const { monthWidth, calendarWidth, isSingleMonth, scroll, scrollableRef, } = useCalendarDimensions({
        selectedStartDate,
        initialMonthIndex,
        inputsWidth,
    });
    const [headerRef, { height: headerHeight }] = useMeasure();
    return (_jsxs("div", { className: classes.calendar, style: { maxWidth: calendarWidth }, children: [_jsx("div", { className: classes.section, ref: headerRef, children: header }), _jsx(Weekdays, { isSingleMonth: isSingleMonth, topOffset: headerHeight }), _jsx("div", { className: classes.monthesRow, ref: scrollableRef, tabIndex: -1, onMouseLeave: () => setHoveredDate(null), children: _jsx("div", { className: classes.monthes, children: months.map(({ month, year, index }) => (_jsx(Month, { year: year, month: month, onSelectDate: onSelectDate, dateStartTimestamp: dateStartTimestamp, dateEndTimestamp: dateEndTimestamp, scroll: scroll, monthIndex: index, monthOptions: monthOptions, isMostLeft: index === 0, isMostRight: index === totalMonths - 1, width: monthWidth, isSingleMonthCalendar: isSingleMonth, minDateIncluded: minDateIncluded, maxDateIncluded: maxDateIncluded, setHoveredDate: setHoveredDate, areDatesUnselected: areDatesUnselected }, index))) }) }), _jsx("div", { className: classes.section, children: footer })] }));
});
