import { getCheCustomPalette, mergeCustomPalette } from './paletteUtils';
export const CUSTOM_PALETTE_COLORS = [
    'primary',
    'light',
    'background',
    'success',
    'warning',
    'error',
    'deletion',
    'search',
];
export const BASE_PALETTE = {
    black: '#101820',
    white: '#FFFFFF',
    darkWhite: '#fafafa',
    brand: '#636AFF',
    brandTertiary: '#EDF4FE',
    darkBrand: '#747af7',
    darkBrandTertiary: '#292a41',
    success: '#41CC78',
    error: '#FF6666',
    warning: '#FB751C',
    deletion: '#FFECEC',
    accent: '#F54B92',
    yellow: '#FFF400',
    darkAccent: '#d92671',
    darkSuccess: '#36a762',
    darkError: '#f55f5c',
    darkDeletion: '#493336',
    textPrimary: '#718299',
    textSecondary: '#B9C1CC',
    textTertiary: '#D8DFE8',
    textBackground: '#F4F5F7',
    darkTextPrimary: '#8999af',
    darkTextSecondary: '#81838c',
    darkTextTertiary: '#3A3D51',
    darkTextBackground: '#2f2f3a',
    background: '#F5F7FB',
    darkHeader: '#16161a',
    darkField: '#2a2e36',
    darkCloud: '#16161a',
    darkBackground: '#22252c',
};
export const PALETTE = mergeCustomPalette(getCheCustomPalette(window.name));
