import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';
import { MAX_EMAILS_AMOUNT, MAX_TOURISTS_AMOUNT, MIN_EMAILS_AMOUNT, MIN_TOURISTS_AMOUNT, } from '@/libs/constants';
import { DEFAULT_FIELD_ERROR } from '@/libs/validation/constants';
import { getDeepCopy } from '../notebook/helpers';
import { FORMS_STATE_NAMESPACE } from './consts';
import { InsurerField, TouristField, } from './interface';
const DEFAULT_TOURIST_AGE = 30;
const getKeys = (target) => Object.keys(target);
const getObjectFilledWithValue = (fields, value) => getKeys(fields).reduce((object, field) => ((object[field] = value), object), {});
export const defaultTourist = ({ age = DEFAULT_TOURIST_AGE, isNew = false, } = {}) => {
    return {
        _uuid: uuidv4(),
        ...getObjectFilledWithValue(TouristField, ''),
        fieldsErrors: getObjectFilledWithValue(TouristField, DEFAULT_FIELD_ERROR),
        fieldsDirty: getObjectFilledWithValue(TouristField, false),
        age,
        isNew,
    };
};
const defaultEmail = () => ({
    _uuid: uuidv4(),
    value: '',
    errorMessage: DEFAULT_FIELD_ERROR,
    isDirty: false,
});
export const formsInitialState = {
    tourists: [defaultTourist({ isNew: true })],
    insurer: {
        ...getObjectFilledWithValue(InsurerField, ''),
        fieldsErrors: getObjectFilledWithValue(InsurerField, DEFAULT_FIELD_ERROR),
        fieldsDirty: getObjectFilledWithValue(InsurerField, false),
        hasSecondNameRu: true,
        phoneCountryCode: '7',
    },
    emails: [defaultEmail()],
    isFormValidated: false,
    isFormDirty: false,
};
export const META_PARAM_TO_FORCE_RECALCULATION_ON_BIRTHDAY_COMPLETE = 'META_PARAM_TO_FORCE_RECALCULATION_ON_BIRTHDAY_COMPLETE';
const META = {
    startRecalculation: true,
    forceRecalculation: true,
    checkTouristsBirthdays: false,
};
const formsSlice = createSlice({
    name: FORMS_STATE_NAMESPACE,
    initialState: formsInitialState,
    reducers: {
        _resetForms: (state, action) => {
            const { touristsAmount, emailsAmount } = action.payload;
            const newState = getDeepCopy({
                ...formsInitialState,
                tourists: Array.from({
                    length: Math.min(Math.max(touristsAmount, MIN_TOURISTS_AMOUNT), MAX_TOURISTS_AMOUNT),
                }, () => defaultTourist()),
                emails: Array.from({
                    length: Math.min(Math.max(emailsAmount, MIN_EMAILS_AMOUNT), MAX_EMAILS_AMOUNT),
                }, () => defaultEmail()),
            });
            return newState;
        },
        addTourist: {
            reducer: (state, action) => {
                if (state.tourists.length < MAX_TOURISTS_AMOUNT) {
                    state.tourists.push(defaultTourist({ age: action?.payload, isNew: true }));
                }
            },
            prepare: (payload) => ({ payload, meta: META }),
        },
        removeTourist: {
            reducer: (state, action) => {
                if (state.tourists.length > MIN_TOURISTS_AMOUNT) {
                    state.tourists = state.tourists.filter((tourist) => tourist._uuid !== action.payload);
                }
            },
            prepare: (payload) => ({ payload, meta: META }),
        },
        _setTouristField: (state, action) => {
            const { _uuid, field, value } = action.payload;
            const tourist = state.tourists.find((tourist) => tourist._uuid === _uuid);
            if (tourist) {
                tourist[field] = value;
            }
        },
        _setTouristAge: {
            reducer: (state, action) => {
                const { _uuid, age } = action.payload;
                const tourist = state.tourists.find((tourist) => tourist._uuid === _uuid);
                if (tourist) {
                    tourist.age = age;
                    tourist.isNew = false;
                }
            },
            prepare: (payload, noMeta) => ({
                payload,
                meta: noMeta
                    ? undefined
                    : {
                        ...META,
                        META_PARAM_TO_FORCE_RECALCULATION_ON_BIRTHDAY_COMPLETE: true,
                    },
            }),
        },
        _setTouristFieldError: (state, action) => {
            const { _uuid, field, error } = action.payload;
            const tourist = state.tourists.find((tourist) => tourist._uuid === _uuid);
            if (tourist) {
                tourist.fieldsErrors[field] = error;
            }
        },
        setTouristFieldDirty: (state, action) => {
            const { _uuid, field } = action.payload;
            const tourist = state.tourists.find((tourist) => tourist._uuid === _uuid);
            if (tourist) {
                tourist.fieldsDirty[field] = true;
            }
        },
        _setInsurerField: (state, action) => {
            const { field, value } = action.payload;
            state.insurer[field] = value;
        },
        _setInsurerFieldError: (state, action) => {
            const { field, error } = action.payload;
            state.insurer.fieldsErrors[field] = error;
        },
        setInsurerFieldDirty: (state, action) => {
            state.insurer.fieldsDirty[action.payload] = true;
        },
        setInsurerHasSecondNameRu: (state, action) => {
            state.insurer.hasSecondNameRu = action.payload;
        },
        setPhoneCountryCode: (state, action) => {
            state.insurer.phoneCountryCode = action.payload;
            state.insurer.phoneBody = '';
        },
        addEmail: (state) => {
            if (state.emails.length < MAX_EMAILS_AMOUNT)
                state.emails.push(defaultEmail());
        },
        removeEmail: (state, action) => {
            if (state.emails.length > MIN_EMAILS_AMOUNT) {
                state.emails = state.emails.filter((email) => email._uuid !== action.payload);
            }
        },
        _setEmailValue: (state, action) => {
            const { _uuid, value } = action.payload;
            const email = state.emails.find((email) => email._uuid === _uuid);
            if (email) {
                email.value = value;
            }
        },
        _setEmailError: (state, action) => {
            const { _uuid, error } = action.payload;
            const email = state.emails.find((email) => email._uuid === _uuid);
            if (email) {
                email.errorMessage = error;
            }
        },
        setEmailDirty: (state, action) => {
            const email = state.emails.find((email) => email._uuid === action.payload);
            if (email) {
                email.isDirty = true;
            }
        },
        _setFormValidated: (state, action) => {
            state.isFormValidated = action.payload;
        },
        setFormDirty: (state, action) => {
            state.isFormDirty = action.payload ?? true;
        },
    },
});
export const { actions: formsActions, reducer: formsReducer } = formsSlice;
export { FORMS_STATE_NAMESPACE };
export * from './consts';
